import { jwtDecode } from 'jwt-decode'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { UITextInput } from '../..'
import './ProductsLine.scss'
import { formatNumberWithThreeDecimals } from '../../../lib/helpers'

const ProductsLine = (props) => {
  const { type, product, onChange, onDeleteClick } = props

  const { t } = useTranslation('productLine')

  const role = jwtDecode(localStorage.getItem('access_token'))?.role

  return (
    <>
      {type === 'following-delivery' && (
        <div className="products-line">
          <p className="products-line__name">{product?.name}</p>
          <div className="products-line__input">
            <UITextInput
              type="number"
              value={formatNumberWithThreeDecimals(product.quantity)}
              onChange={(data) => {
                const decimalPart = data.split('.')[1]
                if (!decimalPart || decimalPart?.length <= 3) {
                  onChange(data)
                }
              }}
              styleInput={{ width: '80%', height: '25px' }}
              style={{ height: '30px' }}
            />
            <p className="products-line__input__unit">{t(product.unit)}</p>
          </div>

          {role !== 'bdn_on_site_assistant' && (
            <button type="button" className="products-line__icon" onClick={onDeleteClick}>
              <img className="products-line__icon__img" src="icons/x.png" alt="remove product" />
            </button>
          )}
        </div>
      )}

      {type === 'new-delivery' && (
        <div className="products-line-new">
          <p className="products-line-new__name">{product.name}</p>
          <div className="products-line-new__values">
            <p className="products-line-new__values__quantity">
              {parseFloat(product.volume).toFixed(3)}
            </p>
            <p className="products-line-new__values__unit">{t(product.unit)}</p>
          </div>
        </div>
      )}

      {type === 'print-delivery' && (
        <div className="products-line-new">
          <p className="products-line-new__name">{product.name}</p>
          <div className="products-line-new__values">
            <p className="products-line-new__values__quantity">
              {formatNumberWithThreeDecimals(product.quantity)}
            </p>
            <p className="products-line-new__values__unit">{t(product.unit)}</p>
          </div>
        </div>
      )}

      {type === 'print-page' && (
        <div className="products-line-new">
          <p className="products-line-new__name">{product.name}</p>
          <div className="products-line-new__values">
            <p className="products-line-new__values__quantity">
              {formatNumberWithThreeDecimals(product.quantity)}
            </p>
            <p className="products-line-new__values__unit">{t(product.unit)}</p>
          </div>
        </div>
      )}
    </>
  )
}

ProductsLine.propTypes = {
  type: PropTypes.string.isRequired,
  product: PropTypes.shape([]).isRequired,
  onChange: PropTypes.func,
  onDeleteClick: PropTypes.func,
}

ProductsLine.defaultProps = {
  onChange: null,
  onDeleteClick: null,
}

export default ProductsLine
