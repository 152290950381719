import Papa from 'papaparse'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import api from '../../api'
import { Header, LayoutContainer, NavBar, UIButton } from '../../components'
import { UIUploadFile } from '../../components/_UI/UIUploadFile/UIUploadFile'
import './SettingsPage.scss'

const SettingsPage = () => {
  const { t } = useTranslation('settings_page')
  const [csvFile, setCsvFile] = useState(null)
  const [error, setError] = useState(null)

  const checkCsvFile = (file) => {
    Papa.parse(file, {
      header: true,
      encoding: 'UTF-8',
      error: function () {
        setError((prev) => ({ ...prev, csv: t('error_invalid_file') }))
      },
      complete: function (results) {
        const headers = results.meta.fields

        const expectedHeaders = [
          'ID liste',
          'ID site',
          'site',
          'Canal de distribution',
          'Code produit',
          'Prix',
          'Unité',
          'Client',
        ]

        const headersAreValid = expectedHeaders.every((header) => headers.includes(header))

        if (headersAreValid) {
          setError((prev) => ({ ...prev, csv: null }))
          setCsvFile(file)
        } else {
          setError((prev) => ({ ...prev, csv: t('error_invalid_headers') }))
        }
      },
    })
  }

  const onFileError = (error) => {
    if (error.acceptType) {
      setError((prev) => ({ ...prev, csv: t('error_file_type') }))
      return
    }
  }

  const onSave = async () => {
    await api
      .postFilePrices(csvFile, localStorage.getItem('access_token'))
      .then((response) => {
        if (response) {
          setCsvFile(null)
        }
      })
      .catch(() => {
        setError((prev) => ({ ...prev, csv: t('error_upload') }))
      })
  }

  return (
    <div className="settings">
      <Header title={t('title')} />
      <NavBar active="settings" />
      <LayoutContainer>
        <div className="settings__card">
          <section className="settings__card__section">
            <h2 className="settings__card__section__title">{t('settings_prices')}</h2>
            <p className="settings__card__section__description">{t('settings_prices_info')}</p>

            <div className="settings__card__section__btn">
              <UIUploadFile
                kind="primary"
                acceptType={['csv']}
                file={csvFile}
                uploadBtnText={t('import')}
                type={'button'}
                onChange={checkCsvFile}
                onError={onFileError}
                formatText={t('format_text')}
              />
              {error?.csv && <p className="settings__card__section__error">{error.csv}</p>}
              {!error?.csv && csvFile?.name && (
                <p className="settings__card__section__fileName">{csvFile?.name}</p>
              )}
            </div>
          </section>
          <footer className="settings__card__footer">
            <UIButton
              onClick={onSave}
              disabled={!csvFile || error?.csv}
              type="primary"
              text={t('save')}
            />
          </footer>
        </div>
      </LayoutContainer>
    </div>
  )
}

export default SettingsPage
