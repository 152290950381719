import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import api from '../../api'
import { UIButton, UITextInput, WidgetComeBack } from '../../components'
import './ResetPassword.scss'

const ResetPassword = () => {
  const { t } = useTranslation('resetPassword')

  const { search } = window.location
  const params = new URLSearchParams(search)
  const tokenURI = params.get('token')
  const navigate = useNavigate()

  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [noMatchPassword, setNoMatchPassword] = useState(false)
  const [regexError, setRegexError] = useState(false)
  const [generalError, setGeneralError] = useState(false)

  // eslint-disable-next-line
  const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/

  const sendNewPassword = async () => {
    setNoMatchPassword(false)
    setRegexError(false)
    setGeneralError(false)
    if (password && confirmPassword && password === confirmPassword) {
      if (passwordRegex.test(password)) {
        try {
          await api.sendNewPassword(password, tokenURI)
          navigate('/')
        } catch (e) {
          setGeneralError(true)
        }
      } else {
        setRegexError(true)
      }
    } else {
      setNoMatchPassword(true)
    }
  }

  return (
    <div className="reset-pw" style={{ backgroundImage: 'url(images/bdn-bg-image.png)' }}>
      <div className="reset-pw__container">
        <div className="reset-pw__container__image">
          <img
            className="reset-pw__container__image__logo-bdn"
            src="images/logo-BDN.png"
            alt={t('altimage')}
          />
        </div>
        <div className="reset-pw__container__informations">
          <WidgetComeBack />

          <h1 className="reset-pw__container__informations__title">{t('title')}</h1>
          <h2 className="reset-pw__container__informations__text1">{t('text1')}</h2>

          <div className="reset-pw__container__informations__form">
            <div className="reset-pw__container__informations__form__password">
              <UITextInput
                type="email"
                value={password}
                onChange={(e) => {
                  setPassword(e)
                }}
                error={noMatchPassword || regexError || generalError}
                placeHolder={t('placeHolder1')}
                style={{ height: '100%' }}
              />
            </div>
            <div className="reset-pw__container__informations__form__password-confirm">
              <UITextInput
                type="email"
                value={confirmPassword}
                onChange={(e) => {
                  setConfirmPassword(e)
                }}
                error={noMatchPassword || regexError || generalError}
                placeHolder={t('placeHolder2')}
                style={{ height: '100%' }}
              />
            </div>

            {noMatchPassword && (
              <p className="reset-pw__container__informations__form__password-confirm__error">
                {t('noMatchPassword')}
              </p>
            )}
            {regexError && (
              <p className="reset-pw__container__informations__form__password-confirm__error">
                {t('neededPassword')}
              </p>
            )}
            {generalError && (
              <p className="reset-pw__container__informations__form__password-confirm__error">
                {t('passwordError')}
              </p>
            )}

            <div className="reset-pw__container__informations__form__needed">
              <p className="reset-pw__container__informations__form__needed__text">
                {t('neededText')}
              </p>
              <ul className="reset-pw__container__informations__form__needed__list">
                <li>{t('neededList1')}</li>
                <li>{t('neededList2')}</li>
                <li>{t('neededList3')}</li>
              </ul>
            </div>
            <div className="reset-pw__container__informations__form__send">
              <UIButton
                type="primary"
                text={t('send')}
                onClick={() => sendNewPassword()}
                alt="register"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ResetPassword
