import { PropTypes } from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { UITextInput, UITextInputAutoCompletion } from '../..'
import '../../../styles/colors.scss'
import './MaterialsDelivery.scss'

const MaterialsDelivery = (props) => {
  const {
    product,
    productOnChange,
    unit,
    division,
    divisionOnChange,
    volume,
    volumeOnChange,
    deleteOnClick,
    materialsAutoCompletion,
  } = props

  const { t } = useTranslation('newDelivery')

  return (
    <div className="materialsDelivery">
      <div className="materialsDelivery__inputs">
        <div className="materialsDelivery__inputs__field">
          <UITextInputAutoCompletion
            type="string"
            value={product}
            onChange={productOnChange}
            prefix={t('products')}
            placeHolder={t('productsPlaceHolder')}
            autoCompleteList={materialsAutoCompletion}
            autoCompleteType="materials"
          />
        </div>

        <div className="materialsDelivery__inputs__field">
          {unit === 'TNE' && (
            <UITextInput
              type="number"
              value={division}
              onChange={divisionOnChange}
              prefix={t('distribution')}
              placeHolder={t('percentPlaceHolder')}
              unit="%"
            />
          )}
        </div>

        <div className="materialsDelivery__inputs__field">
          <UITextInput
            type="number"
            value={volume}
            onChange={volumeOnChange}
            prefix={unit === 'TNE' ? t('tonnage') : t('quantity')}
            placeHolder={unit === 'TNE' ? t('tonnage') : t('quantity')}
            unit={t(unit)}
          />
        </div>
      </div>

      <div className="materialsDelivery__button">
        <button className="materialsDelivery__button__icon" type="button" onClick={deleteOnClick}>
          <img
            className="materialsDelivery__button__icon__img"
            src="icons/delete.png"
            alt={t('deleteLine')}
          />
        </button>
      </div>
    </div>
  )
}

MaterialsDelivery.propTypes = {
  product: PropTypes.string.isRequired,
  productOnChange: PropTypes.func.isRequired,
  unit: PropTypes.string,
  division: PropTypes.string.isRequired,
  divisionOnChange: PropTypes.func.isRequired,
  volume: PropTypes.string.isRequired,
  volumeOnChange: PropTypes.func.isRequired,
  deleteOnClick: PropTypes.func.isRequired,
  materialsAutoCompletion: PropTypes.arrayOf(PropTypes.string).isRequired,
}

MaterialsDelivery.defaultProps = {
  unit: '',
}

export default MaterialsDelivery
