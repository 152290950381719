/* eslint-disable no-undef */
/* eslint-disable no-bitwise */
/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import { fr } from 'date-fns/locale'
import fileDownload from 'js-file-download'
import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import { DateRangePicker } from 'react-date-range'
import 'react-date-range/dist/styles.css' // main style file
import 'react-date-range/dist/theme/default.css' // theme css file
import { useTranslation } from 'react-i18next'
import { v4 as uuid } from 'uuid'
import api from '../../api'
import {
  ConfirmPopIn,
  ErrorPopIn,
  FollowingDeliveryLine,
  Header,
  InformationPopIn,
  LayoutContainer,
  NavBar,
  ProductsLine,
  UIButton,
  UIDropDown,
  UILoading,
  UISearchBar,
  UITextInput,
  UITextInputAutoCompletion,
  WidgetOverlaySaved,
  WidgetOverlayValidate,
} from '../../components'
import { gqlClients, gqlDelivery, gqlTrucks, gqlUsers } from '../../gql'
import { captureMessageWithAttachment } from '../../lib/sentry'
import { getDate, getTime } from '../../lib/utils'
import './FollowingDelivery.scss'

// TEMPORARY DATA

const FollowingDelivery = () => {
  const { t } = useTranslation('followingDelivery')
  // eslint-disable-next-line camelcase
  const initOffet = 20

  const [currentSelected, setCurrentSelected] = useState()

  const [print, setPrint] = useState(false)

  const [openDetails, setOpenDetails] = useState(false)
  const [sortSite, setSortSite] = useState('')
  const [sortStatus, setSortStatus] = useState('')
  const [sortPeriode, setSortPeriode] = useState(null)
  const [searchBar, setSearchBar] = useState('')
  const [choosePeriod, setChoosePeriod] = useState(false)

  const [choosePeriodExport, setChoosePeriodExport] = useState(false)
  const [periodExport, setPeriodExport] = useState(null)

  const [isErrorPopInOpen, setIsErrorPopInOpen] = useState(false)
  const [errorPopInMessage, setErrorPopInMessage] = useState([])
  const [errorPopInCode, setErrorPopInCode] = useState([])

  const [isInformationPopInOpen, setIsInformationPopInOpen] = useState(false)
  const [informationPopInMessage, setInformationPopInMessage] = useState('')

  const [openMiniSearchBar, setOpenMiniSearchBar] = useState(false)
  const [mustDisplaySavedOverlay, setMustDisplaySavedOverlay] = useState(false)
  const [mustDisplayValidateOverlay, setMustDisplayValidateOverlay] = useState(false)
  const [openBackInformationalPopIn, setOpenBackInformationalPopIn] = useState(false)
  const [loadingSreen, setLoadingSreen] = useState(false)
  const [disableValidateButton, setDisableValidateButton] = useState(true)

  // state related to the form
  const [deliveryId, setDeliveryId] = useState()
  const [deliveryNumber, setDeliveryNumber] = useState()

  // Pas encore de state pour code de livraison

  const [saleOrder, setSaleOrder] = useState()
  const [saleOrderId, setSaleOrderId] = useState(null)

  const [client, setClient] = useState()
  const [clientId, setClientId] = useState()

  const [licensePlate, setLicensePlate] = useState()
  const [truckId, setTruckId] = useState()

  const [externRef, setExternRef] = useState()

  const [destinationSite, setDestinationSite] = useState()

  const [freightForwarder, setFreightForwarder] = useState()
  const [freightForwarderId, setFreightForwarderId] = useState()

  const [constructionSiteId, setConstructionSiteId] = useState()
  const [constructionSiteCode, setConstructionSiteCode] = useState()
  const [constructionSiteAddress, setConstructionSiteAddress] = useState()
  const [constructionSiteCity, setConstructionSiteCity] = useState()

  const [timestamp, setTimestamp] = useState()

  const [materials, setMaterials] = useState([])

  const [signature, setSignature] = useState()

  const [currentStatus, setCurrentStatus] = useState()
  // ---

  const [deleteMaterialIndex, setDeleteMaterialIndex] = useState(-1)
  const [deleteMaterial, setDeleteMaterial] = useState({})

  const [offset, setOffset] = useState(initOffet)
  const [more, setmore] = useState(false)
  const loadingMore = useRef()

  // ===== GraphQl =====

  const { data: clients } = useQuery(gqlClients.CLIENTS)
  const { data: getFreightForwarders } = useQuery(gqlClients.FREIGHT_FORWARDERS)
  const { data: sites } = useQuery(gqlUsers.SITES)

  const [fetchSaleOrder] = useLazyQuery(gqlDelivery.SALE_ORDER_BY_ID, {
    fetchPolicy: 'network-only',
    onCompleted: (response) => {
      if (response.salesOrder) {
        setSaleOrder(response.salesOrder.code)
        setSaleOrderId(response.salesOrder.id)
      } else {
        setSaleOrder('')
        setSaleOrderId(null)
      }
    },
  })

  const [fetchSalesOrder] = useLazyQuery(gqlDelivery.SALES_ORDER_BY_CODE, {
    fetchPolicy: 'network-only',
    variables: {
      code: saleOrder,
    },
    onCompleted: (response) => {
      if (response.salesOrderByCode) {
        setSaleOrderId(response?.salesOrderByCode?.id)
        setClient(response?.salesOrderByCode?.clientByIdClient?.displayName)
        setClientId(response?.salesOrderByCode?.clientByIdClient?.id)
        setExternRef(response?.salesOrderByCode?.externalReference)
      } else {
        setSaleOrderId(null)
      }
    },
  })

  const [updateDeliveryStatus] = useMutation(gqlDelivery.UPDATE_DELIVERY_STATUS, {
    optimisticResponse: {
      updateDraftOutboundDelivery: {
        clientMutationId: null,
        draftOutboundDelivery: {
          id: deliveryId,
          status: currentStatus,
          __typename: 'DraftOutboundDelivery',
        },
        __typename: 'DraftOutboundDeliveryPayload',
      },
    },
    onCompleted: () => {
      setMustDisplayValidateOverlay(true)
      setTimeout(() => {
        setOpenDetails(false)
        setCurrentSelected(undefined)
        setMustDisplayValidateOverlay(false)
      }, 1000)
    },
    onError: (err) => {
      const request = gqlDelivery.UPDATE_DELIVERY_STATUS
      const event = {
        request: request?.definitions[0]?.name?.value,
        variables: {
          id: deliveryId,
          status: currentStatus,
        },
      }
      captureMessageWithAttachment(event, err)
    },
  })

  const [fetchSomeDeliveries, { data: deliveries, refetch }] = useLazyQuery(
    gqlDelivery.DELIVERIES,
    {
      fetchPolicy: 'network-only',
      onCompleted: () => {
        const objDiv = document.getElementById(`#delivery-${offset - initOffet}`)
        if (objDiv) {
          objDiv.scrollIntoView({ block: 'start' })
        }
        setOffset(offset + initOffet)
        setmore(deliveries.draftOutboundDeliveries.pageInfo.hasNextPage)
      },
    }
  )

  const [deleteMaterialItem] = useMutation(gqlDelivery.DELETE_DELIVERY_ITEM, {
    variables: {
      id: deleteMaterial?.id,
    },
    onError: (err) => {
      const request = gqlDelivery.DELETE_DELIVERY_ITEM
      const event = {
        request: request?.definitions[0]?.name?.value,
        variables: {
          id: deleteMaterial?.id,
        },
      }
      captureMessageWithAttachment(event, err)
    },
  })

  const [fetchTruck, { data: truck }] = useLazyQuery(gqlTrucks.TRUCK_BY_ID, {
    fetchPolicy: 'network-only',
    onCompleted: () => {
      if (truck.truck) {
        setLicensePlate(truck.truck.licencePlate)
      } else {
        setLicensePlate('')
      }
    },
  })

  const [fetchIdTruck, { data: truckIdByLicensePlate }] = useLazyQuery(
    gqlTrucks.TRUCK_BY_LICENSEPLATE,
    {
      fetchPolicy: 'network-only',
      variables: {
        plicensePlate: licensePlate,
      },
      onCompleted: () => {
        if (truckIdByLicensePlate.trucks.nodes[0]?.id) {
          setTruckId(truckIdByLicensePlate.trucks.nodes[0]?.id)
        } else {
          setTruckId()
        }
      },
    }
  )

  const [fetchConstructionSite] = useLazyQuery(gqlDelivery.CONSTRUCTION_SITE_BY_ID, {
    fetchPolicy: 'cache-and-network',
    constructionSite: {
      clientMutationId: null,
      __typename: 'ConstructionSite',
    },
    onCompleted: (response) => {
      setConstructionSiteCode(response?.constructionSite?.code)
      setConstructionSiteAddress(response?.constructionSite?.address)
      setConstructionSiteCity(response?.constructionSite?.city)
    },
  })

  const [fetchConstructionSiteByCode] = useLazyQuery(gqlDelivery.CONSTRUCTION_SITE_BY_CODE, {
    fetchPolicy: 'network-only',
    variables: {
      code: constructionSiteCode,
    },
    onCompleted: (response) => {
      if (response.constructionSiteByCode && response !== '') {
        setConstructionSiteId(response.constructionSiteByCode.id)
        setConstructionSiteAddress(response.constructionSiteByCode.address)
        setConstructionSiteCity(response.constructionSiteByCode.city)
      } else {
        setConstructionSiteId('')
        setConstructionSiteAddress('')
        setConstructionSiteCity('')
      }
    },
  })

  const [fetchFreightForwarder, { data: getFreightForwarder }] = useLazyQuery(
    gqlClients.FREIGHT_FORWARDER,
    {
      fetchPolicy: 'network-only',
      onCompleted: () => {
        if (getFreightForwarder && getFreightForwarder.freightForwarder) {
          setFreightForwarderId(getFreightForwarder.freightForwarder.id)
          setFreightForwarder(getFreightForwarder.freightForwarder.displayName)
        } else {
          setFreightForwarderId(null)
          setFreightForwarder('')
        }
      },
    }
  )

  const [updateDeliveryItem] = useMutation(gqlDelivery.UPDATE_DELIVERY_ITEM, {
    updateDraftOutboundDeliveryItem: {
      clientMutationId: null,
      __typename: 'DraftOutboundDeliveryItemPayload',
    },
    onCompleted: () => {
      setMustDisplaySavedOverlay(true)
      setTimeout(() => {
        setMustDisplaySavedOverlay(false)
      }, 1000)
    },
    onError: (err) => {
      const request = gqlDelivery.UPDATE_DELIVERY_ITEM
      const event = {
        request: request?.definitions[0]?.name?.value,
        variables: {
          materials,
        },
      }
      captureMessageWithAttachment(event, err)
    },
  })

  const [updateDelivery] = useMutation(gqlDelivery.UPDATE_DELIVERY, {
    optimisticResponse: {
      updateDraftOutboundDelivery: {
        clientMutationId: null,
        draftOutboundDelivery: {
          code: deliveryId,
          constructionSiteAddress,
          constructionSiteCity,
          externalReference: externRef,
          id: deliveryId,
          idClient: clientId,
          idConstructionSite: constructionSiteId,
          idSaleOrder: saleOrderId,
          idTruck: truckId,
          saleOrderClientName: client,
          truckLicencePlate: licensePlate,
          idFreightForwarder: freightForwarderId,
          forwardingAgent: freightForwarder,
          destinationSite,
          __typename: 'DraftOutboundDelivery',
        },
        __typename: 'DraftOutboundDeliveryPayload',
      },
    },
    onCompleted: () => {
      for (let i = 0; i < materials.length; i++) {
        updateDeliveryItem({
          variables: {
            id: materials[i].id,
            quantity: parseFloat(materials[i].quantity),
          },
        })
      }
      setTimeout(() => {
        refetch()
      }, 500)
    },
    onError: (err) => {
      const request = gqlDelivery.UPDATE_DELIVERY
      const event = {
        request: request?.definitions[0]?.name?.value,
        variables: {
          id: deliveryId,
          externalReference: externRef,
          idConstructionSite: constructionSiteId,
          constructionSiteAddress,
          constructionSiteCity,
          idSaleOrder: saleOrderId,
          truckLicencePlate: licensePlate,
          saleOrderClientName: client,
          idClient: clientId,
        },
      }
      captureMessageWithAttachment(event, err)
    },
  })

  const [createConstructionSite] = useMutation(gqlDelivery.CREATE_CONSTRUCTION_SITE, {
    onCompleted: (response) => {
      updateDelivery({
        variables: {
          id: deliveryId,
          externalReference: externRef,
          idConstructionSite: response?.createConstructionSite?.constructionSite?.id,
          idSaleOrder: saleOrderId,
          idTruck: '',
          truckLicencePlate: licensePlate,
          saleOrderClientName: client,
          idClient: clientId,
          idFreightForwarder: freightForwarderId,
          forwardingAgent: freightForwarder,
          destinationSite,
        },
      })
    },
    onError: (err) => {
      const request = gqlDelivery.CREATE_CONSTRUCTION_SITE
      const event = {
        request: request?.definitions[0]?.name?.value,
        variables: {
          id: constructionSiteId,
          code: constructionSiteCode,
          address: constructionSiteAddress,
          city: constructionSiteCity,
        },
      }
      captureMessageWithAttachment(event, err)
    },
  })

  const [updateConstructionSite] = useMutation(gqlDelivery.UPDATE_DELIVERY_CONSTRUCTION_SITE, {
    updateDeliveryConstructionSite: {
      clientMutationId: null,
      __typename: 'UpdateConstructionSitePayload',
    },
    onCompleted: () => {
      updateDelivery({
        variables: {
          id: deliveryId,
          externalReference: externRef,
          idConstructionSite: constructionSiteId,
          idSaleOrder: saleOrderId,
          idTruck: '',
          truckLicencePlate: licensePlate,
          saleOrderClientName: client,
          idClient: clientId,
          idFreightForwarder: freightForwarderId,
          forwardingAgent: freightForwarder,
          destinationSite,
        },
      })
      if (constructionSiteId) {
        fetchConstructionSite({
          variables: {
            id: constructionSiteId,
          },
        })
      }
      refetch()
    },
    onError: (err) => {
      const request = gqlDelivery.UPDATE_DELIVERY_CONSTRUCTION_SITE
      const event = {
        request: request?.definitions[0]?.name?.value,
        variables: {
          id: constructionSiteId,
          code: constructionSiteCode,
          address: constructionSiteAddress,
          city: constructionSiteCity,
        },
      }
      captureMessageWithAttachment(event, err)
    },
  })

  // ===== Functions =====

  const checkIfPossibleToGoToSap = () => {
    if (saleOrderId && currentStatus !== 'REDRESS_') {
      setDisableValidateButton(false)
    } else if (externRef && clientId && currentStatus !== 'REDRESS_') {
      setDisableValidateButton(false)
    } else {
      setDisableValidateButton(true)
    }
  }

  const updateItem = (prop, event, index) => {
    const old = materials[index]
    const clone = [...materials]
    const updated = { ...old, [prop]: event }
    clone[index] = updated
    setMaterials(clone)
  }

  const handleOpenDetails = (isSelected) => {
    if (isSelected) {
      setOpenBackInformationalPopIn(true)
    }
  }

  const registerModifications = () => {
    if (constructionSiteId) {
      updateConstructionSite({
        variables: {
          id: constructionSiteId,
          code: constructionSiteCode,
          address: constructionSiteAddress,
          city: constructionSiteCity,
        },
      })
    } else if (!constructionSiteId && constructionSiteCode) {
      const newUUIDConstructionSite = uuid()
      createConstructionSite({
        variables: {
          id: newUUIDConstructionSite,
          code: constructionSiteCode,
          address: constructionSiteAddress,
          city: constructionSiteCity,
        },
      })
    } else {
      updateDelivery({
        variables: {
          id: deliveryId,
          externalReference: externRef,
          idConstructionSite: constructionSiteId,
          constructionSiteAddress,
          constructionSiteCity,
          idSaleOrder: saleOrderId,
          truckLicencePlate: licensePlate,
          saleOrderClientName: client,
          idClient: clientId,
          idFreightForwarder: freightForwarderId,
          forwardingAgent: freightForwarder,
          destinationSite,
        },
      })
    }
  }

  const validateModifications = async () => {
    if (clientId && saleOrderId && currentStatus !== 'REDRESS_') {
      try {
        setLoadingSreen(true)
        await api.sendDeliveryToSAP(deliveryId).then(() => {
          setLoadingSreen(false)
          updateDeliveryStatus({
            variables: {
              id: deliveryId,
              status: 'REDRESS_',
            },
          })
        })
      } catch (error) {
        const event = {
          request: 'sendDeliveryToSAP',
          variables: {
            id: deliveryId,
            externRef,
            clientId,
            client,
            currentStatus,
            materials,
          },
        }
        captureMessageWithAttachment(event, error)
        setIsErrorPopInOpen(true)
        setErrorPopInMessage('errorSAP')
      }
    } else if (currentStatus !== 'REDRESS_') {
      try {
        setLoadingSreen(true)
        await api.createSalesOrderSAP(deliveryId).then(() => {
          setLoadingSreen(false)
          fetchSomeDeliveries({
            variables: {
              first: initOffet,
              start: sortPeriode?.selection
                ? moment(sortPeriode?.selection?.startDate).format('yyyy-MM-DD')
                : moment().subtract(1, 'years').format('yyyy-MM-DD'),
              end: sortPeriode?.selection
                ? moment(sortPeriode?.selection?.endDate).add(1, 'days').format('yyyy-MM-DD')
                : moment().add(1, 'days').format('yyyy-MM-DD'),
              idSite: sortSite || '',
              client: searchBar || '',
              status: sortStatus ? { equalTo: sortStatus } : {},
            },
          })
        })
      } catch (error) {
        const event = {
          request: 'createSalesOrderSAP',
          variables: {
            id: deliveryId,
            externRef,
            clientId,
            client,
            currentStatus,
            materials,
          },
        }
        captureMessageWithAttachment(event, error)
        setLoadingSreen(false)
        const prefix = error?.response?.data?.error
        if (prefix?.Log?.Item) {
          setErrorPopInMessage(prefix?.Log.Item[0]?.Note)
          setErrorPopInCode(prefix?.Log.Item[0]?.TypeID)
          setIsErrorPopInOpen(true)
        } else if (typeof prefix === 'string') {
          setIsErrorPopInOpen(true)
          setErrorPopInMessage(prefix)
        } else {
          setIsErrorPopInOpen(true)
          setErrorPopInMessage('errorSAP')
        }
      }
    }
  }

  const exportDraftDeliveries = async (startDate, endDate) => {
    if (periodExport && startDate && endDate) {
      try {
        setLoadingSreen(true)
        await api.exportDeliveries(startDate, endDate).then((response) => {
          setChoosePeriodExport(false)
          setPeriodExport(null)
          setLoadingSreen(false)
          if (response) {
            fileDownload(response, 'Export_bons_de_livraison.csv')
          } else {
            setIsInformationPopInOpen(true)
            setInformationPopInMessage('noContent')
          }
        })
      } catch (error) {
        const event = {
          request: 'exportDeliveries',
          variables: {
            startDate,
            endDate,
          },
        }
        captureMessageWithAttachment(event, error)
        setLoadingSreen(false)
        setIsErrorPopInOpen(true)
        setErrorPopInMessage('errorExport')
      }
    } else {
      setIsErrorPopInOpen(true)
      setErrorPopInMessage('errorExportTime')
    }
  }

  const printBound = (id) => {
    const token = localStorage.getItem('access_token')
    api.getSignature(id, token, (resp) => {
      setSignature(resp)
      setPrint(true)
    })
  }

  const closePrint = () => {
    setPrint(false)
  }

  // ===== Use effects =====

  useEffect(() => {
    if (constructionSiteCode !== '') {
      fetchConstructionSiteByCode({
        variables: {
          code: constructionSiteCode,
        },
      })
    } else if (constructionSiteCode === '' && constructionSiteId) {
      setConstructionSiteId(null)
    }
  }, [constructionSiteCode])

  useEffect(() => {
    if (client === '') {
      setClientId(null)
    }
  }, [client])

  useEffect(() => {
    if (freightForwarder === '') {
      setFreightForwarderId(null)
    }
  }, [freightForwarder])

  useEffect(() => {
    if (licensePlate) {
      fetchIdTruck()
    }
  }, [licensePlate, fetchIdTruck])

  useEffect(() => {
    fetchSalesOrder()
  }, [saleOrder])

  const loadFunction = () => {
    fetchSomeDeliveries({
      variables: {
        first: offset,
        start: sortPeriode?.selection
          ? moment(sortPeriode?.selection?.startDate).format('yyyy-MM-DD')
          : moment().subtract(1, 'years').format('yyyy-MM-DD'),
        end: sortPeriode?.selection
          ? moment(sortPeriode?.selection?.endDate).add(1, 'days').format('yyyy-MM-DD')
          : moment().add(1, 'days').format('yyyy-MM-DD'),
        idSite: sortSite || '',
        client: searchBar || '',
        status: sortStatus ? { equalTo: sortStatus } : {},
      },
    })
  }

  useEffect(() => {
    if (offset === 0) {
      fetchSomeDeliveries({
        variables: {
          first: initOffet,
          start: sortPeriode?.selection
            ? moment(sortPeriode?.selection?.startDate).format('yyyy-MM-DD')
            : moment().subtract(1, 'years').format('yyyy-MM-DD'),
          end: sortPeriode?.selection
            ? moment(sortPeriode?.selection?.endDate).add(1, 'days').format('yyyy-MM-DD')
            : moment().add(1, 'days').format('yyyy-MM-DD'),
          idSite: sortSite || '',
          client: searchBar || '',
          status: sortStatus ? { equalTo: sortStatus } : {},
        },
      })
    }
  }, [fetchSomeDeliveries, offset, searchBar, sortPeriode, sortSite, sortStatus])

  useEffect(() => {
    if (currentSelected !== undefined && deliveries?.draftOutboundDeliveries?.nodes?.length > 0) {
      const prefix = deliveries.draftOutboundDeliveries.nodes[currentSelected]
      setDeliveryId(prefix?.id)
      setDeliveryNumber(prefix?.code || '')
      setClient(prefix?.saleOrderClientName || '')
      setClientId(prefix?.idClient)
      setTruckId(prefix?.idTruck)
      setExternRef(prefix?.externalReference || '')
      setDestinationSite(prefix?.destinationSite || '')
      setConstructionSiteId(prefix?.idConstructionSite)
      setTimestamp(prefix?.createdAt || '')
      setCurrentStatus(prefix?.status || '')

      if (prefix.idSaleOrder) {
        fetchSaleOrder({
          variables: {
            id: prefix.idSaleOrder,
          },
        })
      } else {
        setSaleOrder('')
        setSaleOrderId(null)
      }

      if (prefix.truckLicencePlate) {
        setLicensePlate(prefix.truckLicencePlate)
      } else if (prefix.idTruck) {
        fetchTruck({
          variables: {
            id: prefix.idTruck,
          },
        })
      } else {
        setLicensePlate('')
      }

      if (prefix.idFreightForwarder) {
        fetchFreightForwarder({
          variables: {
            id: prefix.idFreightForwarder,
          },
        })
      } else if (prefix.forwardingAgent) {
        setFreightForwarder(prefix.forwardingAgent)
        setFreightForwarderId(null)
      } else {
        setFreightForwarder('')
        setFreightForwarderId(null)
      }

      if (prefix.idConstructionSite) {
        fetchConstructionSite({
          variables: {
            id: prefix.idConstructionSite,
          },
        })
      } else if (prefix.constructionSiteAddress || prefix.constructionSiteCity) {
        setConstructionSiteAddress(prefix.constructionSiteAddress)
        setConstructionSiteCity(prefix.constructionSiteCity)
        setConstructionSiteCode('')
        setConstructionSiteId(null)
      } else {
        setConstructionSiteAddress('')
        setConstructionSiteCity('')
        setConstructionSiteCode('')
        setConstructionSiteId(null)
      }

      const tabWeights = []
      for (
        let i = 0;
        i < prefix.draftOutboundDeliveryItemsByIdDraftOutboundDelivery.totalCount;
        i++
      ) {
        const prefixMaterials = prefix.draftOutboundDeliveryItemsByIdDraftOutboundDelivery.nodes[i]
        tabWeights.push({
          id: prefixMaterials.id,
          name: prefixMaterials.materialByIdMaterial?.description || prefixMaterials.name,
          quantity: prefixMaterials.quantity || prefixMaterials.volume,
          unit: prefixMaterials.materialByIdMaterial?.baseMeasureUnitCode || prefixMaterials.unit,
        })
      }
      setMaterials(tabWeights)
    }
  }, [
    currentSelected,
    deliveries,
    fetchSaleOrder,
    fetchTruck,
    fetchConstructionSite,
    fetchFreightForwarder,
  ])

  useEffect(() => {
    fetchSomeDeliveries({
      variables: {
        offset: 0,
        start: sortPeriode?.selection
          ? moment(sortPeriode?.selection?.startDate).format('yyyy-MM-DD')
          : moment().subtract(1, 'years').format('yyyy-MM-DD'),
        end: sortPeriode?.selection
          ? moment(sortPeriode?.selection?.endDate).add(1, 'days').format('yyyy-MM-DD')
          : moment().add(1, 'days').format('yyyy-MM-DD'),
        idSite: sortSite || '',
        client: searchBar || '',
        status: sortStatus ? { equalTo: sortStatus } : {},
      },
    })
  }, [sortPeriode, sortSite, searchBar, sortStatus, fetchSomeDeliveries])

  useEffect(() => {
    setTimeout(() => {
      if (print) {
        const css = '@page { size: portrait; -webkit-print-color-adjust: exact; }'
        const head = document.head || document.getElementsByTagName('head')[0]
        const style = document.createElement('style')

        style.type = 'text/css'
        style.media = 'print'

        if (style.styleSheet) {
          style.styleSheet.cssText = css
        } else {
          style.appendChild(document.createTextNode(css))
        }

        head.appendChild(style)

        window.print()
      }
    }, 500)
  }, [print])

  useEffect(() => {
    checkIfPossibleToGoToSap()
  }, [saleOrderId, externRef, clientId, currentStatus])

  // =====  Renders =====

  const renderHeader = () => (
    <div
      className={
        openMiniSearchBar
          ? 'following-delivery__header following-delivery__header__search-open'
          : 'following-delivery__header'
      }
    >
      {sites?.sites?.nodes && (
        <div
          className={
            openDetails
              ? 'following-delivery__header__dropdown'
              : 'following-delivery__header__dropdown1'
          }
        >
          <UIDropDown
            options={sites?.sites?.nodes}
            onChange={(e) => setSortSite(e.target.value)}
            value={sortSite}
            placeholder={t('sortSite')}
            style={{ width: '100%' }}
          />
        </div>
      )}

      <div
        className={
          openDetails
            ? 'following-delivery__header__dropdown'
            : 'following-delivery__header__dropdown1'
        }
      >
        <UIDropDown
          options={[
            { id: 'VALIDER', name: t('VALIDER') },
            { id: 'REDRESS_', name: t('REDRESS_') },
            { id: 'REDRESSER', name: t('REDRESSER') },
            { id: 'EN_CR_ATION', name: t('EN_CR_ATION') },
          ]}
          onChange={(e) => setSortStatus(e.target.value)}
          value={sortStatus}
          placeholder={t('sortStatus')}
          style={{ width: '100%' }}
        />
      </div>

      <div
        className="following-delivery__date-choice"
        style={{ width: openDetails ? '17%' : '14%' }}
      >
        <button
          className="following-delivery__date-choice__btn"
          type="button"
          onClick={() => setChoosePeriod(!choosePeriod)}
        >
          <p>{t('sortPeriode')}</p>
        </button>
        {choosePeriod ? (
          <div className="following-delivery__date-choice__date-picker">
            <DateRangePicker
              locale={fr}
              ranges={[
                {
                  startDate: sortPeriode?.selection?.startDate || new Date(),
                  endDate: sortPeriode?.selection?.endDate || new Date(),
                  key: 'selection',
                },
              ]}
              dateDisplayFormat="yyyy-MM-dd"
              onChange={(e) => setSortPeriode(e)}
              maxDate={new Date()}
            />
          </div>
        ) : (
          ''
        )}
      </div>

      <div className="following-delivery__export" style={{ width: openDetails ? '25%' : '18%' }}>
        <button
          className="following-delivery__export__btn"
          type="button"
          onClick={() => setChoosePeriodExport(!choosePeriodExport)}
        >
          <p>{t('exportPeriod')}</p>
        </button>
        <button
          className={
            periodExport
              ? 'following-delivery__export__btn-xls'
              : 'following-delivery__export__btn-xls following-delivery__export__btn-xls--disabled'
          }
          type="button"
          onClick={() =>
            exportDraftDeliveries(
              moment
                .utc(periodExport?.selection?.startDate)
                .add(moment.duration(1, 'hours'))
                .format(),
              moment.utc(periodExport?.selection?.endDate).add(moment.duration(1, 'hours')).format()
            )
          }
        >
          <img
            className="following-delivery__export__btn-xls__img"
            src="icons/excel.png"
            alt="icon excel"
          />
        </button>
        {choosePeriodExport ? (
          <div className="following-delivery__export__date-picker">
            <DateRangePicker
              locale={fr}
              ranges={[
                {
                  startDate: periodExport?.selection?.startDate || new Date(),
                  endDate: periodExport?.selection?.endDate || new Date(),
                  key: 'selection',
                },
              ]}
              dateDisplayFormat="yyyy-MM-dd"
              onChange={(e) => setPeriodExport(e)}
              maxDate={new Date()}
            />
          </div>
        ) : (
          ''
        )}
      </div>

      {openDetails ? (
        openMiniSearchBar ? (
          <div className="following-delivery__header__search-bar-mini__open">
            <UISearchBar
              value={searchBar}
              onChange={(e) => setSearchBar(e)}
              styleInput={{
                border: '#F4F4F4',
                height: '40px',
                paddingLeft: '30px',
              }}
            />
          </div>
        ) : (
          <div className="following-delivery__header__search-bar-mini">
            <button
              type="button"
              className="following-delivery__header__search-bar-mini__button"
              onClick={() => setOpenMiniSearchBar(true)} // set true to enable the search bar
            >
              <div className="following-delivery__header__search-bar-mini__button__icon">
                <img
                  className="following-delivery__header__search-bar-mini__button__icon__img"
                  src="icons/search.png"
                  alt="dropdown arrow"
                />
              </div>
            </button>
          </div>
        )
      ) : (
        <div className="following-delivery__header__search-bar">
          <UISearchBar
            value={searchBar}
            onChange={(e) => setSearchBar(e)}
            styleInput={{
              paddingLeft: '35px',
              border: '#F4F4F4',
              width: '100%',
              height: '40px',
            }}
          />
        </div>
      )}
    </div>
  )

  return (
    <div className="following-delivery">
      {print ? (
        // Ajustement dû à la mise en prod qui à impliqué l'envoie de tout le BL en DB au lieu de uniquement la signature
        <img
          onClick={() => closePrint()}
          src={signature}
          alt="signature"
          style={{ width: '100%', height: '100%' }}
        />
      ) : (
        <>
          <Header title={t('title')} />
          <NavBar active="following" minimize={openDetails} />
          {loadingSreen && <UILoading />}
          {isErrorPopInOpen && (
            <ErrorPopIn
              text={errorPopInMessage}
              code={errorPopInCode}
              confirm={() => {
                setIsErrorPopInOpen(false)
                setLoadingSreen(false)
              }}
            />
          )}
          {isInformationPopInOpen && (
            <InformationPopIn
              text={t(informationPopInMessage)}
              confirm={() => {
                setIsInformationPopInOpen(false)
                setLoadingSreen(false)
              }}
            />
          )}

          {openDetails ? (
            <div>
              <div className="following-delivery__mini">
                {deleteMaterialIndex !== -1 && (
                  <ConfirmPopIn
                    text={t('deleteMaterial')}
                    back={() => {
                      setDeleteMaterialIndex(-1)
                      setDeleteMaterial(null)
                    }}
                    confirm={() => {
                      deleteMaterialItem()
                      setDeleteMaterialIndex(-1)
                    }}
                  />
                )}

                {openBackInformationalPopIn && (
                  <ConfirmPopIn
                    text={t('looseChangements')}
                    back={() => {
                      setOpenBackInformationalPopIn(false)
                    }}
                    confirm={() => {
                      setOpenBackInformationalPopIn(false)
                      setOpenDetails(false)
                    }}
                  />
                )}

                <div className="following-delivery__mini__container">
                  <div className="following-delivery__mini__container__left">
                    {renderHeader()}
                    <div className="following-delivery__mini__container__left__body">
                      {deliveries && (
                        <div
                          className="following-delivery__mini__container__left__body"
                          ref={loadingMore}
                        >
                          {deliveries &&
                            deliveries.draftOutboundDeliveries.nodes.map((followingLine, index) => (
                              <button
                                key={index}
                                className="following-delivery__container__body__line"
                                type="button"
                                onClick={() => setCurrentSelected(index)}
                                id={`#delivery-${index}`}
                              >
                                <FollowingDeliveryLine
                                  delivery={followingLine}
                                  isSelected={currentSelected === index}
                                  isMinimize={openDetails}
                                  onClickOpenDetails={() =>
                                    handleOpenDetails(currentSelected === index)
                                  }
                                  onClickPrint={() => printBound(followingLine.id)}
                                />
                              </button>
                            ))}
                          {more && (
                            <div className="following-delivery__container__more">
                              <button
                                type="button"
                                className="following-delivery__container__more__button"
                                onClick={() => loadFunction()}
                              >
                                <p className="following-delivery__container__more__button__text">
                                  {t('seemore')}
                                </p>
                              </button>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="following-delivery__mini__container__right">
                    {mustDisplaySavedOverlay && (
                      <div className="following-delivery__mini__container__right__overlay">
                        <WidgetOverlaySaved />
                      </div>
                    )}
                    {mustDisplayValidateOverlay && (
                      <div className="following-delivery__mini__container__right__overlay">
                        <WidgetOverlayValidate />
                      </div>
                    )}
                    <div className="following-delivery__mini__container__right__top">
                      <div className="following-delivery__mini__container__right__top__horodatage">
                        <p className="following-delivery__mini__container__right__top__horodatage__text1">
                          {t('timestamp')}
                        </p>
                        <p className="following-delivery__mini__container__right__top__horodatage__text2">
                          {`${getDate(timestamp)} ${getTime(timestamp)}` || '?'}
                        </p>
                      </div>
                      <div className="following-delivery__mini__container__right__top__input">
                        <UITextInput
                          type="string"
                          value={deliveryNumber}
                          onChange={(e) => setDeliveryNumber(e)}
                          prefix={t('inputDeliveryNumber')}
                          disabled={true}
                          styleInput={{
                            border: 'none',
                            boxShadow: 'none',
                            fontSize: '14px',
                          }}
                          stylePrefix={{
                            width: '45%',
                            justifyContent: 'flex-start',
                            fontSize: '14px',
                          }}
                          style={{ height: '30px' }}
                        />
                      </div>
                      <div className="following-delivery__mini__container__right__top__input">
                        <UITextInput
                          type="string"
                          value={saleOrder}
                          onChange={(e) => setSaleOrder(e)}
                          prefix={t('inputSapNumber')}
                          placeHolder={t('placeHolderSapNumber')}
                          success={saleOrderId}
                          styleInput={{ fontSize: '14px', width: '100%' }}
                          stylePrefix={{
                            width: '40%',
                            justifyContent: 'flex-start',
                            fontSize: '14px',
                          }}
                          style={{ height: '30px' }}
                        />
                        <button
                          className="following-delivery__mini__container__right__top__input__icon"
                          type="button"
                          onClick={() => console.log('over view....')}
                        >
                          <img
                            className="following-delivery__mini__container__right__top__input__icon__img"
                            src="icons/eye.png"
                            alt="view more"
                          />
                        </button>
                      </div>
                    </div>

                    <div className="following-delivery__mini__container__right__middle">
                      <div className="following-delivery__mini__container__right__middle__cliNim">
                        <div className="following-delivery__mini__container__right__middle__input">
                          <UITextInputAutoCompletion
                            type="string"
                            value={client}
                            valueId={setClientId}
                            onChange={(e) => setClient(e)}
                            prefix={t('inputClient')}
                            placeHolder={t('placeholderClient')}
                            success={clientId}
                            style={{ flexDirection: 'column', width: '95%' }}
                            styleInput={{ fontSize: '14px', width: '100%' }}
                            stylePrefix={{
                              width: '100%',
                              justifyContent: 'flex-start',
                              marginBottom: '5px',
                              fontSize: '14px',
                            }}
                            autoCompleteList={clients}
                            autoCompleteType="clients"
                            styleAutoComplete={{
                              width: '300px',
                              marginTop: '40px',
                              marginLeft: '-150px',
                            }}
                          />
                        </div>

                        <div className="following-delivery__mini__container__right__middle__input">
                          <UITextInputAutoCompletion
                            type="string"
                            value={freightForwarder}
                            valueId={setFreightForwarderId}
                            onChange={(e) => setFreightForwarder(e)}
                            prefix={t('inputFreightForwarder')}
                            placeHolder={t('placeHolderFreightForwarder')}
                            success={freightForwarderId}
                            style={{ flexDirection: 'column', width: '95%' }}
                            styleInput={{ fontSize: '14px', width: '100%' }}
                            stylePrefix={{
                              width: '100%',
                              justifyContent: 'flex-start',
                              marginBottom: '5px',
                              fontSize: '14px',
                            }}
                            autoCompleteList={getFreightForwarders}
                            styleAutoComplete={{
                              width: '300px',
                              marginTop: '40px',
                              marginLeft: '-150px',
                            }}
                          />
                        </div>
                      </div>

                      <div className="following-delivery__mini__container__right__middle__cliNim">
                        <div className="following-delivery__mini__container__right__middle__input">
                          <UITextInput
                            type="string"
                            value={externRef}
                            onChange={(e) => setExternRef(e)}
                            prefix={t('inputExternRef')}
                            placeHolder={t('placeHolderExternRef')}
                            success={externRef}
                            style={{ width: '95%' }}
                            styleContainer={{ width: '100%' }}
                            styleInput={{ fontSize: '14px', width: '100%' }}
                            stylePrefix={{
                              width: '100%',
                              justifyContent: 'flex-start',
                              marginBottom: '5px',
                              fontSize: '14px',
                            }}
                          />
                        </div>

                        <div className="following-delivery__mini__container__right__middle__input">
                          <UITextInput
                            type="string"
                            value={licensePlate}
                            onChange={(e) => setLicensePlate(e)}
                            prefix={t('inputImmatriculation')}
                            placeHolder={t('placeHolderImmatriculation')}
                            success={truckId}
                            style={{ width: '95%' }}
                            styleContainer={{ width: '100%' }}
                            styleInput={{ fontSize: '14px', width: '100%' }}
                            stylePrefix={{
                              width: '100%',
                              justifyContent: 'flex-start',
                              marginBottom: '5px',
                              fontSize: '14px',
                            }}
                          />
                        </div>
                      </div>

                      <div className="following-delivery__mini__container__right__middle__cliNim">
                        <div className="following-delivery__mini__container__right__middle__input">
                          <UITextInput
                            type="string"
                            value={constructionSiteCode}
                            onChange={(e) => setConstructionSiteCode(e)}
                            prefix={t('constructionSitecode')}
                            placeHolder={t('placeHolderConstructionSitecode')}
                            success={constructionSiteId}
                            style={{ width: '95%' }}
                            styleContainer={{ width: '100%' }}
                            styleInput={{ fontSize: '14px', width: '100%' }}
                            stylePrefix={{
                              width: '100%',
                              justifyContent: 'flex-start',
                              marginBottom: '5px',
                              fontSize: '14px',
                            }}
                          />
                        </div>
                        <div className="following-delivery__mini__container__right__middle__input">
                          <UITextInput
                            type="string"
                            value={destinationSite}
                            onChange={(e) => setDestinationSite(e)}
                            prefix={t('inputDestinationSite')}
                            placeHolder={t('placeHolderDestinationSite')}
                            success={destinationSite}
                            style={{ width: '95%' }}
                            styleContainer={{ width: '40%' }}
                            styleInput={{ fontSize: '14px', width: '100%' }}
                            stylePrefix={{
                              width: '100%',
                              justifyContent: 'flex-start',
                              marginBottom: '5px',
                              fontSize: '14px',
                            }}
                          />
                        </div>
                      </div>

                      <div className="following-delivery__mini__container__right__middle__cliNim">
                        <div className="following-delivery__mini__container__right__middle__input">
                          <UITextInput
                            type="string"
                            value={constructionSiteAddress}
                            onChange={(e) => setConstructionSiteAddress(e)}
                            prefix={t('inputAddress')}
                            placeHolder={t('placeHolderAddress')}
                            success={constructionSiteId}
                            style={{ flexDirection: 'column', width: '95%' }}
                            styleInput={{ fontSize: '14px', width: '100%' }}
                            stylePrefix={{
                              width: '100%',
                              justifyContent: 'flex-start',
                              marginBottom: '5px',
                              fontSize: '14px',
                            }}
                          />
                        </div>

                        <div className="following-delivery__mini__container__right__middle__input">
                          <UITextInput
                            type="string"
                            value={constructionSiteCity}
                            onChange={(e) => setConstructionSiteCity(e)}
                            prefix={t('inputCity')}
                            placeHolder={t('placeHolderCity')}
                            success={constructionSiteId}
                            style={{ flexDirection: 'column', width: '95%' }}
                            styleInput={{ fontSize: '14px', width: '100%' }}
                            stylePrefix={{
                              width: '100%',
                              justifyContent: 'flex-start',
                              marginBottom: '5px',
                              fontSize: '14px',
                            }}
                          />
                        </div>
                      </div>

                      <div className="following-delivery__mini__container__right__middle__products">
                        <h2 className="following-delivery__mini__container__right__middle__products__tittle">
                          {t('products')}
                        </h2>
                        <div className="following-delivery__mini__container__right__middle__products__grid">
                          <div className="following-delivery__mini__container__right__middle__products__grid__informations">
                            {materials.map((product, index) => (
                              <div
                                key={index}
                                className="following-delivery__mini__container__right__middle__products__grid__informations__line"
                              >
                                <ProductsLine
                                  type="following-delivery"
                                  product={product}
                                  onChange={(e) => updateItem('quantity', e, index)}
                                  onDeleteClick={() => {
                                    setDeleteMaterialIndex(index)
                                    setDeleteMaterial(product)
                                  }}
                                />
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="following-delivery__mini__container__right__bottom">
                      <div className="following-delivery__mini__container__right__bottom__button">
                        <UIButton
                          type="secondary"
                          text={t('back')}
                          onClick={() => setOpenBackInformationalPopIn(true)}
                          alt={t('back')}
                          style={{ width: '100%', height: '50px' }}
                        />
                      </div>
                      <div className="following-delivery__mini__container__right__bottom__button">
                        <UIButton
                          type="primary"
                          text={t('register')}
                          onClick={() => registerModifications()}
                          alt={t('register')}
                          style={{ width: '100%', height: '50px' }}
                        />
                      </div>
                      <div className="following-delivery__mini__container__right__bottom__button">
                        <UIButton
                          type="primary"
                          text={t('valid')}
                          onClick={() => validateModifications()}
                          disabled={disableValidateButton}
                          alt={t('valid')}
                          style={{ width: '100%', height: '50px' }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <LayoutContainer>
              <>
                {renderHeader()}
                <div className="following-delivery__container">
                  {deliveries && (
                    <div className="following-delivery__container__body" ref={loadingMore}>
                      {deliveries?.draftOutboundDeliveries?.nodes?.map((followingLine, index) => (
                        <button
                          key={index}
                          className="following-delivery__container__body__line"
                          type="button"
                          onClick={() => setCurrentSelected(index)}
                          id={`#delivery-${index}`}
                        >
                          <FollowingDeliveryLine
                            delivery={followingLine}
                            isSelected={currentSelected === index}
                            isMinimize={openDetails}
                            onClickOpenDetails={() => setOpenDetails(true)}
                            onClickPrint={() => printBound(followingLine.id)}
                          />
                        </button>
                      ))}

                      {more && (
                        <div className="following-delivery__container__more">
                          <button
                            type="button"
                            className="following-delivery__container__more__button"
                            onClick={() => loadFunction()}
                          >
                            <p>{t('seemore')}</p>
                          </button>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </>
            </LayoutContainer>
          )}
        </>
      )}
    </div>
  )
}

export default FollowingDelivery
