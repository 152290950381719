import React from 'react'
import { PropTypes } from 'prop-types'
import { useTranslation } from 'react-i18next'
import './ConfirmPopIn.scss'

import UIButton from '../_UI/UIButton/UIButton.js'

const ConfirmPopIn = (props) => {
  const { text, back, confirm } = props

  const { t } = useTranslation('informationalPopIn')

  return (
    <div className="confirmPopIn">
      <h1 className="confirmPopIn__title">{t('title')}</h1>
      <div className="confirmPopIn__text">
        <p>{text}</p>
      </div>

      <div className="confirmPopIn__buttons">
        <UIButton type="secondary" text={t('back')} onClick={back} alt={t('back')} />

        <UIButton type="primary" text={t('confirm')} onClick={confirm} alt={t('confirm')} />
      </div>
    </div>
  )
}

ConfirmPopIn.propTypes = {
  text: PropTypes.string.isRequired,
  back: PropTypes.func.isRequired,
  confirm: PropTypes.func.isRequired,
}

export default ConfirmPopIn
