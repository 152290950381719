/* eslint-disable camelcase */
import { useLazyQuery, useQuery } from '@apollo/client'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import api from '../../api/index.js'
import { PrintDelivery } from '../../components'
import { gqlDelivery, gqlUsers } from '../../gql'
import { getUserFromResponse } from '../../lib/helpers'

const PrintPage = () => {
  const access_token = localStorage.getItem('access_token')
  const [delivery, setDelivery] = useState()
  const [materials, setMaterials] = useState([])
  const [signature, setSignature] = useState()
  const { id } = useParams()

  const [fetchConstructionSiteById, { data: constructionSite }] = useLazyQuery(
    gqlDelivery.CONSTRUCTION_SITE_BY_ID,
    {
      fetchPolicy: 'network-only',
    }
  )

  const [fetchSiteCode, { data: siteCode }] = useLazyQuery(gqlUsers.GET_SITE_BY_ID, {})

  const { data: user } = useQuery(gqlUsers.USER, {
    variables: {
      id: getUserFromResponse({ access_token }).id,
    },
    onCompleted: (response) => {
      fetchSiteCode({
        variables: {
          id: response.user.idSite,
        },
      })
    },
  })

  const { data: fetchedDelivery } = useQuery(gqlDelivery.DELIVERY_BY_ID, {
    variables: {
      id,
    },
    onCompleted: () => {
      setDelivery(fetchedDelivery.draftOutboundDelivery)
    },
  })

  useEffect(() => {
    if (delivery) {
      const token = localStorage.getItem('access_token')
      api.getSignature(delivery.id, token, (resp) => {
        setSignature(resp)
      })

      const tempMaterials = []
      const prefix = delivery?.draftOutboundDeliveryItemsByIdDraftOutboundDelivery.nodes
      for (let i = 0; i < prefix.length; i++) {
        const name = prefix[i].materialByIdMaterial.description
        const { quantity } = prefix[i]
        const unit = prefix[i].materialByIdMaterial.baseMeasureUnitCode
        tempMaterials.push({
          name,
          quantity,
          unit,
        })
      }
      setMaterials(tempMaterials)
      fetchConstructionSiteById({
        variables: {
          id: delivery.idConstructionSite,
        },
      })
    }
  }, [delivery, fetchConstructionSiteById])

  return (
    <div className="printPage">
      {delivery && user && (
        <PrintDelivery
          type="print-page"
          saleOrderCode={delivery.code}
          date={delivery.createdAt}
          client={delivery.saleOrderClientName}
          site={siteCode?.site?.name}
          firstname={user?.user?.firstname}
          lastname={user?.user?.lastname}
          constructionSiteCode={constructionSite?.constructionSite?.code}
          constructionSiteAddress={delivery.constructionSiteAddress}
          constructionSiteCity={delivery.constructionSiteCity}
          licensePlate={delivery.truckLicencePlate}
          refExt={delivery.externalReference}
          materials={materials}
          signature={signature}
        />
      )}
    </div>
  )
}

export default PrintPage
