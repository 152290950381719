import { useMutation, useQuery } from '@apollo/client'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  ConfirmPopIn,
  Header,
  LayoutContainer,
  NavBar,
  TabletUserGrid,
  UIButton,
  UIDropDown,
  UISearchBar,
  UITextInput,
  WidgetOverlayValidate,
} from '../../components'
import { gqlTablets, gqlUsers } from '../../gql'
import { captureMessageWithAttachment } from '../../lib/sentry'
import './Tablets.scss'

const Tablets = () => {
  const { t } = useTranslation('tablets')

  const optionsState = [
    { id: 'toValidate', name: 'A valider' },
    { id: 'tabletsValidate', name: 'Tablettes' },
  ]

  const [sortSite, setSortSite] = useState()
  const [state, setState] = useState('toValidate')
  const [search, setSearch] = useState('')
  const [deleteOverlay, setDeleteOverlay] = useState(false)
  const [mustDisplaySavedOverlay, setMustDisplaySavedOverlay] = useState(false)

  const [tabletId, setTabletId] = useState('')
  const [tabletName, setTabletName] = useState('')

  const [userSelected, setUserSelected] = useState()

  const [currentSelected, setCurrentSelected] = useState()

  const { data: sites } = useQuery(gqlUsers.SITES)

  const { data: tabletsNoValid } = useQuery(gqlTablets.DEVICES_NO_VALIDATE, {
    fetchPolicy: 'network-only',
  })

  const { data: tablets } = useQuery(gqlTablets.DEVICES, {
    fetchPolicy: 'network-only',
  })

  const { data: users } = useQuery(gqlUsers.USERS, {
    variables: {
      companyRole: 'Conducteur',
    },
  })

  const [validateDevice] = useMutation(gqlTablets.DEVICE_VALIDATION, {
    refetchQueries: [{ query: gqlTablets.DEVICES_NO_VALIDATE }],
    onCompleted: () => {
      setMustDisplaySavedOverlay(true)
      setTimeout(() => setMustDisplaySavedOverlay(false), 1000)
    },
    onError: (err) => {
      const request = gqlTablets.DEVICE_VALIDATION
      const event = {
        request: request?.definitions[0]?.name?.value,
        variables: {
          id: tabletsNoValid.deviceSeeds.nodes[currentSelected].id,
          idUser: userSelected,
        },
      }
      captureMessageWithAttachment(event, err)
    },
  })

  const [deleteDevice] = useMutation(gqlTablets.DEVICE_DELETE, {
    variables: {
      id: tablets?.devices?.nodes[currentSelected]?.id,
    },
    refetchQueries: [{ query: gqlTablets.DEVICES }],
    optimisticResponse: {
      deleteDevice: {
        clientMutationId: null,
        device: {
          id: tablets?.devices?.nodes[currentSelected]?.id,
          __typename: 'Device',
        },
        __typename: 'DeleteDevicePayload',
      },
    },
    onError: (err) => {
      const request = gqlTablets.DEVICE_DELETE
      const event = {
        request: request?.definitions[0]?.name?.value,
        variables: {
          id: tablets?.devices?.nodes[currentSelected]?.id,
        },
      }
      captureMessageWithAttachment(event, err)
    },
  })

  const [deleteSeed] = useMutation(gqlTablets.SEED_DELETE, {
    variables: {
      id: tabletsNoValid?.deviceSeeds?.nodes[currentSelected]?.id,
    },
    refetchQueries: [{ query: gqlTablets.DEVICES_NO_VALIDATE }],
    optimisticResponse: {
      deleteDevice: {
        clientMutationId: null,
        deleteDeviceSeed: {
          id: tabletsNoValid?.deviceSeeds?.nodes[currentSelected]?.id,
          __typename: 'Device',
        },
        __typename: 'DeleteDeviceSeedPayload',
      },
    },
    onError: (err) => {
      const request = gqlTablets.SEED_DELETE
      const event = {
        request: request?.definitions[0]?.name?.value,
        variables: {
          id: tabletsNoValid?.deviceSeeds?.nodes[currentSelected]?.id,
        },
      }
      captureMessageWithAttachment(event, err)
    },
  })

  const [updateDeviceName] = useMutation(gqlTablets.UPDATE_TABLET_NAME, {
    variables: {
      id: tabletId && tabletId,
      name: tabletName,
    },
    optimisticResponse: {
      updateDevice: {
        clientMutationId: null,
        device: {
          id: tabletId && tabletId,
          name: tabletName,
          __typename: 'Device',
        },
        __typename: 'UpdateDevicePayload',
      },
    },
    onCompleted: () => {
      setMustDisplaySavedOverlay(true)
      setTimeout(() => setMustDisplaySavedOverlay(false), 1000)
    },
    onError: (err) => {
      const request = gqlTablets.UPDATE_TABLET_NAME
      const event = {
        request: request?.definitions[0]?.name?.value,
        variables: {
          id: tabletId && tabletId,
          name: tabletName,
        },
      }
      captureMessageWithAttachment(event, err)
    },
  })

  useEffect(() => {
    if (tablets && currentSelected !== undefined && state === 'tabletsValidate') {
      setTabletId(tablets.devices.nodes[currentSelected].id || '')
      setTabletName(tablets.devices.nodes[currentSelected].name || '')
    }
  }, [currentSelected, tablets, state])

  useEffect(() => {
    setCurrentSelected(undefined)
  }, [state])

  useEffect(() => {
    if (users) {
      setUserSelected(users.users.nodes[0]?.id)
    }
  }, [users])

  return (
    <div className="tablets">
      <Header title={t('title')} />
      <NavBar active="tablets" />
      <LayoutContainer>
        <div className="tablets__container">
          {deleteOverlay && (
            <ConfirmPopIn
              text={t('deleteText')}
              back={() => {
                setDeleteOverlay(false)
              }}
              confirm={() => {
                if (state === 'toValidate') {
                  deleteSeed()
                } else {
                  deleteDevice()
                }
                setDeleteOverlay(false)
                setCurrentSelected(undefined)
              }}
            />
          )}

          {mustDisplaySavedOverlay && (
            <div className="tablets__container__overlay">
              <WidgetOverlayValidate />
            </div>
          )}
          <div className="tablets__container__left">
            <div className="tablets__container__left__header">
              <h2 className="tablets__container__left__header__sort">{t('sort')}</h2>
              {sites?.sites?.nodes && (
                <div className="tablets__container__left__header__dropdown">
                  <UIDropDown
                    options={sites?.sites?.nodes}
                    onChange={(e) => setSortSite(e.target.value)}
                    value={sortSite}
                    placeholder={t('site')}
                    style={{ width: '170px' }}
                    disabled={state === 'toValidate'}
                  />
                </div>
              )}

              <div className="tablets__container__left__header__dropdown">
                <UIDropDown
                  options={optionsState}
                  onChange={(e) => setState(e.target.value)}
                  value={state}
                  // placeholder={t('state')}
                  style={{ width: '100%' }}
                />
              </div>
              <div className="tablets__container__left__header__search">
                <UISearchBar
                  value={search}
                  onChange={(e) => setSearch(e)}
                  disable={true}
                  styleInput={{
                    height: '40px',
                    paddingLeft: '35px',
                    backgroundColor: '#F4F4F4',
                    border: '#F4F4F4',
                  }}
                />
              </div>
            </div>

            <div className="tablets__container__left__grid">
              {state === 'toValidate' &&
                (tabletsNoValid?.deviceSeeds.totalCount > 0 ? (
                  <TabletUserGrid
                    type="tablets"
                    data={tabletsNoValid && tabletsNoValid.deviceSeeds.nodes}
                    waiting={true}
                    selected={currentSelected}
                    onChangeSelected={setCurrentSelected}
                    onDelete={() => setDeleteOverlay(true)}
                  />
                ) : (
                  <p>{t('noNewTablet')}</p>
                ))}

              {state === 'tabletsValidate' && (
                <TabletUserGrid
                  type="tablets"
                  data={tablets && tablets.devices.nodes}
                  selected={currentSelected}
                  onChangeSelected={setCurrentSelected}
                  onDelete={() => setDeleteOverlay(true)}
                />
              )}
            </div>
          </div>
          <div className="tablets__container__right">
            <div className="tablets__container__right__top">
              <div className="tablets__container__right__top__fleet">
                <p className="tablets__container__right__top__fleet__title">{t('fleet')}</p>
                <p className="tablets__container__right__top__fleet__number">
                  {tablets && tablets.devices.totalCount}
                </p>
                <p className="tablets__container__right__top__fleet__text">{t('tablets')}</p>
              </div>
              {users && state === 'toValidate' && (
                <div className="tablets__container__right__top__selec-user">
                  <UIDropDown
                    options={users?.users.nodes}
                    defaultValue={users?.users.nodes[0]?.id}
                    onChange={(e) => setUserSelected(e.target.value)}
                    style={{ width: '80%' }}
                  />
                </div>
              )}

              <div className="tablets__container__right__top__button">
                <UIButton
                  type="primary"
                  text={t('newTablet')}
                  alt={t('newTablet')}
                  onClick={() =>
                    validateDevice({
                      variables: {
                        idDeviceSeed:
                          tabletsNoValid &&
                          currentSelected !== undefined &&
                          state === 'toValidate' &&
                          tabletsNoValid.deviceSeeds.nodes[currentSelected].id,
                        idUser: userSelected,
                      },
                    })
                  }
                  disabled={
                    state === 'tabletsValidate' || currentSelected === undefined || !userSelected
                  }
                />
              </div>
            </div>

            {tablets && state === 'tabletsValidate' && (
              <div className="tablets__container__right__middle">
                <p className="tablets__container__right__middle__title">{t('informations')}</p>
                <div className="tablets__container__right__middle__form">
                  <div className="tablets__container__right__middle__form__id-container">
                    <p className="tablets__container__right__middle__form__id-container__id-prefix">
                      {t('id')}
                    </p>
                    <p className="tablets__container__right__middle__form__id-container__id">
                      {tabletId}
                    </p>
                  </div>

                  <div className="tablets__container__right__middle__form__input">
                    <UITextInput
                      type="string"
                      value={tabletName}
                      onChange={(e) => setTabletName(e)}
                      prefix={t('name')}
                      placeHolder={t('namePrefix')}
                      stylePrefix={{ width: '20%', color: '#0583F2', fontWeight: '700' }}
                    />
                  </div>
                </div>
                <div className="tablets__container__right__middle__button">
                  <UIButton
                    type="primary"
                    text={t('register')}
                    onClick={() => updateDeviceName()}
                    alt={t('register')}
                    style={{ width: '100%', height: '55px' }}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </LayoutContainer>
    </div>
  )
}

export default Tablets
