import React from 'react'
import { useTranslation } from 'react-i18next'
import './WidgetOverlayValidate.scss'

const WidgetOverlayValidate = () => {
  const { t } = useTranslation('overlay')

  return (
    <div className="overlayValidate">
      <div className="overlayValidate__background" />
      <div className="overlayValidate__layout">
        <div className="overlayValidate__layout__container">
          <div className="overlayValidate__layout__container__icon">
            <img
              className="overlayValidate__layout__container__icon__img"
              src="icons/validate.png"
              alt="validate"
            />
          </div>
          <p className="overlayValidate__layout__container__text">{t('validate')}</p>
        </div>
      </div>
    </div>
  )
}

export default WidgetOverlayValidate
