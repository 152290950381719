import { PropTypes } from 'prop-types'
import React from 'react'
import './UIButton.scss'

const UIButton = (props) => {
  const { type, text, onClick, alt, style, disabled, submit } = props

  return (
    <button
      type={submit ? 'submit' : 'button'}
      className={`uiButton uiButton--${type}`}
      onClick={onClick}
      alt={alt}
      style={style}
      disabled={disabled}
    >
      <p className={`uiButton_label uiButton_label--${type}`}>{text}</p>
    </button>
  )
}

UIButton.propTypes = {
  type: PropTypes.oneOf(['primary', 'secondary']).isRequired,
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  alt: PropTypes.string,
  disabled: PropTypes.bool,
  style: PropTypes.shape({}),
  submit: PropTypes.bool,
}

UIButton.defaultProps = {
  disabled: false,
  style: null,
  alt: '',
  submit: false,
}

export default UIButton
