import moment from 'moment'

export const parseTime = (sec, hourText = 'hr', minText = 'min', plural = 's') => {
  if (!sec) {
    return `0${minText}`
  }
  const utc = moment.utc(sec * 1000)
  const hours = Number(utc.format('HH'))
  const min = Number(utc.format('mm'))

  let formatted = ''
  if (hours > 0) {
    formatted = `${hours}${hourText}`
    if (hours > 1) {
      formatted += plural
    }
    formatted += ' '
  }

  if (min > 0) {
    formatted += `${min}${minText}`
    if (min > 1) {
      formatted += plural
    }
  }

  return formatted
}

export const formatPhoneNumber = (phoneNumber) => {
  if (!phoneNumber) {
    return ''
  }
  let newPhoneNumber = ''
  for (let i = 0; i < phoneNumber.length; i++) {
    if (i % 2 === 0) {
      newPhoneNumber += ' '
    }
    newPhoneNumber += phoneNumber[i]
  }

  return newPhoneNumber
}

export const getDate = (timestamp) => {
  const UTCDate = timestamp
  if (UTCDate) {
    const day = UTCDate.substring(8, 10)
    const month = UTCDate.substring(5, 7)
    const year = UTCDate.substring(0, 4)
    return `${day}/${month}/${year}`
  }
}

export const getTime = (timestamp) => {
  const UTCDate = timestamp
  if (UTCDate) {
    const time = moment(timestamp).format('HH:mm')
    return time
  }
}
