import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
import api from '../../api'
import { UIButton, UITextInput } from '../../components'
import './Login.scss'

const Login = () => {
  const { t } = useTranslation('login')
  const navigate = useNavigate()

  const [login, setLogin] = useState('')
  const [password, setPassword] = useState('')
  const [wrongLogin, setWrongLogin] = useState(false)

  const sendLogin = (event) => {
    event.preventDefault()
    api
      .submitCredentials(login, password)
      .then((response) => {
        localStorage.setItem('access_token', response.access_token)
        localStorage.setItem('refresh_token', response.refresh_token)
        navigate('/following-delivery')
      })
      .catch(() => setWrongLogin(true))
  }

  return (
    <div className="login" style={{ backgroundImage: 'url(images/bdn-bg-image.png)' }}>
      <div className="login__container">
        <div className="login__container__layout">
          <img
            className="login__container__layout__logo-bdn"
            src="images/Logo-BDN.png"
            alt="logoBDN"
          />
          <form onSubmit={sendLogin}>
            <UITextInput
              type="email"
              value={login}
              onChange={(e) => {
                setLogin(e)
              }}
              error={wrongLogin}
              placeHolder={t('emailPlaceHolder')}
              styleInput={{ width: '100%', marginBottom: 10 }}
              autoFocus
            />
            <UITextInput
              type="password"
              value={password}
              onChange={(e) => {
                setPassword(e)
              }}
              error={wrongLogin}
              placeHolder={t('passwordPlaceHolder')}
              styleInput={{ width: '100%' }}
            />
            <p
              className={
                wrongLogin
                  ? 'login__container__layout__error'
                  : 'login__container__layout__no-error'
              }
            >
              {t('wrongLogin')}
            </p>
            <div className="login__container__layout__submit">
              <UIButton type="primary" text={t('connect')} alt={t('connect')} submit />
              <Link className="login__container__layout__submit__link" to="/forgot-password">
                <p>{t('forgottenPassword')}</p>
              </Link>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default Login
