import clsx from 'clsx'
import PropTypes from 'prop-types'
import React, { useRef } from 'react'
import './UIUploadFile.scss'

const UIUploadFile = ({
  uploadBtnText = 'Upload',
  type = 'button',
  disabled = false,
  onChange = () => {},
  acceptType = ['csv'],
  kind = 'primary',
  formatText = '',
  onError = () => {},
}) => {
  const hiddenFileInput = useRef()

  const handleChange = async (event) => {
    const file = event.target.files[0]
    if (!file) return

    if (acceptType?.includes(file?.type?.split('/')[1])) {
      onChange(file)
    } else {
      onError({ acceptType: true })
    }
  }

  const handleClick = () => {
    hiddenFileInput.current.click()
  }

  return (
    <div>
      <div>
        <button
          onClick={handleClick}
          type={type}
          disabled={disabled}
          className={clsx({
            uploadBtn: true,
            uploadBtn__primary: kind === 'primary',
          })}
        >
          <p className="uploadBtn__text">{uploadBtnText}</p>
          {formatText && <p className="uploadBtn__formatText">{formatText}</p>}
        </button>
      </div>

      <input
        type="file"
        disabled={disabled}
        className="form-control form-control-sm"
        id="media-URL"
        style={{ display: 'none' }}
        ref={hiddenFileInput}
        label={uploadBtnText}
        accept={acceptType?.map((type) => `.${type}`).join(',') || '.csv'}
        onChange={(event) => handleChange(event)}
      />
    </div>
  )
}

UIUploadFile.propTypes = {
  uploadBtnText: PropTypes.string,
  file: PropTypes.any,
  onChange: PropTypes.func,
  removeFile: PropTypes.func,
  dynamicStyle: PropTypes.array,
  multiple: PropTypes.bool,
  type: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  btnColor: PropTypes.string,
  disabled: PropTypes.bool,
  onError: PropTypes.func,
  acceptType: PropTypes.array,
  kind: PropTypes.string,
  formatText: PropTypes.string,
}

export { UIUploadFile }
