import React from 'react'
import { PropTypes } from 'prop-types'
import { useTranslation } from 'react-i18next'
import './InformationPopIn.scss'

import UIButton from '../_UI/UIButton/UIButton.js'

const InformationPopIn = (props) => {
  const { text, confirm } = props

  const { t } = useTranslation('informationalPopIn')

  return (
    <div className="informationPopIn">
      <h1 className="informationPopIn__title">{t('titleInformation')}</h1>
      <div className="informationPopIn__text">
        <p>{text}</p>
      </div>

      <div className="informationPopIn__buttons">
        <UIButton type="primary" text={t('confirm')} onClick={confirm} alt={t('confirm')} />
      </div>
    </div>
  )
}

InformationPopIn.propTypes = {
  text: PropTypes.string.isRequired,
  confirm: PropTypes.func.isRequired,
}

export default InformationPopIn
