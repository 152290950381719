import { PropTypes } from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { TruckModif } from '../..'
import { getDate, getTime } from '../../../lib/utils'
import './Truck.scss'

const Truck = (props) => {
  const { truck, isSelected, truckGone, refreshData } = props

  const { t } = useTranslation('truck')
  const navigate = useNavigate()

  const [openTruckModif, setOpenTruckModif] = useState(false)
  const [numberOfWeighting, setNumberOfWeighting] = useState(0)

  useEffect(() => {
    let count = 0
    if (truck.weighings.weightRubble !== 0) {
      count += 1
    }
    if (truck?.weighings?.weightMaterials !== 0) {
      count += 1
    }
    if (truck?.weighings?.weightTare !== 0) {
      count += 1
    }
    setNumberOfWeighting(count)
  }, [truck])

  return (
    <div className="truck-line">
      {openTruckModif && (
        <div className="truck-line__weight-modif__overlay">
          <TruckModif
            truck={truck?.truck}
            refreshData={refreshData}
            weighings={truck?.weighings}
            unloadedWeight={truck?.truck?.unloadedWeight}
            onClickBack={() => setOpenTruckModif(false)}
            onClickRegister={() => setOpenTruckModif(false)}
          />
        </div>
      )}

      <div
        className={
          isSelected
            ? 'truck-line__informations truck-line__informations__selected'
            : 'truck-line__informations'
        }
      >
        <p className="truck-line__informations__immat">{truck?.truck?.licencePlate}</p>
        <p className="truck-line__informations__empty-weight">
          {truck?.truck?.unloadedWeight !== 0 ? `${truck?.truck?.unloadedWeight} T` : '?'}
        </p>
        <p className="truck-line__informations__date">{getDate(truck?.weighings?.createdAt)}</p>
        <p className="truck-line__informations__time">{getTime(truck?.weighings?.createdAt)}</p>

        <button
          type="button"
          className="truck-line__informations__weight"
          onClick={() => setOpenTruckModif(true)}
        >
          <p className="truck-line__informations__weight__text">{`${numberOfWeighting} ${t(
            'weight'
          )} `}</p>
        </button>

        <div className="truck-line__informations__group-button">
          <button
            className="truck-line__informations__group-button__button"
            type="button"
            onClick={() => navigate(`/new-delivery?licensePlate=${truck?.truck?.licencePlate}`)}
          >
            <div className="truck-line__informations__group-button__button__icon">
              <img
                className="truck-line__informations__group-button__button__icon__img"
                src="icons/plus-circle.png"
                alt="add"
              />
            </div>
          </button>

          <button
            className="truck-line__informations__group-button__button"
            type="button"
            onClick={() => setOpenTruckModif(true)}
          >
            <div className="truck-line__informations__group-button__button__icon">
              <img
                className="truck-line__informations__group-button__button__icon__img"
                src="icons/edit.png"
                alt="edit"
              />
            </div>
          </button>

          <button
            className="truck-line__informations__group-button__button"
            type="button"
            onClick={truckGone}
          >
            <div className="truck-line__informations__group-button__button__icon">
              <img
                className="truck-line__informations__group-button__button__icon__img"
                src="icons/supr.png"
                alt="supr"
              />
            </div>
          </button>
        </div>
      </div>
    </div>
  )
}

Truck.propTypes = {
  truck: PropTypes.shape({
    weighings: PropTypes.shape({
      weightRubble: PropTypes.number.isRequired,
      weightMaterials: PropTypes.number.isRequired,
      weightTare: PropTypes.number.isRequired,
      createdAt: PropTypes.string.isRequired,
    }),
    truck: PropTypes.shape({
      id: PropTypes.string.isRequired,
      licencePlate: PropTypes.string.isRequired,
      unloadedWeight: PropTypes.number.isRequired,
    }).isRequired,
  }).isRequired,
  isSelected: PropTypes.bool.isRequired,
  truckGone: PropTypes.func.isRequired,
  refreshData: PropTypes.func.isRequired,
}

export default Truck
