import React from 'react'
import { PropTypes } from 'prop-types'
import { useTranslation } from 'react-i18next'
import './ErrorPopIn.scss'

import UIButton from '../_UI/UIButton/UIButton.js'

const ErrorPopIn = (props) => {
  const { code, text, confirm } = props

  const { t } = useTranslation('informationalPopIn')

  return (
    <div className="errorPopIn">
      <h1 className="errorPopIn__title">{t('titleError')}</h1>
      <h2 className="errorPopIn__code">{code}</h2>
      <div className="errorPopIn__text">
        <p>{text}</p>
      </div>

      <div className="errorPopIn__buttons">
        <UIButton type="primary" text={t('ok')} onClick={confirm} alt={t('ok')} />
      </div>
    </div>
  )
}

ErrorPopIn.propTypes = {
  code: PropTypes.string,
  text: PropTypes.string.isRequired,
  confirm: PropTypes.func.isRequired,
}

ErrorPopIn.defaultProps = {
  code: '',
}

export default ErrorPopIn
