import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { v4 as uuid } from 'uuid'
import {
  Header,
  LayoutContainer,
  MaterialsDelivery,
  NavBar,
  NewDeliveryStep1,
  NewDeliveryStep2,
  NewDeliveryStep3,
  PrintDelivery,
  UIButton,
  WidgetOverlayValidate,
} from '../../components'
import { gqlClients, gqlDelivery, gqlMaterials, gqlTrucks, gqlUsers } from '../../gql'
import {
  formatNumberWithThreeDecimals,
  getUserFromResponse,
} from '../../lib/helpers'
import { captureMessageWithAttachment } from '../../lib/sentry'
import './NewDelivery.scss'

const NewDelivery = () => {
  const { t } = useTranslation('newDelivery')
  const access_token = localStorage.getItem('access_token')
  const navigate = useNavigate()
  const { search } = window.location
  const params = new URLSearchParams(search)
  const licensePlateURI = params.get('licensePlate')
  const duplicate = params.get('duplicate')

  const [print, setPrint] = useState(false)
  const [createFile, setCreateFile] = useState(false)

  const [deliveryId, setDeliveryId] = useState()
  // [firstStep - secondStep - resumeStep]
  const [step, setStep] = useState('firstStep')
  const [mustDisplaySavedOverlay, setMustDisplaySavedOverlay] = useState(false)

  // First Step
  const [saleOrderCode, setSaleOrderCode] = useState('')
  const [saleOrderId, setSaleOrderId] = useState()
  const [saleOrderSucess, setSaleOrderSucess] = useState(false)

  const [client, setClient] = useState('')
  const [clientId, setClientId] = useState('')

  const [freightForwarders, setFreightForwarders] = useState()
  const [freightForwardersId, setFreightForwardersId] = useState()

  const [constructionSiteCode, setConstructionSiteCode] = useState('')
  const [constructionSiteId, setConstructionSiteId] = useState()
  const [constructionSiteSuccess, setConstructionSiteSuccess] = useState(false)
  const [constructionSiteAddress, setConstructionSiteAddress] = useState('')
  const [constructionSiteCity, setConstructionSiteCity] = useState('')
  const [deliveryCode, setDeliveryCode] = useState()

  const [licensePlate, setLicensePlate] = useState('')
  const [truckId, setTruckId] = useState()

  const [externRef, setExternRef] = useState()
  const [destinationSite, setDestinationSite] = useState()
  const [bdcFile, setBdcFile] = useState()
  const [evacuation, setEvacuation] = useState(false)

  // Second Step
  const [clearingName, setClearingName] = useState('')
  const [clearingId, setClearingId] = useState()
  const [clearingWeight, setClearingWeight] = useState('')
  const [clearingUnit, setClearingUnit] = useState('')

  const [emptyOrTare, setEmptyOrTare] = useState(0)
  const [materials, setMaterials] = useState([])
  const [indexTonnage, setIndexTonnage] = useState([])

  const [signature, setSignature] = useState()

  const [disableRegisterButton, setDisableRegisterButton] = useState(false)

  const sendSignature = async () => {
    try {
      const pSignature = document
        .querySelector('[contenteditable]')
        .getElementsByTagName('img')[0].src
      setSignature(pSignature)
    } catch (e) {
      console.log('error sending signature')
    }
    setCreateFile(true)
    setTimeout(() => {
      setPrint(true)
    }, 1000)
    setTimeout(() => {
      setCreateFile(false)
      setPrint(false)
    }, 5000)
  }

  // ===== GraphQL =====

  const { data: clients } = useQuery(gqlClients.CLIENTS)
  const { data: getFreightForwarders } = useQuery(gqlClients.FREIGHT_FORWARDERS)
  const { data: materialsList } = useQuery(gqlMaterials.MATERIALS)
  const { data: materialsLilleList } = useQuery(gqlMaterials.SPECIFIC_TO_LILLE)
  const { data: rubbleList } = useQuery(gqlMaterials.RUBBLE)

  const { data: user } = useQuery(gqlUsers.USER, {
    variables: {
      id: getUserFromResponse({ access_token }).id,
    },
  })

  const { data: countTodayDeliveries, refetch } = useQuery(gqlDelivery.COUNT_TODAY_DELIVERY, {
    variables: {
      start: moment().format('yyyy-MM-DD'),
      end: moment().add(1, 'day').format('yyyy-MM-DD'),
    },
  })

  const [fetchSiteCode, { data: siteCode }] = useLazyQuery(gqlUsers.GET_SITE_BY_ID, {
    variables: {
      id: user?.user?.idSite,
    },
  })

  const [truckLeave] = useMutation(gqlTrucks.UPDATE_TRUCK_NO_SITE)

  const [fetchSalesOrder, { data: salesOrder }] = useLazyQuery(gqlDelivery.SALES_ORDER_BY_CODE, {
    fetchPolicy: 'network-only',
    variables: {
      code: saleOrderCode,
    },
    onCompleted: (response) => {
      if (salesOrder.salesOrderByCode) {
        setSaleOrderId(response.salesOrderByCode.id)
        setSaleOrderSucess(true)
        setClient(salesOrder.salesOrderByCode.clientByIdClient.displayName)
        setClientId(salesOrder.salesOrderByCode.clientByIdClient.id)
        setExternRef(response.salesOrderByCode.externalReference)
      } else {
        setSaleOrderId()
        setSaleOrderSucess(false)
        setClient('')
        setClientId()
        setExternRef()
      }
    },
  })

  const [fetchSaleOrderById] = useLazyQuery(gqlDelivery.SALE_ORDER_BY_ID, {
    fetchPolicy: 'network-only',
    onCompleted: (response) => {
      if (response.salesOrder) {
        setSaleOrderCode(response.salesOrder.code)
        setSaleOrderId(response.salesOrder.id)
      } else {
        setSaleOrderCode('')
        setSaleOrderId(null)
      }
    },
  })

  const [fetchConstructionSite, { data: constructionSite }] = useLazyQuery(
    gqlDelivery.CONSTRUCTION_SITE_BY_CODE,
    {
      fetchPolicy: 'network-only',
      variables: {
        code: constructionSiteCode,
      },
      onCompleted: () => {
        if (constructionSite.constructionSiteByCode && constructionSiteCode !== '') {
          setConstructionSiteId(constructionSite.constructionSiteByCode.id)
          setConstructionSiteSuccess(true)
          setConstructionSiteAddress(constructionSite.constructionSiteByCode.address)
          setConstructionSiteCity(constructionSite.constructionSiteByCode.city)
        } else {
          setConstructionSiteId('')
          setConstructionSiteSuccess(false)
          setConstructionSiteAddress('')
          setConstructionSiteCity('')
        }
      },
    }
  )

  const [fetchConstructionSiteById, { data: constructionSiteForDuplicate }] = useLazyQuery(
    gqlDelivery.CONSTRUCTION_SITE_BY_ID,
    {
      fetchPolicy: 'network-only',
      onCompleted: () => {
        if (constructionSiteForDuplicate.constructionSite) {
          setConstructionSiteSuccess(true)
          setConstructionSiteAddress(constructionSiteForDuplicate.constructionSite.address)
          setConstructionSiteCity(constructionSiteForDuplicate.constructionSite.city)
          setConstructionSiteCode(constructionSiteForDuplicate.constructionSite.code)
        } else {
          setConstructionSiteId('')
          setConstructionSiteSuccess(false)
          setConstructionSiteAddress('')
          setConstructionSiteCity('')
          setConstructionSiteCode('')
        }
      },
    }
  )

  const [fetchWeightByIdTruck, { data: weightsTruck }] = useLazyQuery(
    gqlTrucks.WEIGHINGS_NO_APPROVED_BY_ID_TRUCK,
    {
      fetchPolicy: 'network-only',
      variables: {
        idTruck: truckId,
      },
      onCompleted: () => {
        if (weightsTruck.weighings.nodes[0]?.weightRubble) {
          setClearingWeight(
            formatNumberWithThreeDecimals(
              weightsTruck.weighings.nodes[0]?.weightRubble -
              truck?.trucks?.nodes[0]?.unloadedWeight
            )
          )
        } else {
          setClearingWeight(0)
        }

        if (weightsTruck.weighings.nodes[0]?.weightMaterials) {
          setMaterials([{ name: '', id: '', unit: '', division: '', volume: '' }])
        }
      },
    }
  )

  const [fetchIdTruck, { data: truck }] = useLazyQuery(gqlTrucks.TRUCK_BY_LICENSEPLATE, {
    fetchPolicy: 'network-only',
    variables: {
      plicensePlate: licensePlate,
    },
    onCompleted: () => {
      if (truck.trucks.nodes[0]?.id) {
        setTruckId(truck.trucks.nodes[0]?.id)
        setTimeout(() => {
          fetchWeightByIdTruck()
        }, 500)
      } else {
        setTruckId('')
      }
    },
  })

  const [truckApproved] = useMutation(gqlTrucks.UPDATE_APPROVED_WEIGHT, {
    variables: {
      id: weightsTruck?.weighings.nodes[0]?.id,
    },
    onCompleted: () => {
      setMustDisplaySavedOverlay(true)
      setTimeout(() => {
        setMustDisplaySavedOverlay(false)
        navigate('/weighing')
      }, 3000)
    },
    onError: (err) => {
      const request = gqlTrucks.UPDATE_APPROVED_WEIGHT
      const event = {
        request: request?.definitions[0]?.name?.value,
        variables: {
          id: weightsTruck?.weighings.nodes[0]?.id,
        },
      }
      captureMessageWithAttachment(event, err)
    },
  })

  const generateDeliveryCode = () => {
    let codeGenerated = ''
    if (siteCode && countTodayDeliveries) {
      const firstPart = siteCode?.site?.name?.substr(0, 2).toUpperCase()
      const secondPart = moment().format('YYYYMMDDHHmmss')
      const thirdPart = String(
        countTodayDeliveries?.draftOutboundDeliveries?.totalCount + 1
      ).padStart(2, '0')

      codeGenerated = firstPart + secondPart + thirdPart
    } else {
      codeGenerated = String(Date.now())
    }

    return codeGenerated
  }

  useEffect(() => {
    setDeliveryCode(generateDeliveryCode())
  }, [])

  const [createDeliveryItem] = useMutation(gqlDelivery.CREATE_DELIVERY_ITEM, {
    onCompleted: () => {
      if (truckId && weightsTruck && weightsTruck?.weighings.nodes[0]) {
        truckApproved()
      } else {
        setMustDisplaySavedOverlay(true)
        setTimeout(() => {
          setMustDisplaySavedOverlay(false)
          navigate('/weighing')
        }, 3000)
      }
    },
  })

  const [createDelivery] = useMutation(gqlDelivery.CREATE_DELIVERY, {
    onCompleted: (response) => {
      sendSignature(response?.createDraftOutboundDelivery?.draftOutboundDelivery?.id)
      setDeliveryId(response?.createDraftOutboundDelivery?.draftOutboundDelivery?.id)
      truckLeave({
        variables: {
          id: truckId,
        },
      })
      const createMaterialArray = [
        ...materials,
        {
          name: clearingName,
          id: clearingId,
          unit: clearingUnit,
          volume: formatNumberWithThreeDecimals(clearingWeight),
        },
      ].filter((e) => e.name)
      for (let i = 0; i < createMaterialArray.length; i++) {
        createDeliveryItem({
          variables: {
            idDraftOutboundDelivery:
              response?.createDraftOutboundDelivery?.draftOutboundDelivery?.id,
            idMaterial: createMaterialArray[i].id,
            quantity: parseFloat(createMaterialArray[i].volume),
          },
        })
      }
      if (createMaterialArray.length === 0) {
        setMustDisplaySavedOverlay(true)
        setTimeout(() => {
          setMustDisplaySavedOverlay(false)
          navigate('/weighing')
        }, 3000)
      }
      refetch()
    },
    onError: (err) => {
      const request = gqlDelivery.CREATE_DELIVERY
      const event = {
        request: request?.definitions[0]?.name?.value,
        variables: {
          code: deliveryCode,
          value: moment().format(),
          idSaleOrder: saleOrderId,
          idTruck: truckId,
          truckLicencePlate: licensePlate,
          idSite: user && user.user.idSite,
          externalReference: externRef,
          saleOrderClientName: client,
          idClient: clientId,
          constructionSiteAddress,
          constructionSiteCity,
        },
      }
      captureMessageWithAttachment(event, err)
    },
  })

  const [createTruck] = useMutation(gqlTrucks.CREATE_TRUCK, {
    onError: (err) => {
      const request = gqlTrucks.CREATE_TRUCK
      const event = {
        request: request?.definitions[0]?.name?.value,
        variables: {
          id: truckId,
          licencePlate: licensePlate,
        },
      }
      captureMessageWithAttachment(event, err)
    },
  })

  const [createConstructionSite] = useMutation(gqlDelivery.CREATE_CONSTRUCTION_SITE, {
    onCompleted: (response) => {
      const newUUIDDelivery = uuid()
      createDelivery({
        variables: {
          id: newUUIDDelivery,
          code: deliveryCode,
          value: moment().format(),
          idSaleOrder: saleOrderId,
          truckLicencePlate: licensePlate,
          idSite: user.user.idSite,
          idConstructionSite: response?.createConstructionSite?.constructionSite?.id,
          constructionSiteCode,
          constructionSiteAddress,
          constructionSiteCity,
          externalReference: externRef,
          saleOrderClientName: client,
          idClient: clientId,
          forwardingAgent: freightForwarders,
          idFreightForwarder: freightForwardersId,
          destinationSite,
        },
      })
    },
    onError: (err) => {
      const request = gqlDelivery.CREATE_CONSTRUCTION_SITE
      const event = {
        request: request?.definitions[0]?.name?.value,
        variables: {
          id: constructionSiteId,
          code: constructionSiteCode,
          address: constructionSiteAddress,
          city: constructionSiteCity,
        },
      }
      captureMessageWithAttachment(event, err)
    },
  })

  const [updateConstructionSite] = useMutation(gqlDelivery.UPDATE_DELIVERY_CONSTRUCTION_SITE)

  // ===== Functions =====

  const getRubble = () => {
    let rubbleArray = []
    rubbleList?.productCategories?.nodes.forEach((e) => {
      rubbleArray = rubbleArray.concat(e.materialsByIdProductCategory.nodes)
    })
    return { materials: { nodes: rubbleArray } }
  }

  const getMaterials = () => {
    const allMaterials = materialsList?.productCategories?.nodes.concat(
      materialsLilleList?.productCategories?.nodes
    )
    let materialsArray = []
    allMaterials?.forEach((e) => {
      materialsArray = materialsArray.concat(e.materialsByIdProductCategory.nodes)
    })
    return { materials: { nodes: materialsArray } }
  }

  // Function to update materials in Step 2
  const updateItem = (prop, event, index, propSecond, propThird) => {
    // Stuff for autocompletion for the first tonnage
    const isTonnage = event.baseMeasureUnitCode === 'TNE'
    let prefilling = false
    if (isTonnage) {
      if (indexTonnage.length === 0) {
        prefilling = true
      }
      const tempIndexTonnage = indexTonnage
      indexTonnage.push(index)
      setIndexTonnage(tempIndexTonnage)
    }

    // Cloning materials and current line
    const old = materials[index]
    const clone = [...materials]

    // Update the current line
    if (event.id && event.description) {
      const updated = { ...old, [prop]: event.description }
      const updatedSecond = { ...updated, [propSecond]: event.id }
      const updatedThird = {
        ...updatedSecond,
        [propThird]: event.baseMeasureUnitCode,
      }
      let updatedFourth = null
      let updatedFifth = null
      if (prefilling && isTonnage && weightsTruck?.weighings?.nodes[0] && emptyOrTare) {
        updatedFourth = { ...updatedThird, division: '100' }
        updatedFifth = {
          ...updatedFourth,
          volume: (weightsTruck.weighings.nodes[0].weightMaterials - emptyOrTare).toFixed(2),
        }
      }
      clone[index] = updatedFifth || updatedThird
    } else if (
      prop === 'division' &&
      weightsTruck?.weighings?.nodes[0] &&
      indexTonnage.includes(index)
    ) {
      const netWeight = weightsTruck.weighings.nodes[0].weightMaterials - emptyOrTare
      const updated = { ...old, [prop]: event }
      const updatedSecond = {
        ...updated,
        volume: ((event / 100) * netWeight).toFixed(2),
      }
      clone[index] = updatedSecond

      // Autocomplete if 2 tonnage
      if (indexTonnage.length === 2) {
        let indexOtherTonnage = 0
        for (let i = 0; i < indexTonnage.length; i++) {
          if (indexTonnage[i] !== index) {
            indexOtherTonnage = indexTonnage[i]
          }
        }
        const oldOtherTonnage = materials[indexOtherTonnage]
        const updatedDivision = {
          ...oldOtherTonnage,
          division: (100 - event).toFixed(0),
        }
        const updatedVolume = {
          ...updatedDivision,
          volume: (((100 - event) / 100) * netWeight).toFixed(2),
        }
        clone[indexOtherTonnage] = updatedVolume
      }
    } else if (
      prop === 'volume' &&
      weightsTruck?.weighings?.nodes[0] &&
      indexTonnage.includes(index)
    ) {
      const netWeight = weightsTruck.weighings.nodes[0].weightMaterials - emptyOrTare
      const updated = { ...old, [prop]: event }
      const updatedSecond = {
        ...updated,
        division: ((event / netWeight) * 100).toFixed(0),
      }
      clone[index] = updatedSecond

      // Autocomplete if 2 tonnage
      if (indexTonnage.length === 2) {
        let indexOtherTonnage = 0
        for (let i = 0; i < indexTonnage.length; i++) {
          if (indexTonnage[i] !== index) {
            indexOtherTonnage = indexTonnage[i]
          }
        }
        const oldOtherTonnage = materials[indexOtherTonnage]
        const updatedVolume = {
          ...oldOtherTonnage,
          volume: (netWeight - event).toFixed(2),
        }
        const updatedDivision = {
          ...updatedVolume,
          division: (((netWeight - event) / netWeight) * 100).toFixed(0),
        }
        clone[indexOtherTonnage] = updatedDivision
      }
    } else {
      const updated = { ...old, [prop]: event }
      clone[index] = updated
    }

    setMaterials(clone)
  }

  const deleteItem = (index) => {
    const clone = [...materials]
    if (index > -1) {
      clone.splice(index, 1)
    }
    setMaterials(clone)

    // update indexTonnage
    const tempIndexTonnage = []
    for (let i = 0; i < clone.length; i++) {
      if (clone[i].unit === 'TNE') {
        tempIndexTonnage.push(i)
      }
    }
    setIndexTonnage(tempIndexTonnage)
  }

  const checkAllVolumeIsFilled = () => {
    if (materials) {
      let allVolumeFill = false
      for (let i = 0; i < materials.length; i++) {
        if (!materials[i].volume) {
          allVolumeFill = true
        }
      }
      if (clearingName) {
        allVolumeFill = false
      }
      return allVolumeFill
    }
  }

  const updateDataConstructionSite = (dataToUpdate) => {
    updateConstructionSite({
      variables: {
        id: constructionSiteId,
        code: constructionSiteCode,
        ...dataToUpdate,
      },
    })
  }

  const onClickRegister = () => {
    setDisableRegisterButton(true)
    // Creation of UUIDs
    const newUUIDConstructionSite = uuid()
    let newUUIDEvacuation = ''
    let UUIDTruck = ''
    if (evacuation) {
      newUUIDEvacuation = uuid()
    }
    if (!truckId) {
      UUIDTruck = uuid()
      setTruckId(UUIDTruck)
      createTruck({
        variables: {
          id: UUIDTruck,
          licencePlate: licensePlate,
        },
      })
    }

    // Creation of a Delivery
    if (constructionSiteId) {
      createDelivery({
        variables: {
          code: deliveryCode,
          value: moment().format(),
          idSaleOrder: saleOrderId,
          idTruck: truckId || UUIDTruck,
          truckLicencePlate: licensePlate,
          idSite: user.user.idSite,
          idConstructionSite: constructionSiteId,
          constructionSiteCode,
          constructionSiteAddress,
          constructionSiteCity,
          externalReference: externRef,
          forwardingAgent: freightForwarders,
          idFreightForwarder: freightForwardersId,
          destinationSite,
          saleOrderClientName: client,
          idClient: clientId,
          idInternalTransfert: newUUIDEvacuation,
        },
      })
    } else if (!constructionSiteId && constructionSiteCode) {
      setConstructionSiteId(newUUIDConstructionSite)
      createConstructionSite({
        variables: {
          id: newUUIDConstructionSite,
          code: constructionSiteCode,
          address: constructionSiteAddress,
          city: constructionSiteCity,
        },
      })
    } else {
      createDelivery({
        variables: {
          code: deliveryCode,
          value: moment().format(),
          idSaleOrder: saleOrderId,
          idTruck: truckId || UUIDTruck,
          truckLicencePlate: licensePlate,
          idSite: user.user.idSite,
          externalReference: externRef,
          saleOrderClientName: client,
          idClient: clientId,
          forwardingAgent: freightForwarders,
          idFreightForwarder: freightForwardersId,
          destinationSite,
          constructionSiteAddress,
          constructionSiteCity,
          idInternalTransfert: newUUIDEvacuation,
        },
      })
    }
  }

  const materialsForPrint = () => {
    let printMaterials = []
    if (clearingId) {
      printMaterials = [
        ...materials,
        {
          name: clearingName,
          id: clearingId,
          unit: clearingUnit,
          volume: formatNumberWithThreeDecimals(clearingWeight),
        },
      ]
    } else {
      printMaterials = materials
    }
    return printMaterials
  }

  // ===== Use effects =====

  useEffect(() => {
    if (user) {
      fetchSiteCode()
    }
  }, [user, fetchSiteCode])

  useEffect(() => {
    if (licensePlateURI) {
      setLicensePlate(licensePlateURI)
    }
  }, [licensePlateURI])

  useEffect(() => {
    if (saleOrderCode) {
      fetchSalesOrder()
    }
  }, [saleOrderCode, fetchSalesOrder])

  useEffect(() => {
    if (constructionSiteCode) {
      fetchConstructionSite()
    } else {
      setConstructionSiteAddress('')
      setConstructionSiteCity('')
    }
  }, [constructionSiteCode, fetchConstructionSite])

  useEffect(() => {
    if (licensePlate) {
      fetchIdTruck()
    }
  }, [licensePlate, fetchIdTruck])

  useEffect(() => {
    if (weightsTruck && truck) {
      if (weightsTruck.weighings.nodes[0]?.weightTare !== 0) {
        setEmptyOrTare(weightsTruck.weighings.nodes[0]?.weightTare)
      } else {
        setEmptyOrTare(truck.trucks.nodes[0]?.unloadedWeight)
      }
    }
  }, [weightsTruck, truck])

  useEffect(() => {
    if (duplicate) {
      const duplicatedItem = JSON.parse(localStorage.getItem('delivery_duplicate'))
      setSaleOrderSucess(true)
      setClient(duplicatedItem.saleOrderClientName)
      setClientId(duplicatedItem.idClient)
      setFreightForwardersId(duplicatedItem.idFreightForwarder)
      setFreightForwarders(duplicatedItem.forwardingAgent)
      setDestinationSite(duplicatedItem.destinationSite)

      if (duplicatedItem.idSaleOrder) {
        fetchSaleOrderById({
          variables: {
            id: duplicatedItem.idSaleOrder,
          },
        })
      }

      if (duplicatedItem.idInternalTransfert) {
        setEvacuation(true)
      }

      if (duplicatedItem?.idConstructionSite) {
        setConstructionSiteId(duplicatedItem.idConstructionSite)
        fetchConstructionSiteById({
          variables: {
            id: duplicatedItem.idConstructionSite,
          },
        })
      }

      setConstructionSiteAddress(duplicatedItem.constructionSiteAddress)
      setConstructionSiteCity(duplicatedItem.constructionSiteCity)

      setLicensePlate(duplicatedItem.truckLicencePlate)
      setTruckId(duplicatedItem.idTruck)
      setExternRef(duplicatedItem.externalReference)
      const tempMaterialsDuplicate = []
      const prefixItems = duplicatedItem.draftOutboundDeliveryItemsByIdDraftOutboundDelivery?.nodes
      for (let i = 0; i < prefixItems.length; i++) {
        tempMaterialsDuplicate.push({
          name: prefixItems[i]?.materialByIdMaterial?.description,
          id: prefixItems[i]?.idMaterial,
          unit: prefixItems[i]?.materialByIdMaterial?.baseMeasureUnitCode,
          division: '',
          volume: prefixItems[i]?.quantity,
        })
      }
      setMaterials(tempMaterialsDuplicate)
      setStep('resumeStep')

    }
  }, [duplicate, fetchConstructionSiteById, fetchSaleOrderById])

  useEffect(() => {
    if (print) {
      const css = '@page { size: portrait; -webkit-print-color-adjust: exact; }'
      const head = document.head || document.getElementsByTagName('head')[0]
      const style = document.createElement('style')

      style.type = 'text/css'
      style.media = 'print'

      if (style.styleSheet) {
        style.styleSheet.cssText = css
      } else {
        style.appendChild(document.createTextNode(css))
      }

      head.appendChild(style)
      window.print()
    }
  }, [print])

  return (
    <div className="delivery">
      {(print || createFile) && deliveryId ? (
        <PrintDelivery
          createFile={createFile}
          type="new-delivery"
          id={deliveryId}
          saleOrderCode={deliveryCode}
          date={moment().format()}
          client={client}
          site={siteCode?.site?.name}
          firstname={user?.user?.firstname}
          lastname={user?.user?.lastname}
          externRef={externRef}
          constructionSiteCode={constructionSiteCode}
          constructionSiteAddress={constructionSiteAddress}
          constructionSiteCity={constructionSiteCity}
          licensePlate={licensePlate}
          refExt={externRef}
          materials={materialsForPrint()}
          signature={signature}
          closeDoc={() => setPrint(false)}
        />
      ) : (
        <>
          <Header title={step === 'resumeStep' ? t('summaryTitle') : t('title')} />
          <NavBar active="delivery" />
          <LayoutContainer>
            {mustDisplaySavedOverlay && (
              <div className="delivery__overlay">
                <WidgetOverlayValidate />
              </div>
            )}
            <div
              className={
                step === 'resumeStep'
                  ? 'delivery__container delivery__container-resume'
                  : 'delivery__container'
              }
            >
              {step === 'firstStep' && (
                <NewDeliveryStep1
                  setStep={() => setStep('secondStep')}
                  saleOrderCode={saleOrderCode}
                  saleOrderSucess={saleOrderSucess}
                  saleOrderOnChange={(e) => setSaleOrderCode(e)}
                  client={client}
                  clientId={setClientId}
                  clientOnChange={(e) => setClient(e)}
                  clientAutoCompletionList={clients && clients}
                  clientSuccessAutoComplete={clientId}
                  freightForwarders={freightForwarders}
                  freightForwardersId={setFreightForwardersId}
                  freightForwardersOnChange={(e) => setFreightForwarders(e)}
                  freightForwardersAutoCompletionList={getFreightForwarders && getFreightForwarders}
                  freightForwardersSuccessAutoComplete={freightForwardersId}
                  externRef={externRef}
                  externRefOnChange={(e) => setExternRef(e)}
                  destinationSite={destinationSite}
                  destinationSiteOnChange={(e) => setDestinationSite(e)}
                  constructionSiteCode={constructionSiteCode}
                  constructionSiteCodeOnChange={(e) => setConstructionSiteCode(e)}
                  constructionSiteSuccess={constructionSiteSuccess}
                  constructionSiteAddress={constructionSiteAddress}
                  constructionSiteAddressOnChange={(e) => {
                    if (constructionSiteId) {
                      updateDataConstructionSite({ address: e })
                    }
                    setConstructionSiteAddress(e)
                  }}
                  constructionSiteCity={constructionSiteCity}
                  constructionSiteCityOnChange={(e) => {
                    if (constructionSiteId) {
                      updateDataConstructionSite({ city: e })
                    }
                    setConstructionSiteCity(e)
                  }}
                  licensePlate={licensePlate}
                  licensePlateSuccess={truckId}
                  licensePlateOnChange={(e) => {
                    setLicensePlate(e.toUpperCase())
                  }}
                  evacuation={evacuation}
                  evacuationOnChange={(e) => setEvacuation(e)}
                  bdcFile={bdcFile}
                  bdcFileOnChange={(e) => setBdcFile(e.target.files[0])}
                />
              )}

              {step === 'secondStep' && (
                <div className="delivery__container__second-step">
                  <NewDeliveryStep2
                    clearingName={clearingName}
                    clearingNameOnChange={(e) => setClearingName(e)}
                    clearingIdOnCange={(e) => {
                      setClearingId(e)
                    }}
                    clearingWeight={clearingWeight}
                    clearingWeightOnChange={(e) => {
                      setClearingWeight(e)
                    }}
                    unit={clearingUnit}
                    setUnit={(e) => setClearingUnit(e)}
                    rubbleAutoCompletion={getRubble()}
                  />

                  <div className="new-delivery-second__separator" />

                  <div className="delivery__container__second-step__materials">
                    {materials &&
                      materials.map((material, index) => (
                        <MaterialsDelivery
                          key={index}
                          product={material.name}
                          productOnChange={(e) => updateItem('name', e, index, 'id', 'unit')}
                          unit={material.unit}
                          division={material.division}
                          divisionOnChange={(e) => updateItem('division', e, index)}
                          volume={material.volume}
                          volumeOnChange={(e) => updateItem('volume', e, index)}
                          deleteOnClick={() => deleteItem(index)}
                          materialsAutoCompletion={getMaterials()}
                        />
                      ))}
                  </div>

                  <div className="delivery__container__second-step__adding">
                    <button
                      type="button"
                      className="delivery__container__second-step__adding__icon"
                      onClick={() => {
                        if (materials) {
                          setMaterials([
                            ...materials,
                            {
                              name: '',
                              id: '',
                              unit: '',
                              division: '',
                              volume: '',
                            },
                          ])
                        } else {
                          setMaterials([
                            {
                              name: '',
                              id: '',
                              unit: '',
                              division: '',
                              volume: '',
                            },
                          ])
                        }
                      }}
                    >
                      <img
                        className="delivery__container__second-step__adding__icon__img"
                        src="icons/blue-plus-circle.png"
                        alt="add line"
                      />
                    </button>
                  </div>

                  <div className="delivery__container__second-step__buttons">
                    <div className="delivery__container__second-step__buttons__back">
                      <UIButton
                        type="secondary"
                        text={t('back')}
                        onClick={() => setStep('firstStep')}
                        alt={t('back')}
                        style={{ width: '240px', height: '50px' }}
                      />
                    </div>
                    <div className="delivery__container__second-step__buttons__register">
                      <UIButton
                        type="primary"
                        text={t('next')}
                        onClick={() => setStep('resumeStep')}
                        alt={t('next')}
                        disabled={checkAllVolumeIsFilled()}
                        style={{ width: '240px', height: '50px' }}
                      />
                    </div>
                  </div>
                </div>
              )}

              {step === 'resumeStep' && (
                <NewDeliveryStep3
                  saleOrderCode={saleOrderCode}
                  saleOrderOnChange={(e) => setSaleOrderCode(e)}
                  client={client}
                  clientOnChange={(e) => setClient(e)}
                  freightForwarders={freightForwarders}
                  freightForwardersOnChange={(e) => setFreightForwarders(e)}
                  externRef={externRef}
                  externRefOnChange={(e) => setExternRef(e)}
                  destinationSite={destinationSite}
                  destinationSiteOnChange={(e) => setDestinationSite(e)}
                  constructionSiteCode={constructionSiteCode}
                  constructionSiteCodeOnChange={(e) => setConstructionSiteCode(e)}
                  constructionSiteAddress={constructionSiteAddress}
                  constructionSiteAddressOnChange={(e) => setConstructionSiteAddress(e)}
                  constructionSiteCity={constructionSiteCity}
                  constructionSiteCityOnChange={(e) => setConstructionSiteCity(e)}
                  licensePlate={licensePlate}
                  licensePlateOnChange={(e) => setLicensePlate(e)}
                  materials={[
                    ...materials,
                    {
                      name: clearingName,
                      id: clearingId,
                      unit: clearingUnit,
                      volume: formatNumberWithThreeDecimals(clearingWeight),
                    },
                  ].filter((e) => e.name)}
                  signature={signature}
                  bdcFile={bdcFile}
                  setStepMaterial={() => setStep('secondStep')}
                  setStepPrevious={() => setStep('firstStep')}
                  register={() => onClickRegister()}
                  disableRegister={disableRegisterButton}
                />
              )}
            </div>
          </LayoutContainer>
        </>
      )}
    </div>
  )
}

export default NewDelivery
