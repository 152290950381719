import { ApolloClient, ApolloProvider, createHttpLink, InMemoryCache } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import i18n from 'i18next'
import React from 'react'
import * as ReactDOM from 'react-dom/client'
import { initReactI18next } from 'react-i18next'
import { BrowserRouter, Navigate } from 'react-router-dom'
import 'semantic-ui-css/semantic.min.css'
import api from './api'
import App from './App'
import config from './config'
import resources from './i18n'
import { isUserTokenExpired } from './lib/helpers'
import * as serviceWorker from './serviceWorker'

const httpLink = createHttpLink({
  uri: config.GRAPHQL_URL,
})

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const accessToken = localStorage.getItem('access_token')
  const refreshToken = localStorage.getItem('refresh_token')
  if (!accessToken) {
    return <Navigate to="/" />
  }
  if (accessToken && isUserTokenExpired(accessToken)) {
    return api
      .refreshAccessToken(refreshToken)
      .then((response) => {
        localStorage.setItem('access_token', response.access_token)
        localStorage.setItem('refresh_token', response.refresh_token)
        return {
          headers: {
            ...headers,
            authorization: `Bearer ${response.access_token}`,
          },
        }
      })
      .catch(() => {
        localStorage.removeItem('access_token')
        localStorage.removeItem('refresh_token')
        return <Navigate to="/" />
      })
  }
  return {
    headers: {
      ...headers,
      authorization: `Bearer ${accessToken}`,
    },
  }
})

const client = new ApolloClient({
  connectToDevTools: true, // ternaire pour dev mettre true sinon false
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
})

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: localStorage.getItem('key') || 'fr',
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
  })

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <BrowserRouter>
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>
  </BrowserRouter>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register()
