import React from 'react'
import { PropTypes } from 'prop-types'
import { useTranslation } from 'react-i18next'
import './UIRadioButton.scss'

const UIRadioButton = (props) => {
  const { radioName, options, checked, onChange, prefix } = props

  const { t } = useTranslation('weighing')

  return (
    <div className="radio-button">
      <h2 className="radio-button__prefixed">{`${prefix} :`}</h2>
      <div className="radio-button__choices">
        {options &&
          options.map((option) => (
            <div className="radio-button__choices__item" key={option.name}>
              <input
                className="radio-button__choices__item__input"
                key={`${radioName}-${option.name}`}
                id={`${radioName}-${option.name}`}
                type="radio"
                name={radioName}
                value={option.name}
                checked={checked === option.name}
                onChange={onChange}
              />
              <div
                className={checked === option.name ? 'radio-button__choices__item__checked' : ''}
              />
              <label
                className={
                  checked === option.name
                    ? 'radio-button__choices__item__text__checked'
                    : 'radio-button__choices__item__text'
                }
                htmlFor={`${radioName}-${option.name}`}
              >
                {t(option.name)}
              </label>
            </div>
          ))}
      </div>
    </div>
  )
}

UIRadioButton.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  radioName: PropTypes.string.isRequired,
  checked: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  prefix: PropTypes.string.isRequired,
}

export default UIRadioButton
