import React from 'react'
import { useTranslation } from 'react-i18next'
import './WidgetOverlaySaved.scss'

const WidgetOverlaySaved = () => {
  const { t } = useTranslation('overlay')

  return (
    <div className="overlaySaved">
      <div className="overlaySaved__background" />
      <div className="overlaySaved__layout">
        <div className="overlaySaved__layout__container">
          <p className="overlaySaved__layout__container__text">{t('saved')}</p>
        </div>
      </div>
    </div>
  )
}

export default WidgetOverlaySaved
