import React from 'react'
import { useTranslation } from 'react-i18next'
import './WidgetComeBack.scss'

const WidgetComeBack = () => {
  const { t } = useTranslation('backButton')

  return (
    <button
      type="button"
      className="comeBackButton"
      onClick={() => window.history.back()}
      alt={t('alttext')}
    >
      <img className="comeBackButton__arrow" src="icons/back-arrow.png" alt="logoBDN" />
      <p className="comeBackButton__text">{t('back')}</p>
    </button>
  )
}

export default WidgetComeBack
