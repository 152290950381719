import { gql } from '@apollo/client'

const MATERIALS = gql`
  query materials {
    productCategories(condition: { family: MATERIAL }) {
      nodes {
        materialsByIdProductCategory {
          nodes {
            description
            detail
            id
            baseMeasureUnitCode
            internalId
          }
          totalCount
        }
      }
    }
  }
`

const SPECIFIC_TO_LILLE = gql`
  query specificToLille {
    productCategories(condition: { family: SPECIFIC_TO_LILLE }) {
      nodes {
        materialsByIdProductCategory {
          nodes {
            description
            detail
            id
            baseMeasureUnitCode
            internalId
          }
          totalCount
        }
      }
    }
  }
`

const RUBBLE = gql`
  query rubble {
    productCategories(condition: { family: RUBBLE }) {
      nodes {
        materialsByIdProductCategory {
          nodes {
            description
            detail
            id
            baseMeasureUnitCode
            internalId
          }
          totalCount
        }
      }
    }
  }
`

const MATERIAL_BY_ID = gql`
  query material($id: UUID = "") {
    material(id: $id) {
      description
      detail
      baseMeasureUnitCode
      idProductCategory
    }
  }
`

export default {
  MATERIALS,
  SPECIFIC_TO_LILLE,
  MATERIAL_BY_ID,
  RUBBLE,
}
