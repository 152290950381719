/* eslint-disable no-bitwise */
/* eslint-disable no-mixed-operators */
import { jwtDecode } from 'jwt-decode'

export const getUserFromResponse = ({ access_token: accessToken }) => {
  const { id, role } = jwtDecode(accessToken)
  return {
    id,
    role,
    accessToken,
  }
}

export const isAuthorized = (role, requiredRole) => {
  if (requiredRole.indexOf(role) > -1) {
    return true
  }
  return false
}

export const validateEmail = (email) => {
  const pattern = new RegExp(
    /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])/
  )
  return pattern.test(email)
}

export const isUserTokenExpired = (token) => {
  if (token) {
    const { exp } = jwtDecode(token)
    return !exp || Math.floor(Date.now() / 1000 + 7200) > exp
  }
  return true
}

export const arrayBufferToDataUri = (arrayBuffer) => {
  let base64 = ''
  const encodings = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/'
  const bytes = new Uint8Array(arrayBuffer)
  const { byteLength } = bytes
  const byteRemainder = byteLength % 3
  const mainLength = byteLength - byteRemainder
  let a
  let b
  let c
  let d
  let chunk
  for (let i = 0; i < mainLength; i += 3) {
    chunk = (bytes[i] << 16) | (bytes[i + 1] << 8) | bytes[i + 2]
    a = (chunk & 16515072) >> 18
    b = (chunk & 258048) >> 12
    c = (chunk & 4032) >> 6
    d = chunk & 63
    base64 += encodings[a] + encodings[b] + encodings[c] + encodings[d]
  }
  if (byteRemainder === 1) {
    chunk = bytes[mainLength]
    a = (chunk & 252) >> 2
    b = (chunk & 3) << 4
    base64 += `${encodings[a] + encodings[b]}==`
  } else if (byteRemainder === 2) {
    chunk = (bytes[mainLength] << 8) | bytes[mainLength + 1]
    a = (chunk & 16128) >> 8
    b = (chunk & 1008) >> 4
    c = (chunk & 15) << 2
    base64 += `${encodings[a] + encodings[b] + encodings[c]}=`
  }
  return `data:image/jpeg;base64,${base64}`
}

const BASE64_MARKER = ';base64,'
export const convertDataURIToBinary = (dataURI) => {
  const base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length
  const base64 = dataURI.substring(base64Index)
  const raw = window.atob(base64)
  const rawLength = raw.length
  const array = new Uint8Array(new ArrayBuffer(rawLength))

  for (let i = 0; i < rawLength; i++) {
    array[i] = raw.charCodeAt(i)
  }
  return array
}

export const formatNumberWithThreeDecimals = (number) => {
  let formattedNumber = Number(number).toFixed(3)
  return parseFloat(formattedNumber)
}
