/* eslint-disable no-nested-ternary */
import { useQuery } from '@apollo/client'
import { jwtDecode } from 'jwt-decode'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
import gqlDelivery from '../../gql/delivery'
import gqlUsers from '../../gql/users'
import { getUserFromResponse } from '../../lib/helpers'
import version from '../../version.json'
import './NavBar.scss'

const NavBar = (props) => {
  const { active, minimize } = props
  const { t } = useTranslation('navbar')

  const role = jwtDecode(localStorage.getItem('access_token'))?.role
  const navigate = useNavigate()

  // Access by the page
  const accessWeighing = ['bdn_admin', 'bdn_headquarter_assistant']
  const accessFollowing = ['bdn_admin', 'bdn_headquarter_assistant', 'bdn_on_site_assistant']
  const accessTrucks = ['bdn_admin', 'bdn_headquarter_assistant']
  const accessDelivery = ['bdn_admin', 'bdn_headquarter_assistant']
  const accessTablets = ['bdn_admin']
  const accessUsers = ['bdn_admin']
  const accessSettings = ['bdn_admin']

  // eslint-disable-next-line camelcase
  const access_token = localStorage.getItem('access_token')

  const { data: user } = useQuery(gqlUsers.USER, {
    variables: {
      id: getUserFromResponse({ access_token }).id,
    },
  })

  const { data: countToValidateDelivery } = useQuery(gqlDelivery.COUNT_TO_VALIDATE)

  return (
    <div className={minimize ? 'nav-bar nav-bar-mini' : 'nav-bar'}>
      <div className="nav-bar__user-name">
        <h2 className="nav-bar__user-name__text">
          {minimize ? t('you') : user ? user.user.firstname : t('you')}
        </h2>
      </div>

      <div className="nav-bar__container">
        <div className="nav-bar__container__first">
          {accessWeighing.indexOf(role) > -1 && (
            <Link to="/weighing">
              <div
                className={
                  active === 'weighing'
                    ? 'nav-bar__container__item-active'
                    : 'nav-bar__container__item'
                }
              >
                <div
                  className={
                    minimize ? 'nav-bar__container__item__mini' : 'nav-bar__container__item'
                  }
                >
                  <div className="nav-bar__container__item__icon">
                    <img
                      className="nav-bar__container__item__icon__img"
                      src="icons/truck.png"
                      alt="weighing"
                    />
                  </div>
                  {!minimize && (
                    <p
                      className={
                        active === 'weighing'
                          ? 'nav-bar__container__item-active__text'
                          : 'nav-bar__container__item__text'
                      }
                    >
                      {t('weighing')}
                    </p>
                  )}
                </div>
              </div>
            </Link>
          )}

          {accessFollowing.indexOf(role) > -1 && (
            <Link to="/following-delivery">
              <div
                className={
                  active === 'following'
                    ? 'nav-bar__container__item nav-bar__container__item-active'
                    : 'nav-bar__container__item'
                }
              >
                <div
                  className={
                    minimize ? 'nav-bar__container__item__mini' : 'nav-bar__container__item'
                  }
                >
                  <div className="nav-bar__container__item__icon">
                    <img
                      className="nav-bar__container__item__icon__img"
                      src="icons/following.png"
                      alt="following delivery"
                    />
                  </div>
                  {!minimize && (
                    <p
                      className={
                        active === 'following'
                          ? 'nav-bar__container__item-active__text'
                          : 'nav-bar__container__item__text'
                      }
                    >
                      {t('following')}
                    </p>
                  )}
                  <div
                    className={
                      active === 'following'
                        ? 'nav-bar__container__item__notification nav-bar__container__item__notification__active'
                        : 'nav-bar__container__item__notification'
                    }
                  >
                    <p className="nav-bar__container__item__notification__number">
                      {countToValidateDelivery?.draftOutboundDeliveries?.totalCount}
                    </p>
                  </div>
                </div>
              </div>
            </Link>
          )}

          {accessTrucks.indexOf(role) > -1 && (
            <Link to="/trucks">
              <div
                className={
                  active === 'trucks'
                    ? 'nav-bar__container__item nav-bar__container__item-active'
                    : 'nav-bar__container__item'
                }
              >
                <div
                  className={
                    minimize ? 'nav-bar__container__item__mini' : 'nav-bar__container__item'
                  }
                >
                  <div className="nav-bar__container__item__icon">
                    <img
                      className="nav-bar__container__item__icon__img"
                      src="icons/time.png"
                      alt="truck on site"
                    />
                  </div>
                  {!minimize && (
                    <p
                      className={
                        active === 'trucks'
                          ? 'nav-bar__container__item-active__text'
                          : 'nav-bar__container__item__text'
                      }
                    >
                      {t('trucks')}
                    </p>
                  )}
                </div>
              </div>
            </Link>
          )}
        </div>

        <div className="nav-bar__container__second">
          {accessDelivery.indexOf(role) > -1 && (
            <Link to="/new-delivery">
              <div
                className={
                  active === 'delivery'
                    ? 'nav-bar__container__item nav-bar__container__item-active'
                    : 'nav-bar__container__item'
                }
              >
                <div
                  className={
                    minimize ? 'nav-bar__container__item__mini' : 'nav-bar__container__item'
                  }
                >
                  <div className="nav-bar__container__item__icon nav-bar__container__item__icon__delivery">
                    <img
                      className="nav-bar__container__item__icon__img"
                      src="icons/delivery-note.png"
                      alt="truck on site"
                    />
                  </div>
                  {!minimize && (
                    <p
                      className={
                        active === 'delivery'
                          ? 'nav-bar__container__item-active__text'
                          : 'nav-bar__container__item__text'
                      }
                    >
                      {t('delivery')}
                    </p>
                  )}
                </div>
              </div>
            </Link>
          )}
        </div>

        <div className="nav-bar__container__third">
          {accessTablets.indexOf(role) > -1 && (
            <Link to="/tablets">
              <div
                className={
                  active === 'tablets'
                    ? 'nav-bar__container__item nav-bar__container__item-active nav-bar__container__item-active__third'
                    : 'nav-bar__container__item'
                }
              >
                <div
                  className={
                    minimize ? 'nav-bar__container__item__mini' : 'nav-bar__container__item'
                  }
                >
                  <div className="nav-bar__container__item__icon">
                    <img
                      className="nav-bar__container__item__icon__img"
                      src="icons/tablet.png"
                      alt="tablet"
                    />
                  </div>
                  {!minimize && (
                    <p
                      className={
                        active === 'tablets'
                          ? 'nav-bar__container__item-active__text'
                          : 'nav-bar__container__item__text'
                      }
                    >
                      {t('tablet')}
                    </p>
                  )}
                </div>
              </div>
            </Link>
          )}

          {accessUsers.indexOf(role) > -1 && (
            <Link to="/users">
              <div
                className={
                  active === 'users'
                    ? 'nav-bar__container__item nav-bar__container__item-active nav-bar__container__item-active__third'
                    : 'nav-bar__container__item'
                }
              >
                <div
                  className={
                    minimize ? 'nav-bar__container__item__mini' : 'nav-bar__container__item'
                  }
                >
                  <div className="nav-bar__container__item__icon">
                    <img
                      className="nav-bar__container__item__icon__img"
                      src="icons/users.png"
                      alt="users"
                    />
                  </div>
                  {!minimize && (
                    <p
                      className={
                        active === 'users'
                          ? 'nav-bar__container__item-active__text'
                          : 'nav-bar__container__item__text'
                      }
                    >
                      {t('users')}
                    </p>
                  )}
                </div>
              </div>
            </Link>
          )}

          {accessSettings.indexOf(role) > -1 && (
            <Link to="/settings">
              <div
                className={
                  active === 'settings'
                    ? 'nav-bar__container__item nav-bar__container__item-active nav-bar__container__item-active__third'
                    : 'nav-bar__container__item'
                }
              >
                <div
                  className={
                    minimize ? 'nav-bar__container__item__mini' : 'nav-bar__container__item'
                  }
                >
                  <div className="nav-bar__container__item__icon">
                    <img
                      className="nav-bar__container__item__icon__img"
                      src="icons/settings.png"
                      alt="users"
                    />
                  </div>
                  {!minimize && (
                    <p
                      className={
                        active === 'settings'
                          ? 'nav-bar__container__item-active__text'
                          : 'nav-bar__container__item__text'
                      }
                    >
                      {t('settings')}
                    </p>
                  )}
                </div>
              </div>
            </Link>
          )}
        </div>
        <div className="nav-bar__container__second">
          {/* <Link to="/" style={{ display: 'flex' }}> */}
          <button
            type="button"
            className="nav-bar__logout"
            onClick={() => {
              localStorage.removeItem('access_token')
              localStorage.removeItem('refresh_token')
              navigate('/')
            }}
          >
            <img className="nav-bar__logout__img" src="icons/log-out.png" alt="weighing" />
            {!minimize && <p className="nav-bar__logout__text">{t('logout')}</p>}
          </button>
          {!minimize && (
            <div className="nav-bar__deconection__version">
              <p className="nav-bar__deconection__version__text">{`${t('version')} ${
                version.buildMajor
              }.${version.buildMinor}.${version.buildRevision}`}</p>
            </div>
          )}
          {/* </Link> */}
        </div>
      </div>
    </div>
  )
}

NavBar.propTypes = {
  active: PropTypes.string.isRequired,
  minimize: PropTypes.bool,
}

NavBar.defaultProps = {
  minimize: false,
}

export default NavBar
