import { gql } from '@apollo/client'

const TRUCKS = gql`
  query trucks {
    trucks {
      nodes {
        licencePlate
        unloadedWeight
        clientName
        id
        createdAt
        idClient
        updatedAt
      }
      totalCount
    }
  }
`

const WEIGHINGS_NO_APPROVED = gql`
  query weighings {
    weighings(condition: { approved: false }) {
      nodes {
        weightMaterials
        weightRubble
        weightTare
        idTruck
        createdAt
        id
      }
    }
  }
`

const WEIGHING = gql`
  query weighing($id: UUID!) {
    weighing(id: $id) {
      weight
      idTruck
      updatedAt
      id
    }
  }
`

const WEIGHING_BY_ID_TRUCK = gql`
  query weighings($id: UUID!) {
    weighings(condition: { idTruck: $id }) {
      nodes {
        weightMaterials
        weightRubble
        weightTare
        id
        createdAt
        approved
      }
    }
  }
`
const WEIGHING_BY_ID_TRUCK_AND_FALSE = gql`
  query weighings($id: UUID!) {
    weighings(condition: { idTruck: $id, approved: false }) {
      nodes {
        weightMaterials
        weightRubble
        weightTare
        id
        createdAt
        approved
      }
    }
  }
`

const WEIGHINGS_NO_APPROVED_BY_ID_TRUCK = gql`
  query weighings($idTruck: UUID!) {
    weighings(condition: { approved: false, idTruck: $idTruck }) {
      nodes {
        approved
        idTruck
        weightMaterials
        weightRubble
        weightTare
        id
      }
    }
  }
`

const TRUCK_BY_LICENSEPLATE = gql`
  query trucks($plicensePlate: String!) {
    trucks(filter: { licencePlate: { like: $plicensePlate } }) {
      nodes {
        id
        licencePlate
        unloadedWeight
      }
    }
  }
`

const TRUCK_BY_ID = gql`
  query truck($id: UUID!) {
    truck(id: $id) {
      licencePlate
      unloadedWeight
      id
    }
  }
`

const CREATE_TRUCK = gql`
  mutation createTruck($licencePlate: String!, $idSite: UUID, $id: UUID!) {
    createTruck(input: { truck: { licencePlate: $licencePlate, idSite: $idSite, id: $id } }) {
      clientMutationId
      truck {
        id
      }
    }
  }
`

const CREATE_WEIGHT = gql`
  mutation createWeighing($idTruck: UUID!) {
    createWeighing(input: { weighing: { idTruck: $idTruck } }) {
      clientMutationId
      weighing {
        id
      }
    }
  }
`

const UPDATE_WEIGHT_TRUCK = gql`
  mutation updateWeighing(
    $id: UUID!
    $weightMaterials: Float
    $weightRubble: Float
    $weightTare: Float
  ) {
    updateWeighing(
      input: {
        patch: {
          weightMaterials: $weightMaterials
          weightRubble: $weightRubble
          weightTare: $weightTare
        }
        id: $id
      }
    ) {
      clientMutationId
    }
  }
`

const UPDATE_APPROVED_WEIGHT = gql`
  mutation approveTruck($id: UUID!, $approved: Boolean = true) {
    updateWeighing(input: { patch: { approved: $approved }, id: $id }) {
      clientMutationId
    }
  }
`

const UPDATE_TRUCK_NO_SITE = gql`
  mutation updateTruck($id: UUID!, $idSite: UUID = null) {
    updateTruck(input: { patch: { idSite: $idSite }, id: $id }) {
      clientMutationId
    }
  }
`

const UPDATE_TRUCK_SITE = gql`
  mutation updateTruckOnSite($id: UUID!, $idSite: UUID!) {
    updateTruck(input: { patch: { idSite: $idSite }, id: $id }) {
      clientMutationId
    }
  }
`

const UPDATE_UNLOADED_WEIGHT = gql`
  mutation updateTruck($id: UUID!, $unloadedWeight: Float!) {
    updateTruck(input: { patch: { unloadedWeight: $unloadedWeight }, id: $id }) {
      clientMutationId
    }
  }
`

const TRUCKS_ON_SITE = gql`
  query trucks($idSite: UUID) {
    trucks(condition: { idSite: $idSite }) {
      nodes {
        licencePlate
        unloadedWeight
        clientName
        id
        createdAt
        idClient
        updatedAt
      }
      totalCount
    }
  }
`

export default {
  TRUCKS,
  TRUCK_BY_LICENSEPLATE,
  TRUCK_BY_ID,
  WEIGHINGS_NO_APPROVED,
  WEIGHING,
  CREATE_TRUCK,
  CREATE_WEIGHT,
  UPDATE_WEIGHT_TRUCK,
  WEIGHING_BY_ID_TRUCK,
  WEIGHINGS_NO_APPROVED_BY_ID_TRUCK,
  UPDATE_APPROVED_WEIGHT,
  UPDATE_UNLOADED_WEIGHT,
  TRUCKS_ON_SITE,
  UPDATE_TRUCK_NO_SITE,
  WEIGHING_BY_ID_TRUCK_AND_FALSE,
  UPDATE_TRUCK_SITE,
}
