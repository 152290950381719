import { gql } from '@apollo/client'

const DEVICES = gql`
  query devices($offset: Int = 0, $first: Int = 20) {
    devices(offset: $offset, first: $first) {
      nodes {
        id
        idDeviceSeed
        isValid
        name
        userByIdUser {
          lastname
          firstname
          siteByIdSite {
            id
            name
          }
        }
        idUser
      }
      totalCount
      pageInfo {
        hasNextPage
      }
    }
  }
`

const DEVICES_NO_VALIDATE = gql`
  query device_waiting_for_approval($offset: Int = 0, $first: Int = 20) {
    deviceSeeds(
      offset: $offset
      first: $first
      filter: { alreadyAssociated: { equalTo: false }, isUsed: { equalTo: false } }
    ) {
      nodes {
        id
        seed
        isUsed
      }
      totalCount
      pageInfo {
        hasNextPage
      }
    }
  }
`

const DEVICE_VALIDATION = gql`
  mutation accept_device($idDeviceSeed: UUID!, $idUser: UUID!) {
    createDevice(
      input: { device: { idDeviceSeed: $idDeviceSeed, idUser: $idUser, isValid: true } }
    ) {
      clientMutationId
    }
  }
`

const DEVICE_DELETE = gql`
  mutation deleteDevice($id: UUID!) {
    deleteDevice(input: { id: $id }) {
      clientMutationId
      device {
        id
      }
    }
  }
`

const SEED_DELETE = gql`
  mutation deleteDeviceSeed($id: UUID!) {
    deleteDeviceSeed(input: { id: $id }) {
      clientMutationId
      deviceSeed {
        id
      }
    }
  }
`

const UPDATE_TABLET_NAME = gql`
  mutation updateDevice($id: UUID!, $name: String!) {
    updateDevice(input: { patch: { name: $name }, id: $id }) {
      clientMutationId
      device {
        id
        name
      }
    }
  }
`

export default {
  DEVICES,
  DEVICES_NO_VALIDATE,
  DEVICE_VALIDATION,
  DEVICE_DELETE,
  SEED_DELETE,
  UPDATE_TABLET_NAME,
}
