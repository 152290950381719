import { gql } from '@apollo/client'

const USERS = gql`
  query users($companyRole: String, $idSite: UUID, $user: String) {
    users(
      filter: {
        companyRole: { includes: $companyRole }
        and: { idSite: { equalTo: $idSite }, and: { email: { includes: $user } } }
      }
    ) {
      nodes {
        id
        idRole
        firstname
        lastname
        email
        idSite
        companyRole
        siteByIdSite {
          name
        }
        devicesByIdUser {
          nodes {
            id
            idDeviceSeed
            name
          }
        }
      }
      totalCount
      pageInfo {
        hasNextPage
      }
    }
  }
`

const USER = gql`
  query user($id: UUID!) {
    user(id: $id) {
      companyRole
      email
      firstname
      id
      idSite
      lastname
      idRole
    }
  }
`

const SITES = gql`
  query sites {
    sites {
      nodes {
        code
        name
        id
      }
      totalCount
    }
  }
`

const GET_SITE_BY_ID = gql`
  query getSiteById($id: UUID!) {
    site(id: $id) {
      code
      name
    }
  }
`

const ROLES = gql`
  query getRoles {
    roles {
      nodes {
        id
        displayName
        name
      }
      totalCount
    }
  }
`

const CREATE_USER = gql`
  mutation createUser(
    $email: String!
    $firstname: String!
    $idRole: BigFloat!
    $idSite: UUID!
    $lastname: String!
    $password: String = ""
  ) {
    createUser(
      input: {
        user: {
          email: $email
          firstname: $firstname
          lastname: $lastname
          idSite: $idSite
          idRole: $idRole
          password: $password
        }
      }
    ) {
      clientMutationId
    }
  }
`

const UPDATE_USER = gql`
  mutation updateUser(
    $id: UUID!
    $firstname: String
    $lastname: String
    $email: String
    $idRole: BigFloat
    $idSite: UUID
  ) {
    updateUser(
      input: {
        patch: {
          firstname: $firstname
          lastname: $lastname
          email: $email
          idRole: $idRole
          idSite: $idSite
        }
        id: $id
      }
    ) {
      clientMutationId
      user {
        id
        firstname
        lastname
        email
        idRole
        idSite
      }
    }
  }
`

const DELETE_USER = gql`
  mutation deleteUser($id: UUID!) {
    deleteUser(input: { id: $id }) {
      clientMutationId
    }
  }
`

const GET_ALL_DEVICE_FROM_USER = gql`
  query userDevices($id: UUID!) {
    user(id: $id) {
      devicesByIdUser {
        nodes {
          id
        }
      }
    }
  }
`

export default {
  USERS,
  USER,
  SITES,
  GET_SITE_BY_ID,
  ROLES,
  CREATE_USER,
  UPDATE_USER,
  DELETE_USER,
  GET_ALL_DEVICE_FROM_USER,
}
