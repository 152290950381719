import { PropTypes } from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import './TabletUserLine.scss'

const TabletUserLine = (props) => {
  const { type, item, waiting, selected, onDelete } = props

  const { t } = useTranslation('tablets')

  return (
    <div className={selected ? 'tablet-user-line tablet-user-line__selected' : 'tablet-user-line'}>
      {type === 'tablets' && (
        <div className="tablet-user-line__tablets">
          <div className="tablet-user-line__tablets__left">
            {waiting ? (
              <p className="tablet-user-line__tablets__left__waiting">
                {t('waiting')} {t('tabletSeed')} {item?.seed?.slice(0, 7)}
              </p>
            ) : (
              <p className="tablet-user-line__tablets__left__tablet">
                {item.name || t('new') + item?.userByIdUser?.lastname}
              </p>
            )}
          </div>

          <div className="tablet-user-line__tablets__right">
            {!waiting && (
              <>
                <p className="tablet-user-line__tablets__right__site">
                  {item.userByIdUser?.siteByIdSite?.name || t('noSite')}
                </p>

                <div className="tablet-user-line__tablets__right__icon">
                  <img
                    className="tablet-user-line__tablets__right__icon__img"
                    src="icons/edit.png"
                    alt="remove product"
                  />
                </div>
              </>
            )}
            <button
              type="button"
              className="tablet-user-line__tablets__right__icon"
              onClick={onDelete}
            >
              <img
                className="tablet-user-line__tablets__right__icon__img"
                src="icons/supr.png"
                alt="remove product"
              />
            </button>
          </div>
        </div>
      )}

      {type === 'users' && (
        <div className="tablet-user-line__users">
          <div className="tablet-user-line__users__selec">
            <div
              className={
                selected
                  ? 'tablet-user-line__users__selec__circle tablet-user-line__users__selec__circle__selected'
                  : 'tablet-user-line__users__selec__circle'
              }
            />
          </div>
          <div className="tablet-user-line__users__full-name">
            <p className="tablet-user-line__users__full-name__firstname">{item.firstname}</p>
            <p className="tablet-user-line__users__full-name__name">
              {` ${item.lastname?.substr(0, 1)}. `}
            </p>
          </div>
          <p className="tablet-user-line__users__job">{item.companyRole}</p>
          <p className="tablet-user-line__users__site">{item.siteByIdSite?.name}</p>
          <div className="tablet-user-line__users__icon">
            <img
              className="tablet-user-line__users__icon__img"
              src="icons/edit.png"
              alt="remove product"
            />
          </div>
          <button type="button" className="tablet-user-line__users__icon" onClick={onDelete}>
            <img
              className="tablet-user-line__users__icon__img"
              src="icons/supr.png"
              alt="remove product"
            />
          </button>
        </div>
      )}
    </div>
  )
}

TabletUserLine.propTypes = {
  type: PropTypes.string.isRequired,
  item: PropTypes.shape({
    firstname: PropTypes.string,
    lastname: PropTypes.string,
    companyRole: PropTypes.string,
    name: PropTypes.string,
    site: PropTypes.string,
    seed: PropTypes.string,
    siteByIdSite: PropTypes.shape({
      name: PropTypes.string,
    }),
    userByIdUser: PropTypes.shape({
      firstname: PropTypes.string,
      lastname: PropTypes.string,
      companyRole: PropTypes.string,
      siteByIdSite: PropTypes.shape({
        name: PropTypes.string,
      }),
    }),
  }).isRequired,
  waiting: PropTypes.bool,
  selected: PropTypes.bool.isRequired,
  onDelete: PropTypes.isRequired,
}

TabletUserLine.defaultProps = {
  waiting: false,
}

export default TabletUserLine
