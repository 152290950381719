/* eslint-disable no-bitwise */
/* eslint-disable no-mixed-operators */
import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import { jwtDecode } from 'jwt-decode'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { v4 as uuid } from 'uuid'
import {
  Header,
  LayoutContainer,
  NavBar,
  UIButton,
  UIDropDown,
  UIRadioButton,
  UITextInput,
  WidgetOverlayValidate,
} from '../../components'
import { gqlTrucks, gqlUsers } from '../../gql'
import { formatNumberWithThreeDecimals, isAuthorized } from '../../lib/helpers'
import { captureMessageWithAttachment } from '../../lib/sentry'
import './Weighing.scss'

const Weighing = () => {
  const { t } = useTranslation('weighing')

  const accessToken = localStorage.getItem('access_token')
  const role = jwtDecode(accessToken)?.role
  const navigate = useNavigate()
  const optionsRadioButton = [
    { name: 'empty' },
    { name: 'rubble' },
    { name: 'materials' },
    { name: 'tare' },
  ]

  const [licencePlate, setLicencePlate] = useState()
  const [truckId, setTruckId] = useState()
  const [weighingId, setWeighingId] = useState()

  const [weight, setWeight] = useState()
  const [weightState, setWeightState] = useState('empty')
  const [error, setError] = useState(true)
  const [goToDelivery, setGotoDelivery] = useState(false)
  const [mustDisplaySavedOverlay, setMustDisplaySavedOverlay] = useState(false)
  const [sortSite, setSortSite] = useState()

  const afterRegisterClick = () => {
    if (goToDelivery && licencePlate) {
      navigate(`/new-delivery?licensePlate=${licencePlate}`)
    } else {
      setTimeout(() => {
        setMustDisplaySavedOverlay(false)
        setLicencePlate('')
        setWeight('')
        setWeightState('empty')
      }, 1000)
      setMustDisplaySavedOverlay(true)
    }
  }

  const { data: sites } = useQuery(gqlUsers.SITES)

  const { data: user } = useQuery(gqlUsers.USER, {
    variables: { id: jwtDecode(accessToken)?.id },
    onCompleted: () => {
      setSortSite(user?.user?.idSite)
    },
  })

  const [updateWeight] = useMutation(gqlTrucks.UPDATE_WEIGHT_TRUCK, {
    onCompleted: () => {
      afterRegisterClick()
    },
    onError: (err) => {
      const request = gqlTrucks.UPDATE_WEIGHT_TRUCK
      const event = {
        request: request?.definitions[0]?.name?.value,
        variables: {
          id: weighingId,
          truckId,
          licencePlate,
        },
      }
      captureMessageWithAttachment(event, err)
    },
  })

  const [updateUnloadedWeight] = useMutation(gqlTrucks.UPDATE_UNLOADED_WEIGHT, {
    onCompleted: () => {
      afterRegisterClick()
    },
    onError: (err) => {
      const request = gqlTrucks.UPDATE_UNLOADED_WEIGHT
      const event = {
        request: request?.definitions[0]?.name?.value,
        variables: {
          id: truckId,
          licencePlate,
        },
      }
      captureMessageWithAttachment(event, err)
    },
  })

  const updateWeighing = () => {
    switch (weightState) {
      case 'empty':
        updateUnloadedWeight({
          variables: {
            id: truckId,
            unloadedWeight: formatNumberWithThreeDecimals(weight),
          },
        })
        break
      case 'rubble':
        updateWeight({
          variables: {
            id: weighingId,
            weightRubble: formatNumberWithThreeDecimals(weight),
          },
        })
        break
      case 'materials':
        updateWeight({
          variables: {
            id: weighingId,
            weightMaterials: formatNumberWithThreeDecimals(weight),
          },
        })
        break
      case 'tare':
        updateWeight({
          variables: {
            id: weighingId,
            weightTare: formatNumberWithThreeDecimals(weight),
          },
        })
        break
      default:
        console.log('error updating')
    }
  }

  const [createWeight] = useMutation(gqlTrucks.CREATE_WEIGHT, {
    onCompleted: (response) => {
      switch (weightState) {
        case 'empty':
          updateUnloadedWeight({
            variables: {
              id: truckId,
              unloadedWeight: formatNumberWithThreeDecimals(weight),
            },
          })
          break
        case 'rubble':
          updateWeight({
            variables: {
              id: response.createWeighing.weighing.id,
              weightRubble: formatNumberWithThreeDecimals(weight),
            },
          })
          break
        case 'materials':
          updateWeight({
            variables: {
              id: response.createWeighing.weighing.id,
              weightMaterials: formatNumberWithThreeDecimals(weight),
            },
          })
          break
        case 'tare':
          updateWeight({
            variables: {
              id: response.createWeighing.weighing.id,
              weightTare: formatNumberWithThreeDecimals(weight),
            },
          })
          break
        default:
          console.log('error updating')
      }
    },
  })

  const [updateTruckOnSite] = useMutation(gqlTrucks.UPDATE_TRUCK_SITE, {
    onCompleted: () => {
      if ((!weighingId || weighingId === undefined) && truckId) {
        createWeight({ variables: { idTruck: truckId } })
      } else {
        updateWeighing()
      }
    },
  })

  const [createTruck] = useMutation(gqlTrucks.CREATE_TRUCK, {
    onCompleted: (response) => {
      createWeight({
        variables: { idTruck: response?.createTruck?.truck?.id },
      })
    },
    onError: (err) => {
      const request = gqlTrucks.CREATE_TRUCK
      const event = {
        request: request?.definitions[0]?.name?.value,
        variables: {
          id: truckId,
          licencePlate,
        },
      }
      captureMessageWithAttachment(event, err)
    },
  })

  const [fetchWeightByIdTruck] = useLazyQuery(gqlTrucks.WEIGHINGS_NO_APPROVED_BY_ID_TRUCK, {
    fetchPolicy: 'network-only',
    onCompleted: (response) => {
      setWeighingId(response.weighings.nodes[0]?.id)
    },
  })

  const [fetchIdTruck, { data: truck }] = useLazyQuery(gqlTrucks.TRUCK_BY_LICENSEPLATE, {
    fetchPolicy: 'network-only',
    variables: { plicensePlate: licencePlate },
    onCompleted: () => {
      setWeighingId(null)
      if (truck.trucks.nodes[0]?.id) {
        setTruckId(truck.trucks.nodes[0].id)
        if (truck.trucks.nodes[0]?.unloadedWeight && weightState === 'empty') {
          setWeight(truck.trucks.nodes[0]?.unloadedWeight)
        }
        fetchWeightByIdTruck({
          variables: { idTruck: truck.trucks.nodes[0]?.id },
        })
      } else {
        setTruckId(null)
      }
    },
  })

  const onRegisterClick = () => {
    if (!truckId) {
      const UUIDTruck = uuid()
      setTruckId(UUIDTruck)
      createTruck({
        variables: {
          idSite: sortSite,
          id: UUIDTruck,
          licencePlate,
        },
      })
    } else {
      updateTruckOnSite({
        variables: { id: truckId, idSite: sortSite },
      })
    }
  }

  useEffect(() => {
    if (licencePlate && licencePlate.length > 6) {
      fetchIdTruck()
    }
  }, [licencePlate, fetchIdTruck])

  useEffect(() => {
    if (licencePlate && weight) {
      setError(false)
    } else {
      setError(true)
    }
  }, [licencePlate, weight])

  return (
    <div className="weighing">
      <Header title={t('title')} />
      <NavBar active="weighing" />
      <LayoutContainer>
        <div className="weighing__container">
          {isAuthorized(role, 'bdn_admin') && sites?.sites?.nodes && (
            <div className="weighing__container__dropdown">
              <UIDropDown
                options={sites?.sites?.nodes}
                onChange={(e) => setSortSite(e.target.value)}
                value={sortSite}
                style={{ width: '250px' }}
              />
            </div>
          )}
          {mustDisplaySavedOverlay && (
            <div className="weighing__container__overlay">
              <WidgetOverlayValidate />
            </div>
          )}
          <div className="weighing__container__form">
            <div className="weighing__container__form__item">
              <UITextInput
                type="string"
                value={licencePlate}
                onChange={(e) => {
                  setLicencePlate(e.toUpperCase())
                }}
                autoFocus={true}
                success={truckId}
                prefix={t('licenceplate')}
                isRequired={true}
                style={{ height: '40px' }}
                styleInput={{ width: '100%' }}
              />
            </div>

            <div className="weighing__container__form__item">
              <UITextInput
                type="number"
                step="0.001"
                value={weight}
                onChange={(e) => setWeight(e)}
                prefix={t('weight')}
                unit="T"
                isRequired={true}
                style={{ height: '40px' }}
                styleInput={{ width: '100%' }}
              />
            </div>

            <div className="weighing__container__form__item">
              <UIRadioButton
                options={optionsRadioButton}
                radioName="weightState"
                checked={weightState}
                onChange={(e) => setWeightState(e.target.value)}
                prefix={t('state')}
              />
            </div>
          </div>
          <div className="weighing__container__buttons">
            <div className="weighing__container__buttons__send">
              <UIButton
                type="primary"
                text={t('delivery')}
                onClick={() => {
                  setGotoDelivery(true)
                  onRegisterClick()
                }}
                alt={t('delivery')}
                disabled={error}
                style={{ width: '100%', height: '100%' }}
              />
            </div>
            <div className="weighing__container__buttons__send">
              <UIButton
                type="primary"
                text={t('register')}
                onClick={() => onRegisterClick()}
                alt={t('altRegister')}
                disabled={error}
                style={{ width: '100%', height: '100%' }}
              />
            </div>
          </div>
        </div>
      </LayoutContainer>
    </div>
  )
}

export default Weighing
