import { jwtDecode } from 'jwt-decode'
import PropTypes from 'prop-types'
import { Navigate, useLocation } from 'react-router-dom'
import { isAuthorized } from '../../../lib/helpers'

const ProtectedRoute = ({ access, children }) => {
  const location = useLocation()
  const token = localStorage.getItem('access_token')
  let userRole = null

  if (!token) {
    return <Navigate to="/" state={{ from: location }} />
  }

  if (token) {
    try {
      const decodedToken = jwtDecode(token)
      userRole = decodedToken?.role
      if (!userRole || !isAuthorized(userRole, access)) {
        return <Navigate to="/" />
      }
    } catch (error) {
      return <Navigate to="/" />
    }
  }

  return children
}
ProtectedRoute.propTypes = {
  access: PropTypes.arrayOf(PropTypes.string),
  children: PropTypes.node.isRequired,
}

ProtectedRoute.defaultProps = { access: [] }

export default ProtectedRoute
