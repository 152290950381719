import React from 'react'
import './UILoading.scss'

const UILoading = () => (
  <div className="loading">
    <div className="holder">
      <div className="preloader">
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
  </div>
)

export default UILoading
