import { gql } from '@apollo/client'

const CLIENTS = gql`
  query clients {
    clients(filter: { internalId: { startsWith: "M" } }) {
      edges {
        node {
          id
          displayName
          internalId
          active
        }
      }
    }
  }
`

const CLIENT_BY_ID = gql`
  query client($id: UUID!) {
    client(id: $id) {
      displayName
    }
  }
`

const FREIGHT_FORWARDERS = gql`
  query freightForwarders {
    freightForwarders {
      nodes {
        id
        displayName
      }
    }
  }
`

const FREIGHT_FORWARDER = gql`
  query freightForwarder($id: UUID!) {
    freightForwarder(id: $id) {
      id
      displayName
    }
  }
`

export default {
  CLIENTS,
  CLIENT_BY_ID,
  FREIGHT_FORWARDERS,
  FREIGHT_FORWARDER,
}
