import html2canvas from 'html2canvas'
import { PropTypes } from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ProductsLine } from '../..'
import api from '../../../api/index.js'
import { convertDataURIToBinary } from '../../../lib/helpers'
import { getDate, getTime } from '../../../lib/utils'
import './PrintDelivery.scss'

const PrintDelivery = (props) => {
  const {
    createFile,
    type,
    id,
    date,
    saleOrderCode,
    client,
    site,
    firstname,
    lastname,
    constructionSiteCode,
    constructionSiteAddress,
    constructionSiteCity,
    licensePlate,
    materials,
    signature,
    refExt,
    closeDoc,
  } = props

  const { t } = useTranslation('print')

  const [isAlreadyPrinted, setIsAlreadyPrinted] = useState(false)

  const printDocument = async () => {
    const accessToken = localStorage.getItem('access_token')
    const input = document.getElementById('divToPrint')
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL('image/png')
      const binary = convertDataURIToBinary(imgData)
      const blob = new Blob([binary], { type: 'image/png' })
      try {
        api.uploadFile(id, blob, accessToken, 'signature')
      } catch (e) {
        console.log('error sending signature')
      }
    })
  }

  useEffect(() => {
    if (type === 'new-delivery') {
      if (!isAlreadyPrinted) {
        printDocument()
        setIsAlreadyPrinted(true)
      }
    }
  }, [printDocument, isAlreadyPrinted, type])

  return (
    <div
      className="print-delivery"
      style={{ width: createFile ? '21cm' : null }}
      onClick={closeDoc}
      id="divToPrint"
    >
      <div className="print-delivery_header">
        <img
          className="print-delivery_header_icon"
          src={type === 'print-page' ? '../images/Logo-BDN.png' : 'images/Logo-BDN.png'}
          alt={t('altlogo')}
        />

        <div className="print-delivery_header_informations">
          <p>{`${t('registerDate')} ${getDate(date)} ${getTime(date)}`}</p>
          <p>{`${t('deliveryCode')} ${saleOrderCode}`}</p>
        </div>
      </div>

      <div className="print-delivery_informations">
        <div className="print-delivery_informations_block">
          <p>{t('destination')}</p>
          <p>{client}</p>
          <p>{constructionSiteCode}</p>
          <p>{constructionSiteAddress}</p>
          <p>{constructionSiteCity}</p>
          <p>{licensePlate}</p>
        </div>

        <div className="print-delivery_informations_block">
          <p>{`${t('refExt')} ${refExt}`}</p>
        </div>

        <div className="print-delivery_informations_block">
          <p>{t('user')}</p>
          <p>{`${firstname} ${lastname}`}</p>
          <p>{site}</p>
        </div>
      </div>

      <div className="print-delivery_materials">
        <p>{t('expedition')}</p>
        <div className="print-delivery_materials_header">
          <p className="print-delivery_materials_ref">{t('ref')}</p>
          <p className="print-delivery_materials_quantity">{t('quantity')}</p>
        </div>
        {materials.map((product, index) => (
          <div key={index} className="print-delivery_materials_products">
            <ProductsLine type={type} product={product} />
          </div>
        ))}
      </div>

      <div className="print-delivery_legalMention">
        <div className="print-delivery_legalMention_block">
          <p>{t('legalMention1')}</p>
          <p>{t('legalMention2')}</p>
        </div>

        <div className="print-delivery_legalMention_block">
          <p>{t('legalMention3')}</p>
          <p>{t('legalMention4')}</p>
        </div>

        <div className="print-delivery_legalMention_block">
          <p>{t('legalMention5')}</p>
          <p>{t('legalMention6')}</p>
        </div>

        <div className="print-delivery_legalMention_block">
          <p>{t('legalMention7')}</p>
          <p>{t('legalMention8')}</p>
        </div>
      </div>

      <div className="print-delivery_signature">
        <p>{t('signature')}</p>
        {signature && (
          <img src={signature} alt={t('altSignature')} className="print-delivery_signature_img" />
        )}
      </div>
    </div>
  )
}

PrintDelivery.propTypes = {
  createFile: PropTypes.bool,
  type: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  saleOrderCode: PropTypes.string.isRequired,
  client: PropTypes.string.isRequired,
  site: PropTypes.string.isRequired,
  firstname: PropTypes.string.isRequired,
  lastname: PropTypes.string.isRequired,
  constructionSiteCode: PropTypes.string.isRequired,
  constructionSiteAddress: PropTypes.string.isRequired,
  constructionSiteCity: PropTypes.string.isRequired,
  licensePlate: PropTypes.string.isRequired,
  materials: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  signature: PropTypes.string.isRequired,
  refExt: PropTypes.string.isRequired,
  closeDoc: PropTypes.func.isRequired,
}

PrintDelivery.defaultProps = {
  createFile: false,
}

export default PrintDelivery
