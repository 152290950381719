import { PropTypes } from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { ProductsLine, UIButton, UITextInput } from '../..'
import './NewDeliveryStep3.scss'

const NewDeliveryStep3 = (props) => {
  const {
    saleOrderCode,
    saleOrderOnChange,
    client,
    clientOnChange,
    freightForwarders,
    freightForwardersOnChange,
    externRef,
    externRefOnChange,
    destinationSite,
    destinationSiteOnChange,
    constructionSiteCode,
    constructionSiteCodeOnChange,
    constructionSiteAddress,
    constructionSiteAddressOnChange,
    constructionSiteCity,
    constructionSiteCityOnChange,
    licensePlate,
    licensePlateOnChange,
    materials,
    signature,
    setStepMaterial,
    setStepPrevious,
    register,
    disableRegister,
    bdcFile,
  } = props

  const { t } = useTranslation('newDelivery')

  return (
    <div className="new-delivery-third">
      <div className="new-delivery-third__left">
        <div className="new-delivery-third__left__container-input">
          <UITextInput
            type="string"
            id="numOrdered"
            value={saleOrderCode}
            onChange={saleOrderOnChange}
            isRequired={true}
            prefix={t('inputNumOrder')}
            placeHolder={t('placeHolderNumOrder')}
            stylePrefix={{ fontWeight: '600' }}
          />
        </div>

        <div className="new-delivery-third__left__container-input">
          <UITextInput
            type="string"
            id="client"
            value={client}
            onChange={clientOnChange}
            prefix={t('inputClient')}
            stylePrefix={{ fontWeight: '600' }}
          />
        </div>

        <div className="new-delivery-third__left__container-input">
          <UITextInput
            type="string"
            id="freightForwarders"
            value={freightForwarders}
            onChange={freightForwardersOnChange}
            prefix={t('inputFreightForwarders')}
            stylePrefix={{ fontWeight: '600' }}
          />
        </div>

        <div className="new-delivery-third__left__container-input">
          <UITextInput
            type="string"
            id="construction"
            value={constructionSiteCode}
            onChange={constructionSiteCodeOnChange}
            prefix={t('prefixCodeConstruction')}
            placeHolder={t('placeHolderCodeConstruction')}
            stylePrefix={{ fontWeight: '600' }}
          />
        </div>

        <div className="new-delivery-third__left__container-input">
          <UITextInput
            type="string"
            id="address"
            value={constructionSiteAddress}
            onChange={constructionSiteAddressOnChange}
            prefix={t('prefixAdress')}
            placeHolder={t('placeHolderAdress')}
            style={{ marginLeft: '20px' }}
            styleContainer={{ width: '100%' }}
            styleLayout={{ flexDirection: 'column' }}
            styleInput={{ width: '95%' }}
            stylePrefix={{
              fontWeight: '600',
              width: '50%',
              justifyContent: 'flex-start',
              marginBottom: '5px',
            }}
          />
        </div>
        <div className="new-delivery-third__left__container-input">
          <UITextInput
            type="string"
            id="city"
            value={constructionSiteCity}
            onChange={constructionSiteCityOnChange}
            prefix={t('prefixCity')}
            placeHolder={t('placeHolderCity')}
            stylePrefix={{ fontWeight: '600' }}
          />
        </div>
        <div className="new-delivery-third__left__container-input">
          <UITextInput
            type="string"
            id="licensePlate"
            value={licensePlate}
            onChange={licensePlateOnChange}
            prefix={t('prefixImmatriculation')}
            placeHolder={t('placeHolderImmatriculation')}
            isRequired={true}
            stylePrefix={{ fontWeight: '600' }}
          />
        </div>

        <div className="new-delivery-third__left__container-input">
          <UITextInput
            type="string"
            id="externRef"
            value={externRef}
            onChange={externRefOnChange}
            prefix={t('inputExternRef')}
            placeHolder={t('placeHolderExternRef')}
            stylePrefix={{ fontWeight: '600', width: '40%' }}
          />
        </div>

        <div className="new-delivery-third__left__container-input">
          <UITextInput
            type="string"
            id="destinationSite"
            value={destinationSite}
            onChange={destinationSiteOnChange}
            prefix={t('inputDestinationSite')}
            placeHolder={t('placeHolderDestinationSite')}
            stylePrefix={{ fontWeight: '600', width: '40%' }}
          />
        </div>

        <div className="new-delivery-third__left__container-BDC">
          <h2 className="new-delivery-third__left__container-BDC__prefix">{t('prefixScanBdc')}</h2>
          {bdcFile ? (
            <>
              <p className="new-delivery-third__left__container-BDC__text">{t('importScanBdc')}</p>
              <div className="new-delivery-third__left__container-BDC__icon">
                <img
                  className="new-delivery-third__left__container-BDC__icon__img"
                  src="icons/check.png"
                  alt="check"
                />
              </div>
            </>
          ) : (
            <p className="new-delivery-third__left__container-BDC__text__no-import">
              {t('noImportScanBdc')}
            </p>
          )}
        </div>
      </div>

      <div className="new-delivery-third__right">
        <div className="new-delivery-third__right__top">
          <div className="new-delivery-third__right__top__header">
            <h1>{t('products')}</h1>
            <button
              type="button"
              className="new-delivery-third__right__top__header__button"
              onClick={setStepMaterial}
            >
              <p className="new-delivery-third__right__top__header__button__text">{t('edit')}</p>
            </button>
          </div>
          <div className="new-delivery-third__right__top__scroll">
            {materials &&
              materials.map((product, index) => (
                <div key={index} className="new-delivery-third__right__top__scroll__line">
                  <ProductsLine type="new-delivery" product={product} />
                </div>
              ))}
          </div>
        </div>

        <div className="new-delivery-third__right__middle" contentEditable>
          <p className="new-delivery-third__right__middle__sign">{t('sign')}</p>
          {signature && <img src={signature} alt="signature" />}
        </div>

        <div className="new-delivery-third__right__bottom">
          <div className="new-delivery-third__right__bottom__button__back">
            <UIButton
              type="secondary"
              text={t('back')}
              onClick={setStepPrevious}
              alt={t('back')}
              style={{ width: '100%', height: '50px' }}
            />
          </div>
          <div className="new-delivery-third__right__bottom__button__register">
            <UIButton
              type="primary"
              text={t('register')}
              onClick={register}
              alt={t('register')}
              style={{ width: '100%', height: '50px' }}
              disabled={disableRegister}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

NewDeliveryStep3.propTypes = {
  saleOrderCode: PropTypes.string.isRequired,
  saleOrderOnChange: PropTypes.func.isRequired,
  client: PropTypes.string.isRequired,
  clientOnChange: PropTypes.func.isRequired,
  freightForwarders: PropTypes.string.isRequired,
  freightForwardersOnChange: PropTypes.func.isRequired.isRequired,
  externRef: PropTypes.string.isRequired,
  externRefOnChange: PropTypes.func.isRequired,
  destinationSite: PropTypes.string.isRequired,
  destinationSiteOnChange: PropTypes.func.isRequired,
  constructionSiteCode: PropTypes.string.isRequired,
  constructionSiteCodeOnChange: PropTypes.func.isRequired,
  constructionSiteAddress: PropTypes.string.isRequired,
  constructionSiteAddressOnChange: PropTypes.func.isRequired,
  constructionSiteCity: PropTypes.string.isRequired,
  constructionSiteCityOnChange: PropTypes.func.isRequired,
  licensePlate: PropTypes.string,
  licensePlateOnChange: PropTypes.func.isRequired,
  materials: PropTypes.arrayOf(PropTypes.shape({})),
  signature: PropTypes.string.isRequired,
  setStepMaterial: PropTypes.func.isRequired,
  setStepPrevious: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
  disableRegister: PropTypes.bool.isRequired,
  bdcFile: PropTypes.shape({}),
}

NewDeliveryStep3.defaultProps = {
  licensePlate: '',
  materials: [],
  bdcFile: null,
}

export default NewDeliveryStep3
