import React from 'react'
import { PropTypes } from 'prop-types'
import { useTranslation } from 'react-i18next'
import './UISearchBar.scss'

import UITextInput from '../UITextInput/UITextInput'

const UISearchBar = (props) => {
  const { value, onChange, disable, styleInput } = props

  const { t } = useTranslation('searchBar')

  return (
    <div className="search-bar">
      <div className="search-bar__icon">
        <img className="search-bar__icon__img" src="icons/search.png" alt="dropdown arrow" />
      </div>
      <UITextInput
        type="string"
        value={value}
        onChange={onChange}
        placeHolder={t('placeHolder')}
        disabled={disable}
        styleInput={styleInput}
      />
    </div>
  )
}

UISearchBar.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  disable: PropTypes.bool,
  styleInput: PropTypes.shape({}),
}

UISearchBar.defaultProps = {
  disable: false,
  styleInput: null,
}

export default UISearchBar
