import moment from 'moment'
import { PropTypes } from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import Clock from 'react-live-clock'
import { useNavigate } from 'react-router-dom'
import './Header.scss'

const Header = (props) => {
  const { title } = props

  const { t } = useTranslation('header')
  const navigate = useNavigate()

  return (
    <div className="header">
      <button
        type="button"
        className="header__image"
        onClick={() => navigate('/following-delivery')}
      >
        <img className="header__image__logo-bdn" src="images/Logo-BDN.png" alt={t('altimage')} />
      </button>

      <h1 className="header__title">{title}</h1>

      <div className="header__container-date">
        <h2 className="header__container-date__time">
          <Clock ticking={true} />
        </h2>
        <h2 className="header__container-date__date">{moment().format('DD/MM/YYYY')}</h2>
      </div>
    </div>
  )
}

Header.propTypes = {
  title: PropTypes.string.isRequired,
}

export default Header
