import { PropTypes } from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { UITextInput, UITextInputAutoCompletion } from '../..'
import './NewDeliveryStep2.scss'

const NewDeliveryStep2 = (props) => {
  const {
    clearingName,
    clearingNameOnChange,
    clearingIdOnCange,
    clearingWeight,
    clearingWeightOnChange,
    rubbleAutoCompletion,
    unit,
    setUnit,
  } = props

  const { t } = useTranslation('newDelivery')

  return (
    <div className="new-delivery-second">
      <div className="new-delivery-second__title">
        <h1>{t('materials')}</h1>
      </div>
      <div className="new-delivery-second__excavation">
        <div className="new-delivery-second__excavation__information">
          <p className="new-delivery-second__excavation__information__text">{t('excavation')}</p>
          <UITextInputAutoCompletion
            type="string"
            value={clearingName}
            onChange={clearingNameOnChange}
            valueId={clearingIdOnCange}
            setUnit={setUnit}
            placeHolder={t('clearingName')}
            autoCompleteList={rubbleAutoCompletion}
            autoCompleteType="rubbles"
            shiny={true}
            styleInput={{ width: '200px', height: '30px', fontSize: '14px' }}
            style={{ width: '200px' }}
          />
          <UITextInput
            type="number"
            value={clearingWeight}
            onChange={clearingWeightOnChange}
            placeHolder={t('unit')}
            unit={t(unit)}
            shiny={true}
            styleInput={{ width: '200px', height: '30px', fontSize: '14px' }}
            style={{ width: '250px' }}
          />
        </div>
      </div>
    </div>
  )
}

NewDeliveryStep2.propTypes = {
  clearingName: PropTypes.string.isRequired,
  clearingNameOnChange: PropTypes.func.isRequired,
  clearingIdOnCange: PropTypes.func.isRequired,
  clearingWeight: PropTypes.string,
  clearingWeightOnChange: PropTypes.func.isRequired,
  rubbleAutoCompletion: PropTypes.arrayOf(PropTypes.string).isRequired,
  unit: PropTypes.string,
  setUnit: PropTypes.func,
}

NewDeliveryStep2.defaultProps = {
  clearingWeight: '',
  unit: '',
  setUnit: null,
}

export default NewDeliveryStep2
