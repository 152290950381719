import { PropTypes } from 'prop-types'
import React from 'react'
import { TabletUserLine } from '../..'
import './TabletUserGrid.scss'

const TabletUserGrid = (props) => {
  const { type, data, waiting, selected, onChangeSelected, onDelete } = props

  return (
    <div className="tablet-user-grid">
      {data &&
        data.map((item, index) => (
          <button
            key={item.id}
            className="tablet-user-grid__button"
            type="button"
            onClick={() => onChangeSelected(index)}
          >
            <TabletUserLine
              type={type}
              item={item}
              waiting={waiting}
              selected={selected === index}
              onDelete={onDelete}
            />
          </button>
        ))}
    </div>
  )
}

TabletUserGrid.propTypes = {
  type: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  waiting: PropTypes.bool,
  selected: PropTypes.number,
  onChangeSelected: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
}

TabletUserGrid.defaultProps = {
  waiting: false,
  selected: -1,
}

export default TabletUserGrid
