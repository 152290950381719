import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  ConfirmPopIn,
  Header,
  LayoutContainer,
  NavBar,
  TabletUserGrid,
  UIButton,
  UIDropDown,
  UISearchBar,
  UITextInput,
  WidgetOverlayValidate,
} from '../../components'
import { gqlTablets, gqlUsers } from '../../gql'
import { captureMessageWithAttachment } from '../../lib/sentry'
import './Users.scss'

const Users = () => {
  const { t } = useTranslation('users')

  const [sortSite, setSortSite] = useState('')
  const [sortJob, setSortJob] = useState('')
  const [searchBar, setSearchBar] = useState('')

  const [id, setId] = useState('')
  const [lastname, setLastname] = useState('')
  const [firstname, setFirstname] = useState('')
  const [roleId, setRoleId] = useState('')
  const [siteId, setSiteId] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState()
  const [createOverlay, setCreateOverlay] = useState(false)
  const [errorPassword, setErrorPassword] = useState(false)
  const [errorForm, setErrorForm] = useState(false)

  const [currentSelected, setCurrentSelected] = useState()
  const [openAdding, setOpenAdding] = useState(false)
  const [popinDelete, setPopinDelete] = useState(false)

  const { data: users } = useQuery(gqlUsers.USERS, {
    fetchPolicy: 'network-only',
  })

  const [fetchSortedUsers, { data: sortedUsers }] = useLazyQuery(gqlUsers.USERS, {
    fetchPolicy: 'network-only',
  })

  const { data: sites } = useQuery(gqlUsers.SITES)

  const { data: roles } = useQuery(gqlUsers.ROLES)

  const [createUser] = useMutation(gqlUsers.CREATE_USER, {
    onCompleted: () => {
      setCreateOverlay(true)
      setTimeout(() => setCreateOverlay(false), 1000)
    },
    onError: (err) => {
      const request = gqlUsers.CREATE_USER
      const event = {
        request: request?.definitions[0]?.name?.value,
        variables: {
          email,
          firstname,
          lastname,
          idRole: roleId || roles?.roles.nodes[0].id,
          idSite: siteId || sites?.sites.nodes[0].id,
        },
      }
      captureMessageWithAttachment(event, err)
    },
  })

  const [updateUser] = useMutation(gqlUsers.UPDATE_USER, {
    variables: {
      id,
      firstname,
      lastname,
      email,
      idRole: roleId,
      idSite: siteId,
    },
    refetchQueries: [{ query: gqlUsers.USERS }],
    optimisticResponse: {
      updateUser: {
        clientMutationId: null,
        user: {
          id,
          firstname,
          lastname,
          email,
          idRole: roleId,
          idSite: siteId,
          __typename: 'User',
        },
        __typename: 'UpdateUserPayload',
      },
    },
    onCompleted: () => {
      setCreateOverlay(true)
      setTimeout(() => setCreateOverlay(false), 1000)
    },
    onError: (err) => {
      const request = gqlUsers.UPDATE_USER
      const event = {
        request: request?.definitions[0]?.name?.value,
        variables: {
          id,
          firstname,
          lastname,
          email,
          idRole: roleId,
          idSite: siteId,
        },
      }
      captureMessageWithAttachment(event, err)
    },
  })

  const [deleteUser] = useMutation(gqlUsers.DELETE_USER, {
    variables: {
      id,
    },
    onError: (err) => {
      const request = gqlUsers.DELETE_USER
      const event = {
        request: request?.definitions[0]?.name?.value,
        variables: {
          id,
        },
      }
      captureMessageWithAttachment(event, err)
    },
  })

  const [deleteDevice] = useMutation(gqlTablets.DEVICE_DELETE)

  const [fetchUserDevicesAndDeleteThenDeleteUser, { data: userDevices }] = useLazyQuery(
    gqlUsers.GET_ALL_DEVICE_FROM_USER,
    {
      variables: {
        id,
      },
      onCompleted: () => {
        const idDevices = userDevices?.user?.devicesByIdUser?.nodes
        if (idDevices.length === 0) {
          deleteUser()
        } else {
          for (let i = 0; i < idDevices.length; i++) {
            deleteDevice({
              variables: {
                id: idDevices[i].id,
              },
            })
            if (i === idDevices.length - 1) {
              deleteUser()
            }
          }
        }
      },
    }
  )

  const onCreate = () => {
    setErrorPassword(false)
    setErrorForm(false)
    if (email && firstname && lastname && password && password === confirmPassword) {
      createUser({
        variables: {
          email,
          firstname,
          lastname,
          idRole: roleId || roles?.roles.nodes[0].id,
          idSite: siteId || sites?.sites.nodes[0].id,
          password,
        },
      })
    } else if (password && password !== confirmPassword) {
      setErrorPassword(true)
    } else {
      setErrorForm(true)
    }
  }

  useEffect(() => {
    if (currentSelected !== undefined && users) {
      setId(users.users.nodes[currentSelected].id)
      setLastname(users.users.nodes[currentSelected].lastname || '')
      setFirstname(users.users.nodes[currentSelected].firstname || '')
      setRoleId(users.users.nodes[currentSelected].idRole)
      setSiteId(users.users.nodes[currentSelected].idSite)
      setEmail(users.users.nodes[currentSelected].email || '')
      setOpenAdding(false)
    }
  }, [currentSelected, users])

  useEffect(() => {
    if (openAdding) {
      setLastname('')
      setFirstname('')
      setRoleId('')
      setSiteId('')
      setEmail('')
    }
  }, [openAdding])

  useEffect(() => {
    fetchSortedUsers({
      variables: {
        idSite: sortSite || '',
      },
    })
  }, [sortSite, fetchSortedUsers])

  return (
    <div className="users">
      <Header title={t('title')} />
      <NavBar active="users" />
      <LayoutContainer>
        <div className="users__container">
          {popinDelete && (
            <ConfirmPopIn
              text={t('deleteUser')}
              back={() => {
                setPopinDelete(false)
              }}
              confirm={() => {
                fetchUserDevicesAndDeleteThenDeleteUser()
                setPopinDelete(false)
              }}
            />
          )}

          {createOverlay && (
            <div className="users__container__overlay">
              <WidgetOverlayValidate />
            </div>
          )}
          <div className="users__container__left">
            <div className="users__container__left__header">
              <h2 className="users__container__left__header__sort">{t('sort')}</h2>
              {roles?.roles?.nodes && (
                <div className="users__container__left__header__dropdown">
                  <UIDropDown
                    options={roles?.roles?.nodes}
                    onChange={(e) => setSortJob(e.target.value)}
                    value={sortJob}
                    placeholder="job"
                    disabled
                    style={{ width: '170px' }}
                  />
                </div>
              )}

              {sites?.sites?.nodes && (
                <div className="users__container__left__header__dropdown">
                  <UIDropDown
                    options={sites?.sites?.nodes}
                    onChange={(e) => setSortSite(e.target.value)}
                    value={sortSite}
                    placeholder="site"
                    style={{ width: '100%' }}
                  />
                </div>
              )}
              <div className="users__container__left__header__search">
                <UISearchBar
                  value={searchBar}
                  onChange={(e) => setSearchBar(e)}
                  styleInput={{
                    height: '40px',
                    paddingLeft: '35px',
                    backgroundColor: '#F4F4F4',
                    border: '#F4F4F4',
                  }}
                  disable
                />
              </div>
            </div>
            {(users?.users?.nodes || sortedUsers?.users?.nodes) && (
              <div className="users__container__left__grid">
                <TabletUserGrid
                  type="users"
                  data={sortedUsers?.users?.nodes || users?.users?.nodes}
                  selected={currentSelected}
                  onChangeSelected={setCurrentSelected}
                  onDelete={() => setPopinDelete(true)}
                />
              </div>
            )}

            <button
              type="button"
              className="users__container__left__add"
              onClick={() => {
                setOpenAdding(true)
                setCurrentSelected(undefined)
              }}
            >
              <p className="users__container__left__add__text">+ {t('add')}</p>
            </button>
          </div>
          <div className="users__container__right">
            <div className="users__container__right__title">
              {openAdding ? (
                <h2 className="users__container__right__title__text">{t('add')}</h2>
              ) : (
                <h2 className="users__container__right__title__text">{t('profil')}</h2>
              )}
            </div>

            <div className="users__container__right__text-input">
              <UITextInput
                type="string"
                value={lastname}
                onChange={(e) => setLastname(e)}
                prefix={t('name')}
                placeHolder={t('name')}
                stylePrefix={{ fontSize: '14px', width: '20%' }}
              />
            </div>

            <div className="users__container__right__text-input">
              <UITextInput
                type="string"
                value={firstname}
                onChange={(e) => setFirstname(e)}
                prefix={t('firstName')}
                placeHolder={t('firstName')}
                stylePrefix={{ fontSize: '14px', width: '20%' }}
              />
            </div>

            <div className="users__container__right__text-input">
              <p className="users__container__right__text-input__prefix">{t('job')} :</p>
              {roles && (
                <div className="users__container__right__text-input__dropdown">
                  <UIDropDown
                    options={roles.roles.nodes}
                    onChange={(e) => setRoleId(e.target.value)}
                    defaultValue={roleId}
                    style={{ width: '85%' }}
                  />
                </div>
              )}
            </div>

            <div className="users__container__right__text-input">
              <p className="users__container__right__text-input__prefix">{t('site')} :</p>
              {sites && (
                <div className="users__container__right__text-input__dropdown">
                  <UIDropDown
                    options={sites.sites.nodes}
                    onChange={(e) => setSiteId(e.target.value)}
                    defaultValue={siteId}
                    type="sites"
                    style={{ width: '85%' }}
                  />
                </div>
              )}
            </div>

            <div className="users__container__right__text-input">
              <UITextInput
                type="string"
                value={email}
                onChange={(e) => setEmail(e)}
                prefix={t('Email')}
                placeHolder={t('Email')}
                stylePrefix={{ fontSize: '14px', width: '20%' }}
              />
            </div>

            {openAdding && (
              <div className="users__container__right__password">
                <div className="users__container__right__password__text-input">
                  <UITextInput
                    type="string"
                    value={password}
                    onChange={(e) => setPassword(e)}
                    prefix={t('password')}
                    placeHolder={t('password')}
                    stylePrefix={{ fontSize: '14px', width: '40%' }}
                  />
                </div>

                <div className="users__container__right__password__text-input">
                  <UITextInput
                    type="string"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e)}
                    prefix={t('confirmPassword')}
                    placeHolder={t('confirmPassword')}
                    stylePrefix={{ fontSize: '14px', width: '40%' }}
                  />
                </div>
              </div>
            )}
            {openAdding && errorPassword && (
              <p className="users__container__right__password__error">{t('passwordError')}</p>
            )}
            {openAdding && errorForm && (
              <p className="users__container__right__password__error">{t('formError')}</p>
            )}
            {openAdding ? (
              <div className="users__container__right__button">
                <UIButton
                  type="primary"
                  text={t('adding')}
                  onClick={() => onCreate()}
                  alt={t('adding')}
                  style={{ width: '100%', height: '55px' }}
                />
              </div>
            ) : (
              <div className="users__container__right__button">
                <UIButton
                  type="primary"
                  text={t('register')}
                  onClick={() => updateUser()}
                  alt={t('register')}
                  disabled={currentSelected === undefined}
                  style={{ width: '100%', height: '55px' }}
                />
              </div>
            )}
          </div>
        </div>
      </LayoutContainer>
    </div>
  )
}

export default Users
