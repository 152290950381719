import React from 'react'
import PropTypes from 'prop-types'

const LayoutContainer = ({ children }) => (
  <div
    style={{
      width: 'calc(100% - 220px)',
      height: 'calc(100% - 75px)',
      position: 'absolute',
      top: '75px',
      left: '220px',
      backgroundColor: '#F4F4F4',
    }}
  >
    {children}
  </div>
)

LayoutContainer.propTypes = {
  children: PropTypes.node.isRequired,
}

export default LayoutContainer
