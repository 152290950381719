import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import api from '../../api'
import { UIButton, UITextInput, WidgetComeBack } from '../../components'
import './ForgotPassword.scss'

const ForgotPassword = () => {
  const { t } = useTranslation('forgotPassword')

  const [email, setEmail] = useState('')
  const [wrongLogin, setWrongLogin] = useState(false)
  const [emailSend, setEmailSend] = useState(false)
  const [disableButton, setDisableButton] = useState(false)

  const sendLoginForReset = async () => {
    setDisableButton(true)
    setTimeout(() => {
      setDisableButton(false)
    }, 3000)
    try {
      await api.sendEmailForgotPassword(email)
      setEmailSend(true)
    } catch (e) {
      setWrongLogin(true)
    }
  }

  return (
    <div className="forgot-pw" style={{ backgroundImage: 'url(images/bdn-bg-image.png)' }}>
      <div className="forgot-pw__container">
        <div className="forgot-pw__container__image">
          <img
            className="forgot-pw__container__image__logo-bdn"
            src="images/logo-BDN.png"
            alt={t('altimage')}
          />
        </div>
        <div className="forgot-pw__container__informations">
          <WidgetComeBack />

          <h1 className="forgot-pw__container__informations__title">{t('title')}</h1>
          <h2 className="forgot-pw__container__informations__text1">{t('text1')}</h2>
          <h2 className="forgot-pw__container__informations__text2">{t('text2')}</h2>

          <div className="forgot-pw__container__informations__form">
            <div className="forgot-pw__container__informations__form__email">
              <UITextInput
                type="email"
                value={email}
                onChange={(e) => {
                  setEmail(e)
                }}
                error={wrongLogin}
                placeHolder={t('placeHolder')}
                style={{ height: '100%' }}
                styleInput={{ width: '90%' }}
              />
            </div>
            <div className="forgot-pw__container__informations__form__send">
              {/* Delete this link and put de navigation in the action when success */}
              <UIButton
                type="primary"
                text={t('send')}
                onClick={() => sendLoginForReset()}
                alt="alt"
                style={{ height: '100%', width: '100%', padding: '3% 30%' }}
                disabled={disableButton}
              />
            </div>
          </div>
          {emailSend && (
            <p className="forgot-pw__container__informations__email-send">{t('sendEMail')}</p>
          )}
        </div>
      </div>
    </div>
  )
}

export default ForgotPassword
