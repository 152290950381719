import { useLazyQuery, useMutation } from '@apollo/client'
import { jwtDecode } from 'jwt-decode'
import { PropTypes } from 'prop-types'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import useOnClickOutside from 'use-onclickoutside'
import { gqlDelivery, gqlTrucks } from '../../../gql'
import { getDate, getTime } from '../../../lib/utils'
import './FollowingDeliveryLine.scss'

const FollowingDeliveryLine = (props) => {
  const { delivery, isSelected, isMinimize, onClickOpenDetails, onClickPrint } = props

  const { t } = useTranslation('followingDeliveryLine')
  const role = jwtDecode(localStorage.getItem('access_token'))?.role
  const navigate = useNavigate()

  const [currentStatus, setCurrentStatus] = useState('')
  const [openStatus, setOpenStatus] = useState(false)

  const [fetchTruck, { data: truck }] = useLazyQuery(gqlTrucks.TRUCK_BY_ID, {
    variables: {
      id: delivery.idTruck,
    },
  })

  const [fetchConstructionSite, { data: constructionSite }] = useLazyQuery(
    gqlDelivery.CONSTRUCTION_SITE_BY_ID,
    {
      variables: {
        id: delivery.idConstructionSite,
      },
    }
  )

  const [fetchSalesOrdercode, { data: salesOrderCode }] = useLazyQuery(
    gqlDelivery.SALE_ORDER_BY_ID,
    {
      variables: {
        id: delivery.idSaleOrder,
      },
    }
  )

  useEffect(() => {
    if (delivery.idSaleOrder) {
      fetchSalesOrdercode()
    }
    if (delivery.idConstructionSite) {
      fetchConstructionSite()
    }
    if (delivery.idTruck) {
      fetchTruck()
    }
  }, [delivery, fetchTruck, fetchConstructionSite, fetchSalesOrdercode])

  const [updateDeliveryStatus] = useMutation(gqlDelivery.UPDATE_DELIVERY_STATUS, {
    refetchQueries: [{ query: gqlDelivery.COUNT_TO_VALIDATE }],
  })

  const refStatusOverlay = useRef(null)
  useOnClickOutside(refStatusOverlay, () => {
    setOpenStatus(false)
  })

  const renderStatusOverlay = () => (
    <div className="status-overlay" ref={refStatusOverlay}>
      <button
        className="status-overlay__button"
        type="button"
        onClick={() => {
          setCurrentStatus('VALIDER')
          setOpenStatus(false)
          updateDeliveryStatus({
            variables: {
              id: delivery.id,
              status: 'VALIDER',
            },
          })
        }}
      >
        <div className="status-overlay__button__icon">
          <img
            className="status-overlay__button__icon__img"
            src="icons/toValidate.png"
            alt="status"
          />
        </div>
        <h2 className="status-overlay__button__text">{t('VALIDER')}</h2>
      </button>

      <button
        className="status-overlay__button"
        type="button"
        onClick={() => {
          setCurrentStatus('REDRESSER')
          setOpenStatus(false)
          updateDeliveryStatus({
            variables: {
              id: delivery.id,
              status: 'REDRESSER',
            },
          })
        }}
      >
        <div className="status-overlay__button__icon">
          <img
            className="status-overlay__button__icon__img"
            src="icons/redresser.png"
            alt="status"
          />
        </div>
        <h2 className="status-overlay__button__text">{t('REDRESSER')}</h2>
      </button>

      <button
        className="status-overlay__button"
        type="button"
        onClick={() => {
          setCurrentStatus('ANNUL_')
          setOpenStatus(false)
          updateDeliveryStatus({
            variables: {
              id: delivery.id,
              status: 'ANNUL_',
            },
          })
        }}
      >
        <div className="status-overlay__button__icon">
          <img className="status-overlay__button__icon__img" src="icons/x.png" alt="status" />
        </div>
        <h2 className="status-overlay__button__text">{t('ANNUL_')}</h2>
      </button>
    </div>
  )

  const selectStatusIcon = (status) => {
    switch (status) {
      case 'VALIDER':
        return 'icons/toValidate.png'
      case 'REDRESSER':
        return 'icons/redresser.png'
      case 'REDRESS_':
        return 'icons/redress_.png'
      case 'ANNUL_':
        return 'icons/x.png'
      case 'EN_CR_ATION':
        return 'icons/statusWaiting.png'
      default:
        return 'icons/ToValidate.png'
    }
  }

  return (
    <div className={isSelected ? 'delivery-line delivery-line__selected' : 'delivery-line'}>
      <div className="delivery-line__selec">
        {delivery.idInternalTransfert && (
          <div className="delivery-line__selec__icon">
            <img src="images/Logo-BDN.png" alt="bdn" className="delivery-line__selec__icon__img" />
          </div>
        )}
        <div
          className={
            isSelected
              ? 'delivery-line__selec__circle delivery-line__selec__circle__selected'
              : 'delivery-line__selec__circle'
          }
        />
      </div>
      <div
        className={
          isMinimize ? 'delivery-line__first delivery-line__first__mini' : 'delivery-line__first'
        }
      >
        <div className="delivery-line__first__dateNtime">
          <p className="delivery-line__first__dateNtime__date">{getDate(delivery.createdAt)}</p>
          <p className="delivery-line__first__dateNtime__time">{getTime(delivery.createdAt)}</p>
        </div>

        <h2
          className={
            delivery.idClient
              ? 'delivery-line__first__client--valid'
              : 'delivery-line__first__client'
          }
        >
          {delivery.saleOrderClientName || t('noClient')}
        </h2>
        <p className="delivery-line__first__code">
          {salesOrderCode?.salesOrder?.code || t('noSalesOrderCode')}
        </p>
        <p className="delivery-line__first__code">{delivery.externalReference || t('noExtRef')}</p>
      </div>

      <div
        className={
          isMinimize ? 'delivery-line__second delivery-line__second__mini' : 'delivery-line__second'
        }
      >
        <h2 className="delivery-line__second__adress">
          {constructionSite?.constructionSite?.address || delivery.constructionSiteAddress}
        </h2>
        <h2 className="delivery-line__second__city">
          {constructionSite?.constructionSite?.city || delivery.constructionSiteCity}
        </h2>
        <h2 className="delivery-line__second__imatriculation">
          {truck?.truck?.licencePlate || delivery?.truckLicencePlate}
        </h2>
      </div>

      {!isMinimize && (
        <div className="delivery-line__third">
          {delivery?.draftOutboundDeliveryItemsByIdDraftOutboundDelivery.nodes.map(
            (material, index) => (
              <div key={index} className="delivery-line__third__material">
                <h2 className="delivery-line__third__material__name">
                  {material.materialByIdMaterial?.description || material.name}
                </h2>
                <h2 className="delivery-line__third__material__weight">
                  {material?.quantity?.toFixed(3) || material?.volume?.toFixed(3)}
                </h2>
                <h2 className="delivery-line__third__material__unit">
                  {t(material.materialByIdMaterial?.baseMeasureUnitCode || material.unit)}
                </h2>
              </div>
            )
          )}
        </div>
      )}

      <div
        className={
          isMinimize ? 'delivery-line__fourth delivery-line__fourth__mini' : 'delivery-line__fourth'
        }
      >
        <div
          className={
            isMinimize
              ? 'delivery-line__fourth__status'
              : ' delivery-line__fourth__status delivery-line__fourth__status__large'
          }
        >
          <button
            className={
              isMinimize
                ? 'delivery-line__fourth__button delivery-line__fourth__button__mini'
                : 'delivery-line__fourth__button'
            }
            type="button"
            onClick={() => setOpenStatus(true)}
          >
            <div
              className={
                isMinimize
                  ? 'delivery-line__fourth__button__icon delivery-line__fourth__button__icon__mini'
                  : 'delivery-line__fourth__button__icon'
              }
            >
              <img
                className="delivery-line__fourth__button__icon__img"
                src={selectStatusIcon(currentStatus || delivery.status)}
                alt="status"
              />
            </div>
            {!isMinimize && (
              <h2 className="delivery-line__fourth__button__text">
                {t(`${currentStatus || delivery.status}`)}
              </h2>
            )}
          </button>
        </div>

        <button
          className={
            isMinimize
              ? 'delivery-line__fourth__button delivery-line__fourth__button__mini'
              : 'delivery-line__fourth__button'
          }
          type="button"
          onClick={onClickOpenDetails}
        >
          <div
            className={
              isMinimize
                ? 'delivery-line__fourth__button__icon delivery-line__fourth__button__icon__mini'
                : 'delivery-line__fourth__button__icon'
            }
          >
            <img
              className="delivery-line__fourth__button__icon__img"
              src={isMinimize && isSelected ? 'icons/close-mini.png' : 'icons/eye.png'}
              alt="visualize"
            />
          </div>
          {!isMinimize && <h2 className="delivery-line__fourth__button__text">{t('visualize')}</h2>}
        </button>

        {role !== 'bdn_on_site_assistant' && (
          <button
            className={
              isMinimize
                ? 'delivery-line__fourth__button delivery-line__fourth__button__mini'
                : 'delivery-line__fourth__button'
            }
            type="button"
            onClick={() => {
              localStorage.setItem('delivery_duplicate', JSON.stringify(delivery))
              navigate(
                `/new-delivery?duplicate=true&licensePlate=${
                  truck?.truck?.licencePlate || delivery?.truckLicencePlate
                }`
              )
            }}
          >
            <div
              className={
                isMinimize
                  ? 'delivery-line__fourth__button__icon delivery-line__fourth__button__icon__mini'
                  : 'delivery-line__fourth__button__icon'
              }
            >
              <img
                className="delivery-line__fourth__button__icon__img"
                src="icons/duplicate.png"
                alt="duplicate"
              />
            </div>
            {!isMinimize && (
              <h2 className="delivery-line__fourth__button__text">{t('duplicate')}</h2>
            )}
          </button>
        )}

        <button
          className={
            isMinimize
              ? 'delivery-line__fourth__button delivery-line__fourth__button__mini'
              : 'delivery-line__fourth__button'
          }
          type="button"
          onClick={onClickPrint}
        >
          <div
            className={
              isMinimize
                ? 'delivery-line__fourth__button__icon delivery-line__fourth__button__icon__mini'
                : 'delivery-line__fourth__button__icon'
            }
          >
            <img
              className="delivery-line__fourth__button__icon__img"
              src="icons/printer.svg"
              alt="visualize"
            />
          </div>
          {!isMinimize && <h2 className="delivery-line__fourth__button__text">{t('print')}</h2>}
        </button>
      </div>
      {openStatus &&
        delivery.status !== 'REDRESS_' &&
        delivery.status !== 'EN_CR_ATION' &&
        renderStatusOverlay()}
    </div>
  )
}

FollowingDeliveryLine.propTypes = {
  delivery: PropTypes.shape({
    idInternalTransfert: PropTypes.string,
    status: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    idClient: PropTypes.string,
    idConstructionSite: PropTypes.string,
    idSaleOrder: PropTypes.string,
    idTruck: PropTypes.string,
    createdAt: PropTypes.string.isRequired,
    externalReference: PropTypes.string,
    constructionSiteAddress: PropTypes.string,
    constructionSiteCity: PropTypes.string,
    saleOrderClientName: PropTypes.string,
    truckLicencePlate: PropTypes.string,
    draftOutboundDeliveryItemsByIdDraftOutboundDelivery: PropTypes.shape({
      nodes: PropTypes.arrayOf(
        PropTypes.shape({
          materialByIdMaterial: PropTypes.shape({
            description: PropTypes.string,
            baseMeasureUnitCode: PropTypes.string,
          }),
          name: PropTypes.string,
          quantity: PropTypes.number,
          volume: PropTypes.number,
          unit: PropTypes.string,
        })
      ),
    }),
  }).isRequired,
  isSelected: PropTypes.bool.isRequired,
  isMinimize: PropTypes.bool,
  onClickOpenDetails: PropTypes.func.isRequired,
  onClickPrint: PropTypes.func.isRequired,
}

FollowingDeliveryLine.defaultProps = {
  isMinimize: false,
}

export default FollowingDeliveryLine
