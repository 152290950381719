/* eslint-disable no-nested-ternary */
import { PropTypes } from 'prop-types'
import React from 'react'
import './UITextInput.scss'

const UITextInput = (props) => {
  const {
    id,
    type,
    value,
    onChange,
    autoFocus,
    error,
    success,
    isRequired,
    prefix,
    unit,
    placeHolder,
    disabled,
    shiny,
    style,
    styleContainer,
    styleInput,
    stylePrefix,
  } = props

  const inputTextInput = `uiInput_field
  ${success ? ' uiInput_field--success' : ''}
  ${error ? ' uiInput_field--error' : ''}
  ${shiny ? ' uiInput_field--shiny' : ''}
  `

  return (
    <div className="uiInput" style={style}>
      {prefix && (
        <h2 className="uiInput_prefix" style={stylePrefix}>
          {isRequired ? `${prefix} *:` : `${prefix} :`}
        </h2>
      )}
      <div className="uiInput_container" style={styleContainer}>
        <input
          className={inputTextInput}
          style={styleInput}
          id={id}
          type={type}
          value={value}
          onChange={(e) => {
            onChange(e.target.value)
          }}
          autoFocus={autoFocus}
          placeholder={placeHolder}
          readOnly={disabled}
        />
        {unit && (
          <div className="uiInput_containerUnit">
            <h2 className="uiInput_unit">{unit}</h2>
          </div>
        )}
      </div>
    </div>
  )
}

UITextInput.propTypes = {
  id: PropTypes.string,
  type: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  autoFocus: PropTypes.bool,
  error: PropTypes.bool,
  success: PropTypes.bool,
  isRequired: PropTypes.bool,
  prefix: PropTypes.string,
  unit: PropTypes.string,
  placeHolder: PropTypes.string,
  disabled: PropTypes.bool,
  shiny: PropTypes.bool,
  style: PropTypes.shape({}),
  styleContainer: PropTypes.shape({}),
  styleInput: PropTypes.shape({}),
  stylePrefix: PropTypes.shape({}),
  styleAutoComplete: PropTypes.shape({}),
}

UITextInput.defaultProps = {
  id: null,
  onChange: null,
  autoFocus: false,
  error: false,
  success: false,
  isRequired: false,
  prefix: null,
  unit: null,
  placeHolder: null,
  disabled: false,
  shiny: false,
  style: null,
  styleContainer: null,
  styleInput: null,
  stylePrefix: null,
  styleAutoComplete: null,
}

export default UITextInput
