import { PropTypes } from 'prop-types'
import React from 'react'
import './TrucksGrid.scss'

import Truck from '../Truck/Truck.js'

const TrucksGrid = (props) => {
  const { trucks, truckGone, selected, onChangeSelected, refreshData } = props

  return (
    <div className="trucks-grid">
      {trucks?.map((truck, index) => (
        <button
          key={index}
          className="trucks-grid__truck"
          type="button"
          onClick={() => onChangeSelected(index)}
        >
          <Truck
            refreshData={refreshData}
            truck={truck}
            isSelected={selected === index}
            truckGone={truckGone}
          />
        </button>
      ))}
    </div>
  )
}

TrucksGrid.propTypes = {
  trucks: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  truckGone: PropTypes.func.isRequired,
  selected: PropTypes.number.isRequired,
  onChangeSelected: PropTypes.func.isRequired,
  refreshData: PropTypes.func.isRequired,
}

export default TrucksGrid
