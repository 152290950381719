import React from 'react'
import { PropTypes } from 'prop-types'
import './UIDropDown.scss'
import 'react-dropdown/style.css'

const UIDropDown = (props) => {
  const { options, defaultValue, onChange, disabled, type, style, placeholder } = props

  return (
    <div style={style}>
      <label className="drop-down" htmlFor="slct">
        <select className="select" id="slct" onChange={onChange} disabled={disabled}>
          {placeholder && (
            <option value="" selected>
              {placeholder}
            </option>
          )}
          {options.map((item) => (
            <option
              key={item.id}
              className="select__options"
              value={item.id}
              selected={item.id === defaultValue}
            >
              {type === 'sites'
                ? `${item.name} - ${item.code}`
                : item.displayName || item.name || item.lastname}
            </option>
          ))}
        </select>
        <svg>
          <use xlinkHref="#select-arrow-down" />
        </svg>
      </label>

      <svg className="sprites">
        <symbol id="select-arrow-down" viewBox="0 0 10 6">
          <polyline points="1 1 5 5 9 1" />
        </symbol>
      </svg>
    </div>
  )
}

UIDropDown.propTypes = {
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  defaultValue: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  type: PropTypes.string,
  style: PropTypes.shape({}),
  placeholder: PropTypes.string,
}

UIDropDown.defaultProps = {
  defaultValue: null,
  disabled: false,
  style: null,
  type: '',
  placeholder: '',
}

export default UIDropDown
