import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import { jwtDecode } from 'jwt-decode'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  ConfirmPopIn,
  Header,
  LayoutContainer,
  NavBar,
  TrucksGrid,
  UIDropDown,
  UISearchBar,
} from '../../components'
import { gqlTrucks, gqlUsers } from '../../gql'
import { isAuthorized } from '../../lib/helpers'
import './TrucksOnSite.scss'

const TrucksOnSite = () => {
  const { t } = useTranslation('trucks')
  const accessToken = localStorage.getItem('access_token')
  const role = jwtDecode(accessToken)?.role

  // states --------------------------------------------------------------------
  const [search, setSearch] = useState('')
  const [truckGone, setTruckGone] = useState(false)
  const [selected, setSelected] = useState()
  const [sortSite, setSortSite] = useState()
  const [trucksOnSite, setTrucksOnSite] = useState([])
  const [truckId, setTruckId] = useState()

  // graphql hooks -------------------------------------------------------------
  const { data: sites } = useQuery(gqlUsers.SITES)

  const [fetchTruckOnSite] = useLazyQuery(gqlTrucks.TRUCKS_ON_SITE, {
    fetchPolicy: 'cache-and-network',
    onCompleted: (response) => {
      let array = []
      if (response?.trucks?.nodes[0]) {
        response?.trucks?.nodes?.forEach((truck) => {
          fetchTruckWeighing({
            variables: { id: truck.id },
          }).then((response2) => {
            array.push({
              truck,
              weighings: response2?.data?.weighings?.nodes[0] || {},
            })
            if (array.length === response?.trucks?.nodes.length) {
              setTrucksOnSite(array)
            }
          })
        })
      } else {
        setTrucksOnSite([])
      }
    },
  })

  const [truckApproved] = useMutation(gqlTrucks.UPDATE_APPROVED_WEIGHT, {
    refetchQueries: [{ query: gqlTrucks.WEIGHINGS_NO_APPROVED }],
    onCompleted: () => {
      fetchTruckOnSite({ variables: { idSite: sortSite } })
    },
    optimisticResponse: {
      truckApproved: {
        clientMutationId: null,
      },
    },
  })

  const [fetchTruckWeighing] = useLazyQuery(gqlTrucks.WEIGHING_BY_ID_TRUCK_AND_FALSE, {
    fetchPolicy: 'cache-and-network',
  })

  const { data: user } = useQuery(gqlUsers.USER, {
    variables: { id: jwtDecode(accessToken)?.id },
    onCompleted: () => {
      setSortSite(user?.user?.idSite)
      if (!isAuthorized(role, 'bdn_admin')) {
        fetchTruckOnSite({ variables: { idSite: user?.user?.idSite } })
      }
    },
  })

  const [truckLeave] = useMutation(gqlTrucks.UPDATE_TRUCK_NO_SITE, {
    onCompleted: () => {
      fetchTruckWeighing({
        variables: { id: truckId },
        onCompleted: (response) => {
          truckApproved({ variables: { id: response?.weighings?.nodes[0]?.id } })
        },
      })
    },
  })

  const truckApproving = () => {
    truckLeave({ variables: { id: truckId } })
  }

  useEffect(() => {
    if (sortSite) {
      fetchTruckOnSite({ variables: { idSite: sortSite } })
    }
  }, [sortSite])

  const refreshData = () => {
    fetchTruckOnSite({ variables: { idSite: sortSite } })
  }

  return (
    <div className="trucks">
      <Header title={t('title')} />
      <NavBar active="trucks" />
      {truckGone && (
        <ConfirmPopIn
          text={t('truckgone')}
          back={() => setTruckGone(false)}
          confirm={() => {
            truckApproving()
            setTruckGone(false)
          }}
        />
      )}
      <LayoutContainer>
        <div className="trucks__container">
          <div className="trucks__container__header">
            <div className="trucks__container__header__search-bar">
              <UISearchBar
                value={search}
                onChange={(e) => setSearch(e)}
                disable={true}
                styleInput={{ paddingLeft: '35px', backgroundColor: '#F4F4F4', border: '#F4F4F4' }}
              />
            </div>
            {isAuthorized(role, 'bdn_admin') && sites?.sites?.nodes && (
              <div>
                <UIDropDown
                  options={sites?.sites?.nodes}
                  onChange={(e) => setSortSite(e.target.value)}
                  value={sortSite}
                  style={{ width: '250px' }}
                />
              </div>
            )}
          </div>
          <div className="trucks__container__header__colLabels">
            <p className="trucks__container__header__colLabels__licensePlate">
              {t('headerLicensePlate')}
            </p>
            <p className="trucks__container__header__colLabels__emptyWeight">{t('headerEmpty')}</p>
            <p className="trucks__container__header__colLabels__creationDate">{t('headerDate')}</p>
            <p className="trucks__container__header__colLabels__creationTime">{t('headerTime')}</p>
            <p className="trucks__container__header__colLabels__weights">{t('weights')}</p>
          </div>
          {trucksOnSite[0] ? (
            <div className="trucks__container__body">
              <TrucksGrid
                trucks={trucksOnSite || []}
                truckGone={() => setTruckGone(true)}
                refreshData={refreshData}
                selected={selected}
                onChangeSelected={(index) => {
                  setSelected(index)
                  setTruckId(trucksOnSite[index]?.truck?.id)
                }}
              />
            </div>
          ) : (
            <div className="trucks__container__no_truck">
              <p className="trucks__container__no_truck__text">{t('noTruck')}</p>
            </div>
          )}
        </div>
      </LayoutContainer>
    </div>
  )
}

export default TrucksOnSite
