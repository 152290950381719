import { useMutation } from '@apollo/client'
import { PropTypes } from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { UIButton, UITextInput } from '../..'
import { gqlTrucks } from '../../../gql'
import { captureMessageWithAttachment } from '../../../lib/sentry'
import './TruckModif.scss'

const TruckModif = (props) => {
  const { truck, weighings, onClickBack, onClickRegister, refreshData } = props

  // states --------------------------------------------------------------------
  const [emptyWeight, setEmptyWeight] = useState(null)
  const [tareWeight, setTareWeight] = useState(null)
  const [rubbleWeight, setRubbleWeight] = useState(null)
  const [materialsWeight, setMaterialsWeight] = useState(null)

  const { t } = useTranslation('trucks')

  // graphql hooks -------------------------------------------------------------
  const [updateUnloadedWeight] = useMutation(gqlTrucks.UPDATE_UNLOADED_WEIGHT, {
    optimisticResponse: {
      updateUnloadedWeight: {
        clientMutationId: null,
        device: {
          id: truck?.id,
          unloadedWeight: parseFloat(emptyWeight),
          __typename: 'Truck',
        },
      },
    },
    onError: (err) => {
      const request = gqlTrucks.UPDATE_UNLOADED_WEIGHT
      const event = {
        request: request?.definitions[0]?.name?.value,
        variables: {
          id: truck?.id,
          licenceplate: truck?.licencePlate,
        },
      }
      captureMessageWithAttachment(event, err)
    },
  })

  const [updateWeightMaterials] = useMutation(gqlTrucks.UPDATE_WEIGHT_TRUCK, {
    optimisticResponse: {
      updateWeightMaterials: {
        clientMutationId: null,
        device: {
          id: weighings.id,
          weightMaterials: parseFloat(materialsWeight),
          weightRubble: parseFloat(rubbleWeight),
          weightTare: parseFloat(tareWeight),
          __typename: 'Weighing',
        },
        __typename: 'WeighingsConnection',
      },
    },
  })

  // functions -----------------------------------------------------------------
  const register = () => {
    let promises = []
    if (emptyWeight !== truck?.unloadedWeight) {
      promises.push(
        updateUnloadedWeight({
          variables: {
            id: truck?.id,
            unloadedWeight: parseFloat(emptyWeight),
          },
        })
      )
    }

    if (
      tareWeight !== weighings?.weightTare ||
      rubbleWeight !== weighings?.weightRubble ||
      materialsWeight !== weighings?.weightMaterials
    ) {
      promises.push(
        updateWeightMaterials({
          variables: {
            id: weighings.id,
            weightMaterials: parseFloat(materialsWeight),
            weightRubble: parseFloat(rubbleWeight),
            weightTare: parseFloat(tareWeight),
          },
        })
      )
    }
    Promise.all(promises).then(() => {
      refreshData()
    })
  }

  useEffect(() => {
    setEmptyWeight(truck?.unloadedWeight)
    setTareWeight(weighings?.weightTare)
    setRubbleWeight(weighings?.weightRubble)
    setMaterialsWeight(weighings?.weightMaterials)
  }, [truck, weighings])

  return (
    <div className="truck-modif">
      <h1 className="truck-modif__title">
        {t('modifWeightOverlayTitle')} - {truck?.licencePlate}
      </h1>
      <div className="truck-modif__form">
        <div className="truck-modif__form__line">
          <UITextInput
            type="number"
            step="0.001"
            value={emptyWeight}
            onChange={(e) => setEmptyWeight(e)}
            prefix={t('headerEmpty')}
            unit={t('unit')}
            styleInput={{ width: '130px' }}
            stylePrefix={{ width: '44%' }}
            styleContainer={{ width: '40%' }}
          />
          <p>
            {'('}
            {truck?.unloadedWeight}
            {')'}
          </p>
        </div>

        <div className="truck-modif__form__line">
          <UITextInput
            type="number"
            step="0.001"
            value={tareWeight}
            onChange={(e) => setTareWeight(e)}
            prefix={t('tare')}
            unit={t('unit')}
            styleInput={{ width: '130px' }}
            stylePrefix={{ width: '44%' }}
            styleContainer={{ width: '40%' }}
          />
          <p>{`(${weighings?.weightTare})`}</p>
        </div>

        <div className="truck-modif__form__line">
          <UITextInput
            type="number"
            step="0.001"
            value={rubbleWeight}
            onChange={(e) => setRubbleWeight(e)}
            prefix={t('rubble')}
            unit={t('unit')}
            styleInput={{ width: '130px' }}
            stylePrefix={{ width: '44%' }}
            styleContainer={{ width: '40%' }}
          />
          <p>
            {'('}
            {weighings?.weightRubble}
            {')'}
          </p>
        </div>

        <div className="truck-modif__form__line">
          <UITextInput
            type="number"
            step="0.001"
            value={materialsWeight}
            onChange={(e) => setMaterialsWeight(e)}
            prefix={t('materials')}
            unit={t('unit')}
            styleInput={{ width: '130px' }}
            stylePrefix={{ width: '44%' }}
            styleContainer={{ width: '40%' }}
          />
          <p>
            {'('}
            {weighings?.weightMaterials}
            {')'}
          </p>
        </div>
      </div>

      <div className="truck-modif__buttons">
        <UIButton type="secondary" text={t('back')} onClick={onClickBack} alt={t('back')} />

        <UIButton
          type="primary"
          text={t('register')}
          onClick={() => {
            register()
            onClickRegister()
          }}
          alt={t('register')}
        />
      </div>
    </div>
  )
}

TruckModif.propTypes = {
  truck: PropTypes.shape({
    id: PropTypes.string.isRequired,
    licencePlate: PropTypes.string.isRequired,
    unloadedWeight: PropTypes.number.isRequired,
  }).isRequired,
  weighings: PropTypes.objectOf(PropTypes.string).isRequired,
  onClickBack: PropTypes.func.isRequired,
  onClickRegister: PropTypes.func.isRequired,
  refreshData: PropTypes.func.isRequired,
}

export default TruckModif
