/* eslint-disable no-nested-ternary */
import { PropTypes } from 'prop-types'
import React, { useEffect, useRef, useState } from 'react'
import useOnClickOutside from 'use-onclickoutside'
import { WidgetAutoCompleteList } from '../..'
import '../UITextInput/UITextInput.scss'

const UITextInputAutoCompletion = (props, { close }) => {
  const {
    id,
    type,
    value,
    valueId,
    onChange,
    autoFocus,
    error,
    success,
    isRequired,
    prefix,
    unit,
    setUnit,
    placeHolder,
    disabled,
    shiny,
    autoCompleteList,
    autoCompleteType,
    style,
    styleInput,
    stylePrefix,
    styleAutoComplete,
  } = props

  const [autoComplete, setAutoComplete] = useState(false)
  const [closeAutocomplete, setCloseAutoComplete] = useState(false)

  const inputRef = useRef(null)

  const ref = useRef(null)
  useOnClickOutside(ref, close)

  let list = null

  // -----Autocomplete for Clients-----
  if (autoCompleteList && autoCompleteList.clients) {
    const ddata = []
    for (let i = 0; i < autoCompleteList.clients.edges.length; i++) {
      ddata.push(autoCompleteList.clients.edges[i].node.displayName)
    }

    list = autoCompleteList.clients.edges
      .filter((item) => item?.node?.displayName?.toLowerCase().includes(value?.toLowerCase()))
      .slice(0, 20)
  }

  // -----Autocomplete for freightForwarders-----
  if (autoCompleteList && autoCompleteList.freightForwarders) {
    const ddata = []
    for (let i = 0; i < autoCompleteList.freightForwarders.nodes.length; i++) {
      ddata.push(autoCompleteList.freightForwarders.nodes[i].displayName)
    }

    list = autoCompleteList.freightForwarders.nodes
      .filter((item) => item?.displayName?.toLowerCase().includes(value?.toLowerCase()))
      .slice(0, 20)
  }

  // -----Autocomplete for Materials-----
  if (autoCompleteList && autoCompleteList.materials) {
    const ddata = []
    for (let i = 0; i < autoCompleteList.materials.nodes.length; i++) {
      ddata.push(autoCompleteList.materials.nodes[i].description)
    }

    list = autoCompleteList.materials.nodes
      .filter((item) => item?.description?.toLowerCase().includes(value?.toLowerCase()))
      .slice(0, 20)
  }

  useEffect(() => {
    if (value && !closeAutocomplete && document.activeElement === inputRef.current) {
      setAutoComplete(true)
    } else {
      setAutoComplete(false)
      setCloseAutoComplete(false)
    }
  }, [value, closeAutocomplete])

  const inputTextInput = `uiInput_field
  ${success ? ' uiInput_field--success' : ''}
  ${error ? ' uiInput_field--error' : ''}
  ${shiny ? ' uiInput_field--shiny' : ''}
  `

  return (
    <div className="uiInput" style={style}>
      {prefix && (
        <h2 className="uiInput_prefix" style={stylePrefix}>
          {isRequired ? `${prefix} *:` : `${prefix} :`}
        </h2>
      )}
      {autoComplete && autoCompleteList && list.length > 0 && (
        <div className="uiInput_autocomplete">
          <WidgetAutoCompleteList
            type={autoCompleteType}
            list={list}
            select={(content) => {
              if (autoCompleteList.freightForwarders) {
                onChange(content.displayName)
                valueId(content.id)
              } else if (autoCompleteList.clients) {
                onChange(content.node.displayName)
                valueId(content.node.id)
              } else if (autoCompleteList.materials && shiny) {
                onChange(content.description)
                valueId(content.id)
                setUnit(content.baseMeasureUnitCode)
              } else if (autoCompleteList.materials && !shiny) {
                onChange(content)
              }
              setAutoComplete(false)
              setCloseAutoComplete(true)
            }}
            style={styleAutoComplete}
          />
        </div>
      )}

      <input
        className={inputTextInput}
        id={id}
        type={type}
        value={value}
        ref={inputRef}
        onChange={(e) => {
          onChange(e.target.value)
        }}
        autoFocus={autoFocus}
        placeholder={placeHolder}
        readOnly={disabled}
        style={styleInput}
      />

      {unit && (
        <div className="uiInput_containerUnit">
          <h2 className="uiInput_unit">{unit}</h2>
        </div>
      )}
    </div>
  )
}

UITextInputAutoCompletion.propTypes = {
  id: PropTypes.string,
  type: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  valueId: PropTypes.string,
  onChange: PropTypes.func,
  autoFocus: PropTypes.bool,
  error: PropTypes.bool,
  success: PropTypes.bool,
  isRequired: PropTypes.bool,
  prefix: PropTypes.string,
  unit: PropTypes.string,
  setUnit: PropTypes.func,
  placeHolder: PropTypes.string,
  disabled: PropTypes.bool,
  shiny: PropTypes.bool,
  autoCompleteList: PropTypes.arrayOf(PropTypes.string),
  autoCompleteType: PropTypes.string,
  style: PropTypes.shape({}),
  styleInput: PropTypes.shape({}),
  stylePrefix: PropTypes.shape({}),
  styleAutoComplete: PropTypes.shape({}),
}

UITextInputAutoCompletion.defaultProps = {
  id: null,
  valueId: null,
  onChange: null,
  autoFocus: false,
  error: false,
  success: false,
  isRequired: false,
  prefix: null,
  unit: null,
  setUnit: null,
  placeHolder: null,
  disabled: false,
  shiny: false,
  autoCompleteList: null,
  autoCompleteType: null,
  style: null,
  styleInput: null,
  stylePrefix: null,
  styleAutoComplete: null,
}

export default UITextInputAutoCompletion
