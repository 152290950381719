import axios from 'axios'
import config from '../config'
import { arrayBufferToDataUri, isUserTokenExpired } from '../lib/helpers'

const api = axios.create({
  baseURL: config.API_BASE_URL,
})

const post = async (path, data = {}, headers = {}) => {
  const res = await api({
    method: 'post',
    url: path,
    data,
    headers,
  })
  return res.data
}

const put = async (path, data = {}, headers = {}) => {
  const res = await api({
    method: 'put',
    url: path,
    data,
    headers,
  })
  return res.data
}

const get = async (path, params = {}, headers = {}) => {
  const res = await api({
    method: 'get',
    url: path,
    params,
    headers,
  })
  return res.data
}

const uploadFile = async (idDelivery, files, token, label) => {
  const formData = new FormData()
  formData.append(label, files)
  const url = `${config.API_BASE_URL}outbound_deliveries/${idDelivery}/signature`
  const response = await axios.post(url, formData, {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'multipart/form-data',
    },
  })
  return response
}

const getSignature = (deliveryId, token, onready) => {
  const url = `${config.API_BASE_URL}outbound_deliveries/${deliveryId}/signature`
  const req = new XMLHttpRequest()
  req.open('GET', url, true)
  req.setRequestHeader('Authorization', `bearer ${token}`)
  req.responseType = 'arraybuffer'
  req.onload = function () {
    onready(arrayBufferToDataUri(req.response))
  }
  req.send(null)
}

const sendDeliveryToSAP = async (id) => {
  const response = await post('/outbound_deliveries', { id })
  return response
}

const createSalesOrderSAP = async (id) => {
  const response = await post('/sales_order', { id })
  return response
}

const exportDeliveries = async (startDate, endDate) => {
  const headers = {
    responseType: 'blob',
  }
  const response = await get(
    `/exports/drafts?from_date=${startDate}&to_date=${endDate}`,
    {},
    headers
  )
  return response
}

const postFilePrices = async (file, token) => {
  const formData = new FormData()
  formData.append('csv', file)
  const response = await axios.post(`${config.API_BASE_URL}imports/prices`, formData, {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'multipart/form-data',
    },
  })
  return response
}

const getWithHeader = async (path, refreshToken) => {
  const conf = {
    method: 'get',
    url: path,
    headers: {
      Authorization: `bearer ${refreshToken}`,
      Accept: 'application/json',
    },
  }
  const res = await api(conf)
  return res.data
}

const refreshAccessToken = async (token) => {
  try {
    return await getWithHeader('/token', token)
  } catch (error) {
    console.log(error)
  }
}

const submitCredentials = async (login, password) => {
  const response = await post('/token', { login, password })
  return response
}

const sendEmailForgotPassword = async (login) => {
  const response = await post('/password/forgot', { login })
  return response
}

const sendNewPassword = async (password, token) => {
  const headers = {
    Authorization: `bearer ${token}`,
    Accept: 'application/json',
  }

  const response = await put('/password', { password }, headers)
  return response
}

const requestWithAuthorization = (request, token) => ({
  ...request,
  headers: {
    ...request.headers,
    Authorization: `bearer ${token}`,
  },
})

api.interceptors.request.use(
  (cfg) => {
    const originalRequest = cfg
    const isLogin = cfg.url.indexOf('/token') !== -1

    const accessToken = localStorage.getItem('access_token')
    const refreshToken = localStorage.getItem('refresh_token')
    if (accessToken && !isLogin) {
      if (isUserTokenExpired(accessToken)) {
        return refreshAccessToken(refreshToken).then(
          (user) => {
            localStorage.setItem('access_token', user.access_token)
            localStorage.setItem('refresh_token', user.refresh_token)
            return Promise.resolve(requestWithAuthorization(originalRequest, user.access_token))
          },
          () => Promise.reject(new Error('E_REFRESH_TOKEN_FAILED'))
        )
      }
    }
    let token = null
    if (accessToken) {
      token = cfg.method === 'get' && isLogin ? refreshToken : accessToken
    }
    if (token) {
      return requestWithAuthorization(cfg, token)
    }
    return cfg
  },
  (error) => Promise.reject(error)
)

export default {
  get,
  post,
  uploadFile,
  getSignature,
  sendDeliveryToSAP,
  createSalesOrderSAP,
  exportDeliveries,
  submitCredentials,
  refreshAccessToken,
  sendEmailForgotPassword,
  sendNewPassword,
  postFilePrices,
}
