import { gql } from '@apollo/client'

const SALES_ORDER_BY_CODE = gql`
  query salesOrderByCode($code: String!) {
    salesOrderByCode(code: $code) {
      id
      idClient
      idSite
      code
      externalReference
      createdAt
      siteByIdSite {
        name
        id
        code
      }
      clientByIdClient {
        displayName
        id
      }
    }
  }
`

const SALE_ORDER_BY_ID = gql`
  query salesOrder($id: UUID!) {
    salesOrder(id: $id) {
      id
      code
      idClient
      idSite
      externalReference
      clientByIdClient {
        displayName
      }
    }
  }
`

const DELIVERIES = gql`
  query draftOutboundDeliveries(
    $first: Int = 20
    $offset: Int = 0
    $start: Datetime!
    $end: Datetime!
    $idSite: String = ""
    $client: String = ""
    $idFreightForwarder: String
    $status: OutboundDeliveryStatusFilter
  ) {
    draftOutboundDeliveries(
      offset: $offset
      first: $first
      filter: {
        shippingPeriod: {
          overlaps: {
            start: { value: $start, inclusive: true }
            end: { value: $end, inclusive: true }
          }
        }
        and: {
          idSite: { includes: $idSite }
          and: {
            saleOrderClientName: { includes: $client }
            and: { status: $status, and: { idFreightForwarder: { includes: $idFreightForwarder } } }
          }
        }
      }
      orderBy: SHIPPING_PERIOD_DESC
    ) {
      nodes {
        id
        createdAt
        idConstructionSite
        idSaleOrder
        idTruck
        externalReference
        code
        saleOrderClientName
        idClient
        idSite
        constructionSiteAddress
        constructionSiteCity
        truckLicencePlate
        idInternalTransfert
        idFreightForwarder
        forwardingAgent
        destinationSite
        status
        draftOutboundDeliveryItemsByIdDraftOutboundDelivery {
          totalCount
          nodes {
            idMaterial
            id
            materialByIdMaterial {
              description
              detail
              baseMeasureUnitCode
            }
            quantity
          }
        }
      }
      totalCount
      pageInfo {
        hasNextPage
      }
    }
  }
`

const DELIVERY_BY_ID = gql`
  query draftOutboundDelivery($id: UUID!) {
    draftOutboundDelivery(id: $id) {
      id
      createdAt
      idConstructionSite
      idSaleOrder
      idTruck
      externalReference
      code
      saleOrderClientName
      idClient
      idSite
      constructionSiteAddress
      constructionSiteCity
      truckLicencePlate
      idInternalTransfert
      idFreightForwarder
      forwardingAgent
      destinationSite
      status
      draftOutboundDeliveryItemsByIdDraftOutboundDelivery {
        totalCount
        nodes {
          idMaterial
          id
          materialByIdMaterial {
            description
            detail
            baseMeasureUnitCode
          }
          quantity
        }
      }
    }
  }
`

const COUNT_TODAY_DELIVERY = gql`
  query countTodayDelivery($start: Datetime!, $end: Datetime!) {
    draftOutboundDeliveries(
      filter: {
        shippingPeriod: {
          overlaps: {
            start: { value: $start, inclusive: true }
            end: { value: $end, inclusive: true }
          }
        }
      }
    ) {
      totalCount
    }
  }
`

const CONSTRUCTION_SITE_BY_CODE = gql`
  query constructionSiteByCode($code: String!) {
    constructionSiteByCode(code: $code) {
      city
      code
      address
      id
    }
  }
`

const CONSTRUCTION_SITE_BY_ID = gql`
  query constructionSite($id: UUID!) {
    constructionSite(id: $id) {
      address
      city
      code
    }
  }
`

const CREATE_DELIVERY = gql`
  mutation createDraftOutboundDelivery(
    $value: Datetime!
    $idTruck: String
    $code: String
    $idSaleOrder: String
    $idConstructionSite: String
    $externalReference: String
    $idSite: String
    $saleOrderClientName: String
    $constructionSiteAddress: String
    $constructionSiteCity: String
    $idInternalTransfert: String
    $truckLicencePlate: String
    $idClient: String
    $forwardingAgent: String
    $destinationSite: String
    $idFreightForwarder: String
  ) {
    createDraftOutboundDelivery(
      input: {
        draftOutboundDelivery: {
          code: $code
          idSaleOrder: $idSaleOrder
          idConstructionSite: $idConstructionSite
          idTruck: $idTruck
          externalReference: $externalReference
          idSite: $idSite
          saleOrderClientName: $saleOrderClientName
          constructionSiteAddress: $constructionSiteAddress
          constructionSiteCity: $constructionSiteCity
          shippingPeriod: {
            start: { value: $value, inclusive: true }
            end: { value: $value, inclusive: true }
          }
          idInternalTransfert: $idInternalTransfert
          truckLicencePlate: $truckLicencePlate
          idClient: $idClient
          forwardingAgent: $forwardingAgent
          destinationSite: $destinationSite
          idFreightForwarder: $idFreightForwarder
        }
      }
    ) {
      clientMutationId
      draftOutboundDelivery {
        id
      }
    }
  }
`

const CREATE_DELIVERY_ITEM = gql`
  mutation createDeliveryItem(
    $idDraftOutboundDelivery: UUID!
    $idMaterial: UUID!
    $quantity: Float!
  ) {
    createDraftOutboundDeliveryItem(
      input: {
        draftOutboundDeliveryItem: {
          idDraftOutboundDelivery: $idDraftOutboundDelivery
          idMaterial: $idMaterial
          quantity: $quantity
        }
      }
    ) {
      clientMutationId
    }
  }
`

const CREATE_CONSTRUCTION_SITE = gql`
  mutation createConstructionSite($id: UUID!, $code: String!, $address: String, $city: String) {
    createConstructionSite(
      input: { constructionSite: { id: $id, code: $code, address: $address, city: $city } }
    ) {
      clientMutationId
      constructionSite {
        id
      }
    }
  }
`

const UPDATE_DELIVERY = gql`
  mutation updateDraftOutboundDelivery(
    $id: UUID!
    $externalReference: String
    $idConstructionSite: String
    $idTruck: String
    $idSaleOrder: String
    $constructionSiteAddress: String
    $constructionSiteCity: String
    $saleOrderClientName: String
    $truckLicencePlate: String
    $idClient: String
    $forwardingAgent: String
    $idFreightForwarder: String
    $destinationSite: String
  ) {
    updateDraftOutboundDelivery(
      input: {
        patch: {
          externalReference: $externalReference
          idConstructionSite: $idConstructionSite
          idSaleOrder: $idSaleOrder
          idTruck: $idTruck
          constructionSiteAddress: $constructionSiteAddress
          constructionSiteCity: $constructionSiteCity
          saleOrderClientName: $saleOrderClientName
          truckLicencePlate: $truckLicencePlate
          idClient: $idClient
          forwardingAgent: $forwardingAgent
          idFreightForwarder: $idFreightForwarder
          destinationSite: $destinationSite
        }
        id: $id
      }
    ) {
      clientMutationId
      draftOutboundDelivery {
        code
        code
        constructionSiteAddress
        constructionSiteCity
        externalReference
        id
        idConstructionSite
        idSaleOrder
        idTruck
        saleOrderClientName
        truckLicencePlate
        idClient
        forwardingAgent
      }
    }
  }
`

const UPDATE_DELIVERY_ITEM = gql`
  mutation updateDraftOutboundDeliveryItem($id: UUID!, $quantity: Float!) {
    updateDraftOutboundDeliveryItem(input: { patch: { quantity: $quantity }, id: $id }) {
      clientMutationId
      materialByIdMaterial {
        id
        description
      }
      draftOutboundDeliveryItem {
        id
        quantity
      }
    }
  }
`

const UPDATE_DELIVERY_STATUS = gql`
  mutation updateDraftOutboundDelivery($status: OutboundDeliveryStatus!, $id: UUID!) {
    updateDraftOutboundDelivery(input: { patch: { status: $status }, id: $id }) {
      clientMutationId
    }
  }
`

const UPDATE_DELIVERY_CONSTRUCTION_SITE = gql`
  mutation updateDeliveryConstructionSite(
    $id: UUID!
    $address: String
    $city: String
    $code: String
  ) {
    updateConstructionSite(
      input: { patch: { city: $city, address: $address, code: $code }, id: $id }
    ) {
      clientMutationId
      constructionSite {
        code
        city
        address
        id
      }
    }
  }
`

const DELETE_DELIVERY_ITEM = gql`
  mutation deleteDraftOutboundDeliveryItem($id: UUID!) {
    deleteDraftOutboundDeliveryItem(input: { id: $id }) {
      clientMutationId
    }
  }
`

const COUNT_TO_VALIDATE = gql`
  query countToValidate {
    draftOutboundDeliveries(filter: { status: { equalTo: VALIDER } }) {
      totalCount
    }
  }
`

export default {
  SALES_ORDER_BY_CODE,
  SALE_ORDER_BY_ID,
  DELIVERIES,
  DELIVERY_BY_ID,
  COUNT_TODAY_DELIVERY,
  CONSTRUCTION_SITE_BY_CODE,
  CONSTRUCTION_SITE_BY_ID,
  CREATE_DELIVERY,
  CREATE_DELIVERY_ITEM,
  CREATE_CONSTRUCTION_SITE,
  UPDATE_DELIVERY,
  UPDATE_DELIVERY_ITEM,
  UPDATE_DELIVERY_STATUS,
  UPDATE_DELIVERY_CONSTRUCTION_SITE,
  DELETE_DELIVERY_ITEM,
  COUNT_TO_VALIDATE,
}
