import { PropTypes } from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { UIButton, UITextInput, UITextInputAutoCompletion } from '../..'
import '../../../styles/colors.scss'
import './NewDeliveryStep1.scss'

const NewDeliveryStep1 = (props) => {
  const {
    setStep,
    saleOrderCode,
    saleOrderSucess,
    saleOrderOnChange,
    client,
    clientId,
    clientOnChange,
    clientAutoCompletionList,
    clientSuccessAutoComplete,
    freightForwarders,
    freightForwardersId,
    freightForwardersOnChange,
    freightForwardersAutoCompletionList,
    freightForwardersSuccessAutoComplete,
    externRef,
    externRefOnChange,
    destinationSite,
    destinationSiteOnChange,
    constructionSiteCode,
    constructionSiteCodeOnChange,
    constructionSiteSuccess,
    constructionSiteAddress,
    constructionSiteAddressOnChange,
    constructionSiteCity,
    constructionSiteCityOnChange,
    licensePlate,
    licensePlateSuccess,
    licensePlateOnChange,
    evacuation,
    evacuationOnChange,
    bdcFile,
    bdcFileOnChange,
  } = props

  const { t } = useTranslation('newDelivery')

  return (
    <div className="new-delivery-first">
      <div className="new-delivery-first__container">
        <div className="new-delivery-first__container__form">
          <div className="new-delivery-first__container__form__top">
            <div className="new-delivery-first__container__form__top__left">
              <div className="new-delivery-first__container__form__container-input">
                <UITextInput
                  type="string"
                  value={saleOrderCode}
                  onChange={saleOrderOnChange}
                  success={saleOrderSucess}
                  prefix={t('inputNumOrder')}
                  placeHolder={t('placeHolderNumOrder')}
                  styleInput={{ height: '50px', width: '93%' }}
                  stylePrefix={{ fontWeight: '600' }}
                />
              </div>

              <div className="new-delivery-first__container__form__container-input">
                <UITextInputAutoCompletion
                  type="string"
                  value={client}
                  valueId={clientId}
                  onChange={clientOnChange}
                  prefix={t('inputClient')}
                  stylePrefix={{ fontWeight: '600', width: '28%' }}
                  styleInput={{ height: '50px', width: '93%' }}
                  styleAutoComplete={{ marginTop: '25px' }}
                  autoCompleteList={clientAutoCompletionList}
                  autoCompleteType="clients"
                  success={clientSuccessAutoComplete}
                />
              </div>

              <div className="new-delivery-first__container__form__container-input">
                <UITextInputAutoCompletion
                  type="string"
                  value={freightForwarders}
                  valueId={freightForwardersId}
                  onChange={freightForwardersOnChange}
                  prefix={t('inputFreightForwarders')}
                  stylePrefix={{ fontWeight: '600', width: '28%' }}
                  styleInput={{ height: '50px', width: '93%' }}
                  styleAutoComplete={{ marginTop: '25px' }}
                  autoCompleteList={freightForwardersAutoCompletionList}
                  success={freightForwardersSuccessAutoComplete}
                />
              </div>
            </div>

            <div className="new-delivery-first__container__form__top__right">
              <div className="new-delivery-first__container__form__container-input">
                <UITextInput
                  type="string"
                  value={externRef}
                  onChange={externRefOnChange}
                  prefix={t('inputExternRef')}
                  placeHolder={t('placeHolderExternRef')}
                  stylePrefix={{ fontWeight: '600', width: '40%' }}
                />
              </div>

              <div className="new-delivery-first__container__form__container-input">
                <UITextInput
                  type="string"
                  value={destinationSite}
                  onChange={destinationSiteOnChange}
                  prefix={t('inputDestinationSite')}
                  placeHolder={t('placeHolderDestinationSite')}
                  stylePrefix={{ fontWeight: '600', width: '40%' }}
                />
              </div>

              <div className="new-delivery-first__container__form__container-input new-delivery-first__container__form__container-input__scan">
                <h2 className="new-delivery-first__container__form__container-input__scan__text">
                  {t('prefixScanBdc')}
                </h2>
                <label className="new-delivery-first__container__form__container-input__scan__button">
                  <input
                    type="file"
                    accept="image/png, image/jpeg, .pdf, .doc, .docx"
                    className="new-delivery-first__container__form__container-input__scan__button__input"
                    onChange={bdcFileOnChange}
                  />
                  <p className="new-delivery-first__container__form__container-input__scan__button__text">
                    {t('import')}
                  </p>
                </label>
                {bdcFile && (
                  <p className="new-delivery-first__container__form__container-input__scan__name-file">
                    {bdcFile?.name}
                  </p>
                )}
              </div>

              <div className="new-delivery-first__container__form__container-input new-delivery-first__container__form__container-input__check">
                <label className="checkbox bounce">
                  <input
                    type="checkbox"
                    className="new-delivery-first__container__form__container-input__checkbox"
                    name="evacuation"
                    checked={evacuation}
                    onChange={() => evacuationOnChange(!evacuation)}
                  />
                  <svg viewBox="0 0 21 21">
                    <polyline points="5 10.75 8.5 14.25 16 6" />
                  </svg>
                </label>
                <h2 className="new-delivery-first__container__form__container-input__evac-text">
                  {t('evacuation')}
                </h2>
              </div>
            </div>
          </div>

          <div className="new-delivery-first__container__form__bottom">
            <div className="new-delivery-first__container__form__container-input">
              <UITextInput
                type="string"
                value={constructionSiteCode}
                onChange={constructionSiteCodeOnChange}
                success={constructionSiteSuccess}
                prefix={t('prefixCodeConstruction')}
                placeHolder={t('placeHolderCodeConstruction')}
                style={{ width: '70%' }}
                stylePrefix={{ fontWeight: '600' }}
              />
            </div>

            <div className="new-delivery-first__container__form__container-input">
              <UITextInput
                type="string"
                value={constructionSiteAddress}
                onChange={constructionSiteAddressOnChange}
                success={constructionSiteSuccess}
                prefix={t('prefixAdress')}
                placeHolder={t('placeHolderAdress')}
                style={{ width: '70%' }}
                stylePrefix={{ fontWeight: '600' }}
              />
            </div>
            <div className="new-delivery-first__container__form__container-input">
              <UITextInput
                type="string"
                value={constructionSiteCity}
                onChange={constructionSiteCityOnChange}
                success={constructionSiteSuccess}
                prefix={t('prefixCity')}
                placeHolder={t('placeHolderCity')}
                style={{ width: '70%' }}
                stylePrefix={{ fontWeight: '600' }}
              />
            </div>
            <div className="new-delivery-first__container__form__container-input">
              <UITextInput
                type="string"
                value={licensePlate}
                onChange={licensePlateOnChange}
                success={licensePlateSuccess}
                prefix={t('prefixImmatriculation')}
                placeHolder={t('placeHolderImmatriculation')}
                isRequired={true}
                style={{ width: '70%' }}
                stylePrefix={{ fontWeight: '600' }}
              />
            </div>
          </div>
        </div>

        <div className="new-delivery-first__container__submit">
          <div className="new-delivery-first__container__submit__button">
            <UIButton
              type="primary"
              text={t('next')}
              onClick={setStep}
              alt="next step"
              disabled={!licensePlate}
              style={{ width: '240px', height: '50px' }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

NewDeliveryStep1.propTypes = {
  setStep: PropTypes.func.isRequired,
  saleOrderCode: PropTypes.string.isRequired,
  saleOrderSucess: PropTypes.bool,
  saleOrderOnChange: PropTypes.func.isRequired,
  client: PropTypes.string.isRequired,
  clientId: PropTypes.string,
  clientOnChange: PropTypes.func.isRequired,
  clientAutoCompletionList: PropTypes.arrayOf(PropTypes.string),
  clientSuccessAutoComplete: PropTypes.bool,
  freightForwarders: PropTypes.string.isRequired,
  freightForwardersId: PropTypes.string.isRequired,
  freightForwardersOnChange: PropTypes.func.isRequired.isRequired,
  freightForwardersAutoCompletionList: PropTypes.arrayOf(PropTypes.string).isRequired,
  freightForwardersSuccessAutoComplete: PropTypes.bool.isRequired,
  externRef: PropTypes.string.isRequired,
  externRefOnChange: PropTypes.func.isRequired,
  destinationSite: PropTypes.string.isRequired,
  destinationSiteOnChange: PropTypes.func.isRequired,
  constructionSiteCode: PropTypes.string.isRequired,
  constructionSiteCodeOnChange: PropTypes.func.isRequired,
  constructionSiteSuccess: PropTypes.bool,
  constructionSiteAddress: PropTypes.string.isRequired,
  constructionSiteAddressOnChange: PropTypes.func.isRequired,
  constructionSiteCity: PropTypes.string.isRequired,
  constructionSiteCityOnChange: PropTypes.func.isRequired,
  licensePlate: PropTypes.string,
  licensePlateSuccess: PropTypes.bool,
  licensePlateOnChange: PropTypes.func.isRequired,
  evacuation: PropTypes.bool.isRequired,
  evacuationOnChange: PropTypes.func.isRequired,
  bdcFile: PropTypes.shape({
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    size: PropTypes.number.isRequired,
    lastModified: PropTypes.number.isRequired,
  }),
  bdcFileOnChange: PropTypes.func.isRequired,
}

NewDeliveryStep1.defaultProps = {
  saleOrderSucess: false,
  clientId: null,
  clientAutoCompletionList: null,
  clientSuccessAutoComplete: false,
  constructionSiteSuccess: false,
  licensePlate: null,
  licensePlateSuccess: false,
  bdcFile: undefined,
}

export default NewDeliveryStep1
