import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { initSentry } from './lib/sentry'

import ProtectedRoute from './components/_Widget/WidgetPrivateRoute/WidgetPrivateRoute.js'
import {
  FollowingDelivery,
  ForgotPassword,
  Login,
  NewDelivery,
  PrintPage,
  ResetPassword,
  SettingsPage,
  Tablets,
  TrucksOnSite,
  Users,
  Weighing,
} from './pages'
import './styles/app.scss'

initSentry()
function App() {
  return (
    <Routes>
      {/* public routes */}
      <Route path="/" element={<Login />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/change-password" element={<ResetPassword />} />

      {/* protect these routes */}
      <Route
        path="/following-delivery"
        element={
          <ProtectedRoute
            access={['bdn_admin', 'bdn_headquarter_assistant', 'bdn_on_site_assistant']}
          >
            <FollowingDelivery />
          </ProtectedRoute>
        }
      />
      <Route
        path="/weighing"
        element={
          <ProtectedRoute access={['bdn_admin', 'bdn_headquarter_assistant']}>
            <Weighing />
          </ProtectedRoute>
        }
      />
      <Route
        path="/trucks"
        element={
          <ProtectedRoute access={['bdn_admin', 'bdn_headquarter_assistant']}>
            <TrucksOnSite />
          </ProtectedRoute>
        }
      />
      <Route
        path="/new-delivery"
        element={
          <ProtectedRoute access={['bdn_admin', 'bdn_headquarter_assistant']}>
            <NewDelivery />
          </ProtectedRoute>
        }
      />

      <Route
        path="/tablets"
        element={
          <ProtectedRoute access={['bdn_admin']}>
            <Tablets />
          </ProtectedRoute>
        }
      />

      <Route
        path="/users"
        element={
          <ProtectedRoute access={['bdn_admin']}>
            <Users />
          </ProtectedRoute>
        }
      />

      <Route
        path="/print/:id"
        element={
          <ProtectedRoute access={['bdn_admin']}>
            <PrintPage />
          </ProtectedRoute>
        }
      />
      <Route
        path="/settings"
        element={
          <ProtectedRoute access={['bdn_admin']}>
            <SettingsPage />
          </ProtectedRoute>
        }
      />
    </Routes>
  )
}
export default App
