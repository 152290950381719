import PropTypes from 'prop-types'
import React from 'react'
import './WidgetAutoCompleteList.scss'

const WidgetAutoCompleteList = ({ list, select, type, style }) => (
  <div className="auto-complete" style={style}>
    {list.map((item, index) => (
      <button
        key={index}
        type="button"
        onClick={() => {
          if (type === 'clients') {
            if (item.node?.active) {
              select(item)
            }
          } else {
            select(item)
          }
        }}
      >
        {type === 'clients' && (
          <p
            className={!item.node?.active && 'clients--inactive'}
          >{`${item.node?.internalId} - ${item.node?.displayName}`}</p>
        )}
        {(type === 'materials' || type === 'rubbles') && `${item.internalId} - ${item.description}`}
        {type !== 'clients' &&
          type !== 'materials' &&
          type !== 'rubbles' &&
          (item.node?.displayName || item.displayName || item.description)}
      </button>
    ))}
  </div>
)

WidgetAutoCompleteList.propTypes = {
  list: PropTypes.arrayOf(PropTypes.string).isRequired,
  type: PropTypes.string,
  select: PropTypes.func.isRequired,
  style: PropTypes.shape({}),
}

WidgetAutoCompleteList.defaultProps = {
  type: null,
  style: null,
}

export default WidgetAutoCompleteList
